import axios from "axios";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SignatureCanvas from "react-signature-canvas";

import Button from "@ui/Button";
import Icon from "@ui/Icon";
import Modal from "@ui/Modal";

import { labels } from "@constants";

import { hideModal } from "@reducers/modalsSlice";
import {
  deleteImage,
  selectImage,
  setImage,
  setSaveImageAction,
  setUseByDefault,
} from "@reducers/signatureSlice";
import { setLoading } from "@reducers/tracesSlice";

import { base64ToFile } from "@utils";

const EditSignature = () => {
  const signature = useSelector(selectImage);
  const [selectedImage, setSelectedImage] = useState<
    string | ArrayBuffer | null
  >(signature);
  const [uploadedImage, setUploadedImage] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const signatureRef = useRef<SignatureCanvas>(null);
  const dispatch = useDispatch();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      setUploadedImage(file);

      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          setSelectedImage(reader.result);
        };
      }
    }
  };

  const saveImage = () => {
    if (selectedImage) {
      const formData = new FormData();
      if (uploadedImage) {
        formData.append("file", uploadedImage);
      }

      axios
        .post("/api/v1/me/signature", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            dispatch(setSaveImageAction({ saveImageAction: true }));
          }
        })
        .catch((error) => {});

      dispatch(hideModal());
      return;
    }
    // Save the drawn signature as an image (if not empty).
    if (signatureRef.current && !signatureRef.current.isEmpty()) {
      dispatch(setLoading(true));
      const dataUrl = signatureRef.current.toDataURL();
      const file = base64ToFile(dataUrl, "image");

      if (!file) {
        return;
      }
      const formData = new FormData();
      formData.append("file", file);
      setUploadedImage(null);

      axios
        .post("/api/v1/me/signature", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            dispatch(setSaveImageAction({ saveImageAction: true }));
          }
        })
        .catch((error) => {});
      dispatch(setImage({ image: dataUrl }));

      dispatch(hideModal());
      return;
    }
  };

  const handleImageDelete = () => {
    dispatch(setLoading(true));

    axios
      .delete("/api/v1/me/signature")
      .then((response) => {
        if (response.status === 200) {
          dispatch(deleteImage());
          dispatch(setImage({ image: null }));
          dispatch(setSaveImageAction({ saveImageAction: true }));
          dispatch(setUseByDefault({ useByDefault: false }));
        }
      })
      .catch((error) => {});
  };

  return (
    <Modal
      title={labels.signature}
      description={labels.signatureDescription}
      width={386}
    >
      <input
        type="file"
        className="hidden"
        accept=".jpg, .jpeg, .png, .bmp"
        onChange={handleFileChange}
        ref={fileInputRef}
      />
      <div className="relative border rounded-[8px] w-[209px] h-[174px] mt-[16px] mx-auto overflow-hidden">
        {selectedImage && "string" === typeof selectedImage ? (
          <img
            src={selectedImage}
            alt={labels.signature}
            className="absolute top-0 left-0 w-full h-full object-contain"
          />
        ) : (
          <SignatureCanvas
            ref={signatureRef}
            canvasProps={{
              width: 209,
              height: 174,
            }}
          />
        )}
        <Button
          className="flex absolute left-[10px] bottom-[10px] bg-white size-[32px] rounded-full items-center justify-center shadow-md"
          onClick={() => {
            setSelectedImage(null);
            if (fileInputRef.current) {
              fileInputRef.current.value = "";
            }
            if (signatureRef.current) {
              signatureRef.current.clear();
            }
            handleImageDelete();
          }}
        >
          <Icon type="trash" className="size-[24px] text-ea-gray-200" />
        </Button>
      </div>
      <div className="flex justify-between items-center select-none mt-[24px]">
        <div>
          <Button
            className="btn-secondary body-lg leading-[31px] h-[33px] px-[17px] rounded-[8px]"
            onClick={() => {
              dispatch(hideModal());
            }}
          >
            {labels.cancel}
          </Button>
        </div>
        <div className="flex items-center">
          <Button
            className="btn-secondary body-lg leading-[31px] h-[33px] px-[17px] rounded-[8px]"
            disabled={!!selectedImage}
            onClick={() => {
              if (fileInputRef.current) {
                fileInputRef.current.click();
              }
            }}
          >
            {labels.import}
          </Button>
          <Button
            className="btn-primary body-md ml-[10px] h-[33px] px-[13px] rounded-[8px]"
            onClick={() => {
              saveImage();
            }}
          >
            {labels.save}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default EditSignature;
