import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";

import Modal from "@ui/Modal";

import { labels } from "@constants";

import {
  selectModalData,
  setActData,
  setModalData,
  setPageData,
} from "@reducers/dataTransferSlice";
import { hideModal } from "@reducers/modalsSlice";

const CancelAct = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const modalData = useSelector(selectModalData);
  const { actType } = useParams();

  const queryParams = new URLSearchParams(location.search);
  const returnPath = queryParams.get("returnPath");
  const backPath = returnPath ?? `/acts/${actType}`;

  // If there is a redirect location in the modal data, use it, otherwise go to the act type
  const quitLocation = modalData?.redirectTo ? modalData.redirectTo : backPath;

  let modalTitle = labels.registerAct;
  let modalDescription = labels.registerActDescription;

  if (location.pathname === "/profile") {
    modalTitle = labels.modification;
    modalDescription = labels.saveModification;
  }

  return (
    <Modal title={modalTitle} description={modalDescription} width={400}>
      <div className="flex items-center justify-between mt-[40px]">
        <Link
          className="btn-secondary rounded-[8px] px-[13px] py-[6px] body-md"
          to={quitLocation}
          onClick={() => {
            dispatch(setModalData(null));
            dispatch(setPageData(null));
            dispatch(setActData(null));
            dispatch(hideModal());
          }}
        >
          {labels.continueWithoutRegistering}
        </Link>
        <Link
          className="btn-primary rounded-[8px] px-[13px] py-[6px] body-md"
          to={quitLocation}
          onClick={() => {
            dispatch(setModalData(null));
            dispatch(setPageData(null));
            dispatch(setActData(null));
            dispatch(hideModal());
          }}
        >
          {labels.save}
        </Link>
      </div>
    </Modal>
  );
};

export default CancelAct;
