import { Field, Form, Formik } from "formik";
import { useDispatch } from "react-redux";

import Button from "@ui/Button";
import Icon from "@ui/Icon";
import Modal from "@ui/Modal";

import { labels } from "@constants";

import { hideModal } from "@reducers/modalsSlice";

const AdvancedSearchParty = () => {
  const dispatch = useDispatch();

  return (
    <Modal
      title={labels.advancedSearchTabLabel}
      description={labels.searchByNameSurnameOrEmail}
      width={400}
    >
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          cnbfNumber: "",
          email: "",
        }}
        onSubmit={(values) => {}}
      >
        {({ resetForm }) => (
          <Form className="mt-2">
            <div>
              <label className="text-[#8F8F8F] body-sm" htmlFor="lastName">
                {labels.surname}
              </label>
              <div className="flex relative mt-2 mb-4">
                <Icon
                  type="search"
                  color="black"
                  className="absolute size-[16px] top-[50%] translate-y-[-50%] left-[14px]"
                />
                <Field
                  type="text"
                  name="lastName"
                  className="flex pl-[47px] pr-[10px] py-2 w-full text-xs bg-white rounded border border-solid border-ea-gray-100 text-black"
                />
              </div>
            </div>

            <div>
              <label className="text-[#8F8F8F] body-sm" htmlFor="firstName">
                {labels.name}
              </label>
              <div className="flex relative mt-2 mb-4">
                <Icon
                  type="search"
                  color="black"
                  className="absolute size-[16px] top-[50%] translate-y-[-50%] left-[14px]"
                />
                <Field
                  type="text"
                  name="firstName"
                  className="flex pl-[47px] pr-[10px] py-2 w-full text-xs bg-white rounded border border-solid border-ea-gray-100 text-black"
                />
              </div>
            </div>

            <div>
              <label className="text-[#8F8F8F] body-sm" htmlFor="email">
                {labels.electronicAddress}
              </label>
              <div className="flex relative mt-2 mb-4">
                <Icon
                  type="search"
                  color="black"
                  className="absolute size-[16px] top-[50%] translate-y-[-50%] left-[14px]"
                />
                <Field
                  type="text"
                  name="email"
                  className="flex pl-[47px] pr-[10px] py-2 w-full text-xs bg-white rounded border border-solid border-ea-gray-100 text-black"
                />
              </div>
            </div>

            <div className="flex items-center justify-between mt-[40px]">
              <Button
                type="button"
                onClick={() => {
                  resetForm();
                }}
              >
                <Icon type="refresh" color="#7F7F7F" className="size-[16px]" />
              </Button>
              <Button
                type="submit"
                className="btn-secondary-emphasize rounded-[8px] px-[13px] py-[6px] body-md"
                onClick={() => dispatch(hideModal())}
              >
                {labels.search}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AdvancedSearchParty;
