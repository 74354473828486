import { useKeycloak } from "@react-keycloak/web";

import { useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { useSelector } from "react-redux";

import Button from "@ui/Button";
import Modal from "@ui/Modal";

import { labels } from "@constants";

import { selectPrepaidAccountInfo, selectUser } from "@reducers/metadataSlice";

import { updatePrepaidAccount } from "@utils/api/prepaidAccountApi";

const ReloadAccount = () => {
  const { keycloak } = useKeycloak();
  const userData = useSelector(selectUser);
  const prepaidAccountInfo = useSelector(selectPrepaidAccountInfo);
  // Because we have no BE currently, we will use a static value for the initial amount
  const initialAmount = 30;
  const initialAmountFormatted = Number(initialAmount).toFixed(2);

  const [amount, setAmount] = useState<number | string>(initialAmountFormatted);
  const [alertMessage, setAlertMessage] = useState("");
  const [newBalance, setNewBalance] = useState<number>(
    Number(prepaidAccountInfo.balance) + initialAmount,
  );

  const handleRecharge = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    if (amount === 0 || amount === "") {
      return;
    }

    updatePrepaidAccount(
      keycloak?.token,
      amount.toString().replace(",", "."),
      userData?.lastName,
    ).then((response) => {
      window.location.href =
        response?.sessionUrl + "?returnUrl=" + window.location.href;
    });
  };
  const updateNewBalance = (value: number | string) => {
    let amountToAdd: number = 0;
    if (typeof value === "string") {
      const formattedAmount = value.replace(",", ".");
      amountToAdd = Number(formattedAmount);
    } else {
      amountToAdd = value;
    }

    if (amountToAdd >= 0) {
      setNewBalance(Number(prepaidAccountInfo.balance) + amountToAdd);
    }
  };

  return (
    <Modal
      title={labels.rechargeAccount}
      width={400}
      description={labels.rechargePrepaidAccount}
    >
      <form name="reloadAccount">
        <div className="flex flex-col max-w-[295px] w-full">
          <div className="flex items-start flex-col mt-[10px]">
            <label
              htmlFor="amount"
              className="text-left body-sm text-ea-gray-300 mb-[10px]"
            >
              {labels.amount}
            </label>
            <div className="flex items-center mb-[10px]">
              <CurrencyInput
                defaultValue={amount}
                value={amount}
                allowDecimals={true}
                intlConfig={{ locale: "fr-FR" }}
                onValueChange={(value) => {
                  if (!value || Number(value) <= 0) {
                    setAlertMessage(labels.amountAlertMessage);
                  } else {
                    setAlertMessage("");
                  }
                  setAmount(value ?? "");
                  updateNewBalance(value ?? "");
                }}
                className="border rounded-[4px] border-ea-gray-100 px-[13px] py-[5px] mr-[5px] w-[245px]"
              />

              <span>{labels.euroSign}</span>
            </div>
            {alertMessage && (
              <div className="text-[10px] text-ea-red -mt-[8px]">
                {alertMessage}
              </div> // Adjust styling as needed
            )}
          </div>
          <div className="flex justify-between mb-[40px] mt-2">
            <p className="body-sm text-ea-gray-300">{labels.newBalance}</p>
            <p>
              {newBalance.toFixed(2).toString().replace(".", ",")}
              {labels.euroSign}
            </p>
          </div>
          <Button
            type="submit"
            className="btn-primary rounded-[8px] px-[21px] py-[8px] max-w-[95px] ml-auto mr-0 body-md"
            disabled={Number(amount) <= 0}
            onClick={(e) => handleRecharge(e)}
          >
            {labels.recharge}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ReloadAccount;
