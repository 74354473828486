import axios from "axios";
import { toast } from "react-toastify";

import { axiosErrorMessages, labels, toastOptionsError } from "@constants";

import { initEaf } from "@eaf";

import { removeBaseUrl } from "@utils";

const prepareAndFinalizeApi = (
  signbookId: string,
  token: Record<string, any>,
  signatureData: FormData,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  signingSuccessful: () => void,
  orderData: Record<string, string>,
) => {
  const certificate = token.certificate?.original;
  const stringifiedCertificate = JSON.stringify({ certificate });
  const certificateBlob = new Blob([stringifiedCertificate], {
    type: "application/json",
  });

  signatureData.append("prepareTokenRequest", certificateBlob);

  let preparationEndpoint = `/api/v1/signbooks/${signbookId}/preparation`;
  let finalizeEndpoint = `/api/v1/signbooks/${signbookId}/finalize`;

  const preparizeHeaders: Record<string, string> = {
    "Content-Type": "multipart/form-data",
  };
  const finalizeHeaders: Record<string, string> = {};

  let hasRedirect = false;

  if (orderData?.accessToken) {
    preparationEndpoint = `/shared-api/v1/process/${orderData.signBookNumber}/actors/${orderData.lawyerCode}/preparation`;
    finalizeEndpoint = `/shared-api/v1/process/${orderData.signBookNumber}/actors/${orderData.lawyerCode}/finalize`;
    preparizeHeaders["Authorization"] = `Bearer ${orderData.accessToken}`;
    finalizeHeaders["Authorization"] = `Bearer ${orderData.accessToken}`;

    hasRedirect = true;
  }

  // Call prepare action API
  return axios
    .post(preparationEndpoint, signatureData, {
      headers: preparizeHeaders,
    })
    .then((response) => {
      const documentsToFinalize: Record<string, string>[] = [];
      const { prepareDocResults, joseSignature } = response.data;
      const eaf = initEaf();

      eaf
        .onSignSuccess((result: Record<string, string>) => {
          const { encryptedData } = result;

          prepareDocResults.forEach(
            (preparedDocument: Record<string, string>, index: number) => {
              const { documentUid, preparedPKCS7 } = preparedDocument;
              const p7Signature = encryptedData[index];

              documentsToFinalize.push({
                documentUid,
                preparedPKCS7,
                p7Signature,
              });
            },
          );

          return axios
            .post(
              finalizeEndpoint,
              {
                documentsFinalizeSign: documentsToFinalize,
              },
              {
                headers: finalizeHeaders,
              },
            )
            .then(() => {
              if (hasRedirect) {
                axios
                  .get(`/api/v1/signbooks/${signbookId}/signings/order`)
                  .then((response) => {
                    const { redirectURI } = response.data;

                    if (redirectURI) {
                      document.location.href = removeBaseUrl(redirectURI);
                    }
                  })
                  .catch((error) => {
                    toast.error(
                      axiosErrorMessages[error.message],
                      toastOptionsError,
                    );
                  });

                return;
              }

              setLoading(false);
              signingSuccessful();
            })
            .catch((error) => {
              toast.error(error.message, toastOptionsError);

              setLoading(false);
            });
        })
        .onSignError(() => {
          toast.error(labels.signingError, toastOptionsError);

          setLoading(false);
        })
        .onSignCancel(() => {
          toast.error(labels.signingCancelled, toastOptionsError);

          setLoading(false);
        });

      eaf.sign(joseSignature, token);
    })
    .catch((error) => {
      const errorMessage = axiosErrorMessages[error.message] || error.message;

      toast.error(errorMessage, toastOptionsError);

      setLoading(false);
    });
};

export { prepareAndFinalizeApi };
