import axios from "axios";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import Button from "@ui/Button";
import CustomDatePickerHeader from "@ui/DatepickerCustomHeader";
import Icon from "@ui/Icon";
import Modal from "@ui/Modal";
import Spinner from "@ui/Spinner";

import { axiosErrorMessages, labels, toastOptionsError } from "@constants";

import { selectModalData, setLoadingState } from "@reducers/dataTransferSlice";
import { showModal } from "@reducers/modalsSlice";

import { areDatesAtLeastXDaysBeforeToday, formatLabel } from "@utils";

const DivorceVerification = () => {
  const [partyOneDate, setPartyOneDate] = useState<Date | null>(null);
  const [partyTwoDate, setPartyTwoDate] = useState<Date | null>(null);
  const [datesAreValidated, setDatesAreValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const data = useSelector(selectModalData);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!partyOneDate || !partyTwoDate) {
      setDatesAreValidated(false);
      return;
    }

    if (!areDatesAtLeastXDaysBeforeToday(partyOneDate, partyTwoDate, 17)) {
      setDatesAreValidated(false);
      return;
    }

    setDatesAreValidated(true);
  }, [partyOneDate, partyTwoDate]);

  const handleCheckDelay = () => {
    if (!data || !partyOneDate || !partyTwoDate) {
      return;
    }

    setLoading(true);
    dispatch(setLoadingState(true));

    axios
      .put(
        `/api/v1/signbooks/${data.publicId}/signings/delivery`,
        {
          member1UID: data.firstPublicIdMember,
          noticeMember1: partyOneDate.toLocaleDateString("fr-FR"),
          member2UID: data.secondPublicIdMember,
          noticeMember2: partyTwoDate.toLocaleDateString("fr-FR"),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      )
      .then(() => {
        dispatch(showModal("divorceConfirmation"));
        dispatch(setLoadingState(false));
        setLoading(false);
      })
      .catch((error) => {
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
        setLoading(false);
      });
  };

  return (
    <Modal title={labels.verifyBeforeLaunch} width={590}>
      <Formik
        validateOnMount
        initialValues={{}}
        onSubmit={() => {
          handleCheckDelay();
        }}
      >
        {() => (
          <Form>
            <div className="text-sm mt-4 pt-20">
              <div className="-mt-10 pb-10">
                <ul className="pl-3 list-disc marker:text-[5px]">
                  <li>{labels.registeredLetterNotice}</li>
                  <li>{labels.receiptExpirationNotice}</li>
                </ul>
                <p className="my-4">{labels.receiptDeadlineNotice}</p>
              </div>
              <div className="my-8 space-y-4">
                <div className="flex justify-between items-center">
                  <p className="truncate">{`${formatLabel(labels.partyX, "1")} - ${data.firstPartyName}`}</p>
                  <div className="relative">
                    <Icon
                      type="calendar"
                      color="#000"
                      className="w-[18px] h-[20px] mr-[19px] absolute top-[50%] left-[16px] transform -translate-y-1/2 z-10"
                    />

                    <DatePicker
                      renderCustomHeader={(props) => (
                        <CustomDatePickerHeader {...props} />
                      )}
                      className="font-medium text-black pl-[40px] py-2 pr-16 bg-white rounded border border-solid border-ea-gray-100 max-md:pr-5"
                      selected={partyOneDate}
                      onChange={(date) => {
                        setPartyOneDate(date);
                      }}
                      dateFormat="dd/MM/yyyy"
                      placeholderText={labels.receiptDate}
                    />
                  </div>
                </div>

                <div className="flex justify-between items-center">
                  <p className="truncate">{`${formatLabel(labels.partyX, "2")} - ${data.secondPartyName}`}</p>
                  <div className="relative">
                    <Icon
                      type="calendar"
                      color="#000"
                      className="w-[18px] h-[20px] mr-[19px] absolute top-[50%] left-[16px] transform -translate-y-1/2 z-10"
                    />

                    <DatePicker
                      renderCustomHeader={(props) => (
                        <CustomDatePickerHeader {...props} />
                      )}
                      className="font-medium text-black pl-[40px] py-2 pr-16 bg-white rounded border border-solid border-ea-gray-100 max-md:pr-5"
                      selected={partyTwoDate}
                      onChange={(date) => {
                        setPartyTwoDate(date);
                      }}
                      dateFormat="dd/MM/yyyy"
                      placeholderText={labels.receiptDate}
                    />
                  </div>
                </div>
              </div>

              <div className="flex justify-end">
                <Button
                  type="submit"
                  className="btn-base btn-primary flex items-center justify-center"
                  disabled={!datesAreValidated || loading}
                >
                  {loading && <Spinner className="mr-4 size-4" />}
                  {labels.checkDelay}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default DivorceVerification;
