import { phones } from "@constants/region";

/**
 * Retrieve a list of all phone codes.
 * @returns  An array of phone codes.
 */
const getPhoneCodes = () => {
  return phones.map((phone) => phone.code);
};

/**
 * Retrieve the country name for the given index.
 * @param index  The index of the country in the phones array.
 * @returns      The name of the country.
 */
const getCountryByIndex = (index: number) => {
  return phones[index].country;
};

/**
 * Extract the phone number excluding the country code.
 * @param phone        The full phone number including the country code.
 * @param countryCode  The country code to be removed.
 * @returns            The phone number without the country code.
 */
const getPhoneNumber = (phone: string, countryCode: string) => {
  if (!phone || !countryCode) {
    return "";
  }

  const countryCodeLength = getCodeLength(countryCode);

  return phone.slice(countryCodeLength);
};

/**
 * Extract the country code from a full phone number.
 * @param phone        The full phone number including the country code.
 * @param countryCode  The country code to extract.
 * @returns            The extracted country code.
 */
const getPhoneCode = (phone: string, countryCode: string) => {
  if (!phone || !countryCode) {
    return "";
  }

  const countryCodeLength = getCodeLength(countryCode);

  return phone.slice(0, countryCodeLength);
};

/**
 * Calculate the length of a country code for the given country.
 * @param country  The name of the country.
 * @returns        The length of the country code. Returns 0 if the country is not found.
 */
const getCodeLength = (country: string) => {
  return phones.find((phone) => phone.country === country)?.code?.length || 0;
};

export { getPhoneCodes, getCountryByIndex, getPhoneNumber, getPhoneCode };
