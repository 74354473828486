import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import Button from "@ui/Button";
import Modal from "@ui/Modal";
import Spinner from "@ui/Spinner";

import { axiosErrorMessages, labels, toastOptionsError } from "@constants";

import { setLoadingState } from "@reducers/dataTransferSlice";
import { hideModal } from "@reducers/modalsSlice";

import { PathMap } from "@types";

import { getPathMap } from "@utils";

const SealMyParties = () => {
  const [loading, setLoading] = useState(false);
  const [publicId, setPublicId] = useState<null | string>(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { actType, "*": rest } = useParams();

  const id = rest ? rest.split("/")[0] : "";
  const pathMap: PathMap = getPathMap(actType, id);
  const thirdStepPath = pathMap[3].url;

  useEffect(() => {
    axios
      .get(`/api/v1/signbooks/${id}`)
      .then((response) => {
        setPublicId(response.data.publicId);

        setLoading(false);
      })
      .catch((error) => {
        const errorMessage = axiosErrorMessages[error.message] || error.message;

        toast.error(errorMessage, toastOptionsError);

        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sealAllParties = () => {
    setLoading(true);

    axios
      .post(`/api/v1/signbooks/${publicId}/signatories/sealing`)
      .then(() => {
        navigate(`/acts/${actType}${thirdStepPath}`);
        dispatch(hideModal());
        dispatch(setLoadingState(false));
        setLoading(false);
      })
      .catch((error) => {
        const errorMessage = axiosErrorMessages[error.message] || error.message;

        toast.error(errorMessage, toastOptionsError);

        setLoading(false);
      });
  };

  return (
    <Modal title={labels.sealPartyModalTitle} width={466}>
      <div className="text-left mt-[10px]">
        <p className="body-sm">
          {labels.sealPartyModalDescription}
          <br />
          {labels.sealPartyModalDescription2}
        </p>
        <p className="body-sm mt-[10px]">{labels.continuePrompt}</p>
        <div className="flex justify-between mt-8">
          <Button
            className="btn-secondary flex justify-start items-center rounded-[8px] px-[21px] py-[8px] body-md h-[34px]"
            onClick={() => dispatch(hideModal())}
            disabled={loading}
          >
            {labels.cancel}
          </Button>
          <Button
            className="btn btn-primary flex justify-between items-center rounded-[8px] px-[21px] py-[8px] body-md h-[34px]"
            onClick={() => {
              dispatch(setLoadingState(true));
              sealAllParties();
            }}
            data-testid="seal-and-continue"
            disabled={loading || !publicId}
          >
            {loading && <Spinner className="mr-2 size-4" />}
            {labels.sealPartyModalButton}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SealMyParties;
