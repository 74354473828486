import LawyerSignatureConfirmation from "./LawyerSignatureConfirmation";
import WaitingForSignature from "./WaitingForSignature";

import { useSelector } from "react-redux";

import AddLawyerCounselor from "@modals/AddLawyerCounselor";
import AdvancedSearchLawyer from "@modals/AdvancedSearchLawyer";
import AdvancedSearchParty from "@modals/AdvancedSearchParty";
import Cancel from "@modals/Cancel";
import CancelAct from "@modals/CancelAct";
import Certificate from "@modals/Certificate";
import ChangeLawyer from "@modals/ChangeLawyer";
import Confirm from "@modals/Confirm";
import DeleteParty from "@modals/DeleteParty";
import Diagnostics from "@modals/Diagnostics";
import DivorceConfirmation from "@modals/DivorceConfirmation";
import DivorceFinalization from "@modals/DivorceFinalization";
import DivorceVerification from "@modals/DivorceVerification";
import EditLawyer from "@modals/EditLawyer";
import EditParty from "@modals/EditParty";
import EditSignature from "@modals/EditSignature";
import InvoiceHistory from "@modals/InvoiceHistory";
import LogOut from "@modals/LogOut";
import Payment from "@modals/Payment";
import ReloadAccount from "@modals/ReloadAccount";
import RestrictedAccess from "@modals/RestrictedAccess";
import SealMyParties from "@modals/SealMyParties";
import SealParty from "@modals/SealParty";
import ShareDocuments from "@modals/ShareDocuments";
import Signature from "@modals/Signature";
import ValidateDocuments from "@modals/ValidateDocuments";

import { selectModal } from "@reducers/modalsSlice";

const Modals = () => {
  const modal = useSelector(selectModal);

  switch (modal) {
    case "editParty":
      return <EditParty />;
    case "editLawyer":
      return <EditLawyer />;
    case "editSignature":
      return <EditSignature />;
    case "invoiceHistory":
      return <InvoiceHistory />;
    case "reloadAccount":
      return <ReloadAccount />;
    case "sealMyParties":
      return <SealMyParties />;
    case "shareDocuments":
      return <ShareDocuments />;
    case "validateDocuments":
      return <ValidateDocuments />;
    case "cancel":
      return <Cancel />;
    case "cancelAct":
      return <CancelAct />;
    case "changeLawyer":
      return <ChangeLawyer />;
    case "advancedSearchLawyer":
      return <AdvancedSearchLawyer />;
    case "advancedSearchParty":
      return <AdvancedSearchParty />;
    case "deleteParty":
      return <DeleteParty />;
    case "sealParty":
      return <SealParty />;
    case "logOut":
      return <LogOut />;
    case "payment":
      return <Payment />;
    case "confirm":
      return <Confirm />;
    case "addLawyerCounselor":
      return <AddLawyerCounselor />;
    case "diagnostics":
      return <Diagnostics />;
    case "restrictedAccess":
      return <RestrictedAccess />;
    case "certificate":
      return <Certificate />;
    case "divorceConfirmation":
      return <DivorceConfirmation />;
    case "divorceFinalization":
      return <DivorceFinalization />;
    case "divorceVerification":
      return <DivorceVerification />;
    case "signature":
      return <Signature />;
    case "lawyerSignatureConfirmation":
      return <LawyerSignatureConfirmation />;
    case "waitingForSignature":
      return <WaitingForSignature />;
    default:
      return null;
  }
};

export default Modals;
