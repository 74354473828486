import { useNavigate } from "react-router-dom";

import Button from "@ui/Button";
import Icon from "@ui/Icon";

import { labels } from "@constants";

const youtubeID = "6BxU8ekeRH8";

const Welcome = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col px-[45px] py-[40px] mt-10 justify-center items-center">
      <h2 className="flex headline-lg mb-5 text-center">
        {labels.welcomeToNewEAct}
        <img
          src="/assets/party.png"
          alt=""
          className="w-[25px] h-[25px] ml-1"
        />
      </h2>
      <p className=" body-lg w-[440px]">
        {labels.welcomeToNewEActPageDescription}
      </p>
      <div className="flex size-[296px] border mt-10 bg-ea-gray-50 justify-center rounded-md">
        <div className="video-frame flex items-center justify-center">
          <div className="rounded-full bg-white flex w-[46px] h-[46px] items-center justify-center">
            <Icon
              type="triangle"
              color="#000"
              className="w-[25px] h-[25px] ml-1"
            />
            <iframe
              width="296"
              height="296"
              className="video"
              title="Youtube player"
              sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
              src={`https://youtube.com/embed/${youtubeID}?autoplay=0`}
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
      <div className="flex justify-end w-full mt-20">
        <Button
          type="submit"
          className="btn-base btn-primary flex items-center justify-center w-[132px]"
          data-testid="next"
          onClick={() => navigate("/onboarding/your-profile")}
        >
          {labels.next}
        </Button>
      </div>
    </div>
  );
};

export default Welcome;
