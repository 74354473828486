import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import Button from "@ui/Button";
import Icon from "@ui/Icon";
import Loader from "@ui/Loader";
import Modal from "@ui/Modal";
import PhoneField from "@ui/PhoneField";
import Spinner from "@ui/Spinner";

import {
  axiosErrorMessages,
  labels,
  toastOptions,
  toastOptionsError,
} from "@constants";

import {
  selectModalData,
  setPageData,
  setPostState,
} from "@reducers/dataTransferSlice";
import { hideModal } from "@reducers/modalsSlice";

import { formatLabel, validateEmail } from "@utils";
import { getPhoneCode, getPhoneNumber } from "@utils/phone";
import { nameRegex } from "@utils/regex";

const EditLawyer = () => {
  const data = useSelector(selectModalData);
  const { "*": rest } = useParams();
  const publicId = rest ? rest.split("/")[0] : "";
  const [initializing, setInitializing] = useState(true);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const [lawyer, setLawyer] = useState<any>(data.lawyer);

  useEffect(() => {
    axios
      .get(`/api/v1/signbooks/${publicId}/lawyers/${data.lawyerCode}`)
      .then((response) => {
        setLawyer(response.data);
        setInitializing(false);
      })
      .catch((error) => {
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      });
  }, [publicId, data.lawyerCode]);

  const emptyModalFields = Object.keys(data.lawyer).filter(
    (key) => key !== "phoneCode",
  );
  const editMode = 0 !== emptyModalFields.length;

  const fieldClass =
    "body-lg border border-[#B4B4B4] py-[4px] px-[16px] rounded-[4px] w-full";
  const fieldClassDisabled = fieldClass.replace(
    "border-[#B4B4B4]",
    "border-[#F5F5F5]",
  );
  const iconClass =
    "absolute top-1/2 -translate-y-2/4 right-[16px] size-[14px]";

  // Map of field names to their corresponding labels.
  const labelsMap: Record<string, string> = {
    lastName: labels.surname,
    firstName: labels.name,
    email: labels.email,
    phone: labels.phone,
    nationality: labels.nationality,
  };

  // Validation schemas for the form.
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .trim(formatLabel(labels.requiredField, labels.name))
      .required(formatLabel(labels.requiredField, labels.name))
      .matches(nameRegex, formatLabel(labels.compliantField, labels.name)),
    lastName: Yup.string()
      .trim(formatLabel(labels.requiredField, labels.surname))
      .required(formatLabel(labels.requiredField, labels.surname))
      .matches(nameRegex, formatLabel(labels.compliantField, labels.surname)),
    phoneCode: Yup.string()
      .trim(formatLabel(labels.requiredField, labels.phoneCode))
      .required(formatLabel(labels.requiredField, labels.phoneCode)),
    nationality: Yup.string().required(
      formatLabel(labels.requiredField, labels.nationality),
    ),
  });

  // List of read-only fields.
  const readOnlyFields = ["lastName", "firstName"];

  // Values that we can't edit in the modal.
  const readOnlyValues = {
    lastName: lawyer.lastName || "",
    firstName: lawyer.firstName || "",
  };

  const phoneNumber = lawyer.mobileNumber || "";
  const phoneCountryCode = lawyer.mobilePhoneCountryCode || "FR";

  const splitPhoneNumber = getPhoneNumber(phoneNumber, phoneCountryCode);
  const splitPhoneCode = getPhoneCode(phoneNumber, phoneCountryCode);

  // Values that we can edit in the modal.
  const editableValues = {
    email: lawyer.email || "",
    phone: splitPhoneNumber || "",
    phoneCode: splitPhoneCode || "",
    nationality: lawyer.nationality || "",
    phoneCountryCode: phoneCountryCode,
  };

  // Combine both values for initializing Formik.
  const combinedValues = { ...readOnlyValues, ...editableValues };

  return (
    <Modal title={labels.lawyer} width={377}>
      <Formik
        enableReinitialize
        initialValues={combinedValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const updatedLawyer = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            codeCNBF: lawyer.codeCNBF,
            mobileNumber: `${values.phoneCode}${values.phone}`,
            codeBarreau: lawyer.codeBarreau,
            dateBarreau: lawyer.dateBarreau,
            nationality: values.nationality,
            mobilePhoneCountryCode: values.phoneCountryCode,
          };

          const updatedData = {
            ...data,
            lawyer: updatedLawyer,
          };

          setLoading(true);

          axios
            .put(
              `/api/v1/signbooks/${publicId}/lawyers/${data.lawyerCode}`,
              updatedLawyer,
            )
            .then((response) => {
              dispatch(setPageData(updatedData));
              dispatch(setPostState(true));
              dispatch(hideModal());
              toast.success(labels.documentSuccesfullyUpdated, toastOptions);
              setLoading(false);
            })
            .catch((error) => {
              dispatch(hideModal());
              toast.error(axiosErrorMessages[error.message], toastOptionsError);
              setLoading(false);
            });
        }}
      >
        {(formik) => {
          const fieldClassFinal = editMode ? fieldClassDisabled : fieldClass;

          return (
            <Form>
              <div className="scrollbar-outside max-h-[630px] mb-8 overflow-y-auto">
                {readOnlyFields.map((value, key) => {
                  const labelText = labelsMap[value];

                  return (
                    <div key={key}>
                      {labelText && (
                        <p className="my-2">
                          <label
                            className="body-sm text-[#8F8F8F]"
                            htmlFor={value}
                          >
                            {labelText}
                          </label>
                        </p>
                      )}
                      {!initializing ? (
                        <div className="relative [&>.react-datepicker-wrapper]:block">
                          <>
                            <Field
                              id={value}
                              name={value}
                              type="text"
                              className={`${fieldClassFinal} bg-white`}
                              disabled={editMode}
                            />

                            <ErrorMessage name={value}>
                              {(msg) => (
                                <span className="text-[10px] max-w-[293px] text-ea-red">
                                  {msg}
                                </span>
                              )}
                            </ErrorMessage>
                          </>

                          {editMode && (
                            <Icon
                              type="check"
                              className={iconClass}
                              color="#00C45A"
                            />
                          )}
                        </div>
                      ) : (
                        <Loader
                          style={{
                            height: "30px",
                          }}
                        />
                      )}
                    </div>
                  );
                })}

                {/* For now we are not displaying the nationality, will be uncommented if needed in future */}
                {/* Nationality */}
                {/* <p className="my-2">
                  <label
                    className="body-sm text-[#8F8F8F]"
                    htmlFor="nationality"
                  >
                    {labels.nationality}
                  </label>
                </p>
                <>
                  {!initializing ? (
                    <Field
                      id="nationality"
                      name="nationality"
                      type="text"
                      className={fieldClass}
                      initialValue={lawyer.nationality}
                    />
                  ) : (
                    <Loader
                      style={{
                        height: "30px",
                      }}
                    />
                  )}
                  <ErrorMessage name="nationality">
                    {(msg) => (
                      <span className="text-[10px] max-w-[293px] text-ea-red">
                        {msg}
                      </span>
                    )}
                  </ErrorMessage>
                </> */}

                {/* Email */}
                <p className="my-2">
                  <label className="body-sm text-[#8F8F8F]" htmlFor="email">
                    {labels.email}
                  </label>
                </p>
                <>
                  {!initializing ? (
                    <Field
                      id="email"
                      name="email"
                      type="text"
                      className={fieldClass}
                      validate={validateEmail}
                      initialValue={lawyer.email}
                    />
                  ) : (
                    <Loader
                      style={{
                        height: "30px",
                      }}
                    />
                  )}
                  <ErrorMessage name="email">
                    {(msg) => (
                      <span className="text-[10px] max-w-[293px] text-ea-red">
                        {msg}
                      </span>
                    )}
                  </ErrorMessage>
                </>

                {/* Phone */}
                <p className="my-2">
                  <label className="body-sm text-[#8F8F8F]" htmlFor="phone">
                    {labels.phone}
                  </label>
                </p>

                {!initializing ? (
                  <PhoneField
                    setFieldValue={formik.setFieldValue}
                    inversedDropdown={true}
                  />
                ) : (
                  <Loader
                    style={{
                      height: "30px",
                    }}
                  />
                )}
              </div>

              <div className="flex justify-between">
                <Button
                  className="btn-secondary flex justify-start items-center rounded-[8px] px-[21px] py-[8px] body-md h-[34px]"
                  onClick={() => dispatch(hideModal())}
                >
                  {labels.cancel}
                </Button>
                <Button
                  className="btn btn-secondary-emphasize flex justify-center items-center rounded-[8px] px-[21px] py-[8px] body-md h-[34px]"
                  type="submit"
                  disabled={loading}
                >
                  {loading && <Spinner className="size-4 mr-2" />}
                  {labels.save}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default EditLawyer;
