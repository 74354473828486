import axios from "axios";
import { Field, Form, Formik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import Button from "@ui/Button";
import Icon from "@ui/Icon";
import Modal from "@ui/Modal";
import Spinner from "@ui/Spinner";

import { axiosErrorMessages, labels, toastOptionsError } from "@constants";

import {
  selectModalData,
  setModalData,
  setPageData,
} from "@reducers/dataTransferSlice";
import { hideModal } from "@reducers/modalsSlice";

const AdvancedSearchLawyer = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const modalData = useSelector(selectModalData);

  const handleAdvancedSearchLawyer = (values: any) => {
    setLoading(true);
    const { firstName, lastName, codeCNBF, email } = values;
    if (
      firstName.length === 0 &&
      lastName.length === 0 &&
      codeCNBF.length === 0 &&
      email.length === 0
    ) {
      setLoading(false);
      dispatch(hideModal());
      return;
    }

    axios
      .post(`/api/v1/lawyers/search`, {
        firstName,
        lastName,
        codeCNBF,
        email,
        page: 0,
        size: 5,
      })
      .then((response) => {
        const { data } = response;
        dispatch(
          setPageData({
            advancedSearchResults: data.lawyers,
            searchableField: modalData.searchableField,
            searchCriterias: {
              firstName,
              lastName,
              codeCNBF,
              email,
            },
          }),
        );
        dispatch(setModalData(null));
        dispatch(hideModal());
      })
      .catch((error) => {
        const errorMessage = axiosErrorMessages[error.message] || error.message;
        toast.error(errorMessage, toastOptionsError);
      });
  };

  return (
    <Modal
      title={labels.advancedSearchTabLabel}
      description={labels.searchByNameChbfNumberOrEmail}
      width={400}
    >
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          codeCNBF: "",
          email: "",
        }}
        onSubmit={(values) => {
          handleAdvancedSearchLawyer(values);
        }}
      >
        {({ resetForm }) => (
          <Form className="mt-2">
            <div>
              <label className="text-[#8F8F8F] body-sm" htmlFor="lastName">
                {labels.surname}
              </label>
              <div className="flex relative mt-2 mb-4">
                <Icon
                  type="search"
                  color="black"
                  className="absolute size-[16px] top-[50%] translate-y-[-50%] left-[14px]"
                />
                <Field
                  type="text"
                  name="lastName"
                  className="flex pl-[47px] pr-[10px] py-2 w-full text-xs bg-white rounded border border-solid border-ea-gray-100 text-black"
                />
              </div>
            </div>

            <div>
              <label className="text-[#8F8F8F] body-sm" htmlFor="firstName">
                {labels.name}
              </label>
              <div className="flex relative mt-2 mb-4">
                <Icon
                  type="search"
                  color="black"
                  className="absolute size-[16px] top-[50%] translate-y-[-50%] left-[14px]"
                />
                <Field
                  type="text"
                  name="firstName"
                  className="flex pl-[47px] pr-[10px] py-2 w-full text-xs bg-white rounded border border-solid border-ea-gray-100 text-black"
                />
              </div>
            </div>

            <div>
              <label className="text-[#8F8F8F] body-sm" htmlFor="codeCNBF">
                {labels.codeCNBF}
              </label>
              <div className="flex relative mt-2 mb-4">
                <Icon
                  type="search"
                  color="black"
                  className="absolute size-[16px] top-[50%] translate-y-[-50%] left-[14px]"
                />
                <Field
                  type="text"
                  name="codeCNBF"
                  className="flex pl-[47px] pr-[10px] py-2 w-full text-xs bg-white rounded border border-solid border-ea-gray-100 text-black"
                />
              </div>
            </div>

            <div>
              <label className="text-[#8F8F8F] body-sm" htmlFor="email">
                {labels.electronicAddress}
              </label>
              <div className="flex relative mt-2 mb-4">
                <Icon
                  type="search"
                  color="black"
                  className="absolute size-[16px] top-[50%] translate-y-[-50%] left-[14px]"
                />
                <Field
                  type="text"
                  name="email"
                  className="flex pl-[47px] pr-[10px] py-2 w-full text-xs bg-white rounded border border-solid border-ea-gray-100 text-black"
                />
              </div>
            </div>

            <div className="flex items-center justify-between mt-[40px]">
              <Button
                type="button"
                onClick={() => {
                  resetForm();
                }}
              >
                <Icon type="refresh" color="#7F7F7F" className="size-[16px]" />
              </Button>
              <Button
                type="submit"
                className="btn-secondary-emphasize rounded-[8px] px-[13px] py-[6px] body-md flex items-center justify-center"
                disabled={loading}
              >
                {loading && <Spinner className="mr-[10px] text-white size-2" />}
                {labels.search}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AdvancedSearchLawyer;
