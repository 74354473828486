import axios from "axios";
import { useEffect, useState } from "react";

import { labels } from "@constants";

import { getStatusText } from "@utils";

const useGetStatuses = (statusData: Record<string, any>[]) => {
  const [statuses, setStatues] = useState<
    Record<
      string,
      {
        message: string;
        progress: number | null;
        showProgress: boolean;
      }
    >
  >({});

  const updateStatuses = (
    id: string,
    progress: number | null,
    message: string,
    showProgress: boolean = true,
  ) => {
    setStatues((prev) => {
      return {
        ...prev,
        [id]: {
          message,
          progress,
          showProgress,
        },
      };
    });
  };

  useEffect(() => {
    if (!statusData.length) {
      return;
    }

    async function fetchData(id: string) {
      return axios.get(`/api/v1/signbooks/${id}/status`);
    }

    async function fetchSignbookStatus(id: string) {
      try {
        const response = await fetchData(id);
        const { progress, message } = response.data;

        if (message.includes("no data found")) {
          updateStatuses(id, null, labels.signing);
          return;
        }

        updateStatuses(id, progress, message);
      } catch (error) {
        updateStatuses(id, null, "");
      }
    }

    async function fetchAllStatusesSequentially() {
      for (const statusDataEntry of statusData) {
        const { publicId, status, signingStatus } = statusDataEntry;

        const loadedStatuses = Object.keys(statuses);

        if (loadedStatuses.includes(publicId)) {
          continue;
        }

        const isClosed = ["closed", "cancelled", "expired"].includes(status);
        const signingNotInProgress = "SIGNING" !== signingStatus;

        if (isClosed || signingNotInProgress) {
          updateStatuses(
            publicId,
            null,
            getStatusText(status, signingStatus) || "",
            false,
          );
          continue;
        }

        await fetchSignbookStatus(publicId);
      }
    }

    fetchAllStatusesSequentially();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusData]);

  return statuses;
};

export default useGetStatuses;
