import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Icon from "@ui/Icon";
import Spinner from "@ui/Spinner";

import { labels } from "@constants";

import { showModal } from "@reducers/modalsSlice";
import {
  selectImage,
  selectSaveImageAction,
  selectUseByDefault,
  setImage,
  setSaveImageAction,
  setUseByDefault,
} from "@reducers/signatureSlice";
import { selectLoading, setLoading } from "@reducers/tracesSlice";

import { SignatureProps } from "@types";

import { noop } from "@utils";

const Signature = ({ isDark = false, readOnly = false }: SignatureProps) => {
  const imageStateAction = useSelector(selectSaveImageAction);
  const [signature, setSignature] = useState(useSelector(selectImage));
  const loading = useSelector(selectLoading);
  const useByDefault = useSelector(selectUseByDefault);
  const isSignature = signature && "string" === typeof signature;

  const radioColor = isDark ? "before:border-white" : "before:border-black";
  const beforeBorderStyle = isSignature
    ? "before:border-black"
    : "before:border-ea-gray-100";

  const textColor = isDark ? "text-white" : "text-black";
  const disabledtextColor = isSignature ? "text-black" : "text-ea-gray-100";

  const dispatch = useDispatch();

  const handleRadioChange = () => {
    dispatch(setUseByDefault({ useByDefault: !useByDefault }));
  };

  useEffect(() => {
    if (!imageStateAction && signature) {
      return;
    }
    dispatch(setLoading(true));

    const fetchSignature = async () => {
      try {
        const response = await axios.get("/api/v1/me/signature", {
          responseType: "blob",
        });

        if (response.data && response.data instanceof Blob) {
          // Create a URL for the Blob
          const url = URL.createObjectURL(response.data);
          // Dispatch the action to set the image
          dispatch(setImage({ image: url }));
          setSignature(url);
          dispatch(setSaveImageAction({ saveImageAction: false }));
          // Set the signature state
          dispatch(setLoading(false));
          // Cleanup function to revoke the Object URL
          return () => {
            URL.revokeObjectURL(url);
          };
        }
      } catch (error) {
        setSignature(null);
        dispatch(setImage({ image: null }));
        dispatch(setSaveImageAction({ saveImageAction: false }));
        dispatch(setUseByDefault({ useByDefault: false }));
        dispatch(setLoading(false));
      }
    };

    // Fetch the signature after 1 second, because BE is returning 200 OK but the image is not saved yet
    setTimeout(() => {
      dispatch(setLoading(true));
      fetchSignature();
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageStateAction]);

  return (
    <div>
      {loading ? (
        <Spinner className="size-7" dark={true} />
      ) : isSignature && signature ? (
        <>
          <div
            className={`relative w-[250px] h-[150px] lg:w-[101px] lg:h-[80px] lg:ml-[31px] bg-slate-200 rounded ${!readOnly && "cursor-pointer"}`}
            onClick={() => {
              if (readOnly) {
                return;
              }

              dispatch(showModal("editSignature"));
            }}
          >
            {signature && "string" === typeof signature && (
              <img
                src={signature}
                alt={labels.signature}
                className="absolute top-0 left-0 w-full h-full object-contain"
              />
            )}
            {!readOnly && (
              <Icon
                type="edit"
                className="absolute bottom-0 right-0 mb-2 mr-2 w-[17px] h-[17px]"
                color="#929292"
              />
            )}
          </div>
          {!readOnly && (
            <div className="hidden xl:flex items-center mt-3 pl-3">
              <input
                id="radio1"
                type="radio"
                name="radio"
                className={`w-[14px] h-[14px] inline-block mr-1 border border-grey rounded-md relative appearance-none bg-transparent bg-white ${radioColor} after:bg-black ${beforeBorderStyle}`}
                onClick={handleRadioChange}
                checked={useByDefault}
                disabled={!isSignature}
                onChange={noop}
              />
              <label
                htmlFor="radio1"
                className={`cursor-pointer body-md select-none ${textColor} ${disabledtextColor}`}
              >
                {labels.defaultAppliedLabel}
              </label>
            </div>
          )}
        </>
      ) : (
        <>
          {!readOnly ? (
            <div
              className="flex items-center mt-3 pl-3 cursor-pointer  justify-center md:justify-start"
              onClick={() => {
                dispatch(showModal("editSignature"));
              }}
            >
              <p className={`body-lg ${textColor}`}>{labels.addSignature}</p>
            </div>
          ) : (
            <p className="text-ea-red">{labels.noSignatureFound}</p>
          )}
        </>
      )}
    </div>
  );
};

export default Signature;
