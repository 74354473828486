import { ProgressBarProps } from "@types";

const ProgressBar = ({ currentStep, maxSteps }: ProgressBarProps) => {
  const percentage = Math.ceil((currentStep / maxSteps) * 100);

  return (
    <div className="flex flex-col flex-1 justify-center pr-14 my-auto rounded bg-ea-gray-100 relative h-2">
      <span
        className="h-2 rounded bg-slate-800 absolute top-0 left-0"
        role="progressbar"
        style={{
          width: `${percentage}%`,
        }}
      ></span>
    </div>
  );
};

export default ProgressBar;
