import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";

import Button from "@ui/Button";
import CustomDatePickerHeader from "@ui/DatepickerCustomHeader";
import Icon from "@ui/Icon";
import Spinner from "@ui/Spinner";

import { labels } from "@constants";

import { selectLoading } from "@reducers/tracesSlice";

import { HistoryFiltersProps } from "@types";

import { isStartDateBeforeEndDate, noop, setDates } from "@utils";

const HistoryFilters = ({
  setShowFilters,
  setFilters,
  setReset,
}: HistoryFiltersProps) => {
  const [startDate, setStartDate] = useState<Date | string>("");
  const [endDate, setEndDate] = useState<Date | string>("");
  const loading = useSelector(selectLoading);

  const resetFilters = (formik: any) => {
    setStartDate("");
    formik.setFieldValue("startDate", "");
    setEndDate("");
    formik.setFieldValue("endDate", "");
    setFilters({ startDate: "", endDate: "" });
    setReset(true);
  };

  const formatDate = (date: Date) => {
    return date.toLocaleDateString("fr-FR"); // 'en-GB' gives you dd/mm/yyyy format
  };

  const handleSearch = () => {
    if (startDate === "" && endDate === "") {
      return;
    }
    setFilters({
      startDate: formatDate(startDate as Date),
      endDate: formatDate(endDate as Date),
    });
  };

  const onChange = (dates: [Date | string, Date | string], formik: any) => {
    const [start, end] = dates;
    setStartDate(start);
    formik.setFieldValue("startDate", start);
    setEndDate(end);
    formik.setFieldValue("endDate", end);
  };

  return (
    <Formik
      initialValues={{
        startDate: "",
        endDate: "",
      }}
      onSubmit={noop}
    >
      {(formik) => {
        return (
          <Form>
            <div className="flex flex-col items-start self-stretch my-auto max-md:mt-10 max-md:max-w-full history-filters">
              <div className="flex flex-col self-stretch pb-4 bg-white rounded-lg max-md:max-w-full">
                <div className="flex flex-col bg-white max-md:max-w-full">
                  <h3 className="body-lg font-bold">{labels.filters}</h3>
                  <div className="flex gap-5 justify-end w-full text-sm text-black max-md:flex-wrap max-md:pr-5 max-md:max-w-full">
                    <Button type="button" onClick={() => setShowFilters(false)}>
                      <Icon
                        type="close"
                        color="#000"
                        className="w-[14px] h-[13px]"
                      />
                    </Button>
                  </div>

                  <div className="flex gap-5 justify-between mt-1.5 max-md:flex-wrap max-md:pr-5 max-md:max-w-full">
                    <div className="flex flex-col flex-1">
                      <label
                        htmlFor="paymentDate"
                        className="body-sm mb-[10px]"
                      >
                        {labels.paymentDate}
                      </label>
                      <div className="relative">
                        <Icon
                          type="calendar"
                          color="#000"
                          className="w-[18px] h-[20px] mr-[19px] absolute top-[50%] left-[16px] transform -translate-y-1/2 z-10"
                        />
                        {/* @ts-ignore DatePicker requires null or undefined which throws error for formik */}
                        <DatePicker
                          renderCustomHeader={(props) => (
                            <CustomDatePickerHeader {...props} />
                          )}
                          className="font-medium max-w-[250px] text-black pl-[40px] py-2 pr-[10px] bg-white rounded border border-solid border-ea-gray-100 max-md:pr-5"
                          selected={startDate}
                          onChange={(dates) =>
                            onChange(
                              [
                                dates[0] ? dates[0] : "",
                                dates[1] ? dates[1] : "",
                              ],
                              formik,
                            )
                          }
                          startDate={startDate}
                          endDate={endDate}
                          onBlur={(event) => {
                            setDates(
                              event.target.value,
                              formik,
                              setStartDate,
                              setEndDate,
                            );
                          }}
                          selectsRange
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                      <Field
                        type="hidden"
                        name="startDate"
                        validate={() =>
                          isStartDateBeforeEndDate(
                            formik.values.startDate,
                            formik.values.endDate,
                          )
                        }
                      />
                      <Field
                        type="hidden"
                        name="endDate"
                        validate={() =>
                          isStartDateBeforeEndDate(
                            formik.values.startDate,
                            formik.values.endDate,
                          )
                        }
                      />
                      <ErrorMessage name="startDate">
                        {(msg) => (
                          <span className="text-[10px] max-w-[293px] text-ea-red">
                            {msg}
                          </span>
                        )}
                      </ErrorMessage>
                    </div>

                    <div className="flex items-center justify-end mt-[30px] text-white whitespace-nowrap w-[150px]">
                      <Button
                        type="button"
                        onClick={() => resetFilters(formik)}
                      >
                        <Icon
                          type="refresh"
                          color="#7F7F7F"
                          className="w-[16px] h-[16px] mr-[16px]"
                        />
                      </Button>
                      <Button
                        type="submit"
                        onClick={handleSearch}
                        disabled={loading}
                        className="btn-base btn-secondary-emphasize flex items-center justify-center"
                      >
                        {loading && <Spinner className="size-4 mr-4" />}
                        {labels.apply}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default HistoryFilters;
