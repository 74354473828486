import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { invoiceDownloadApi } from "@utils/api/invoiceHistoryApi";

const DownloadInvoice = () => {
  const { invoiceId } = useParams();

  useEffect(() => {
    if (invoiceId) {
      invoiceDownloadApi(invoiceId);
    }
  }, [invoiceId]);

  return null;
};
export default DownloadInvoice;
