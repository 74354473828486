import { useState } from "react";

import Icon from "@ui/Icon";
import Toggle from "@ui/Toggle";

const EAFDebug = () => {
  const [eBeeAccessFoundation, setEbeeAccessFoundation] = useState(
    localStorage.getItem("eBeeAccessFoundation") === "on",
  );
  const [validToken, setValidToken] = useState(
    localStorage.getItem("validToken") === "on",
  );
  const [minimized, setMinimized] = useState(
    localStorage.getItem("eafDebugMinized") === "on",
  );
  const arrowClass = minimized ? "arrow-sort-up" : "arrow-sort-down";

  const isOn = (name: string) => {
    return name === "eBeeAccessFoundation" ? eBeeAccessFoundation : validToken;
  };

  const toggleMinimized = () => {
    const newMinimized = !minimized;

    setMinimized(newMinimized);

    if (newMinimized) {
      localStorage.setItem("eafDebugMinized", "on");
      return;
    }

    localStorage.removeItem("eafDebugMinized");
  };

  const setToggle = (name: string, isOn: boolean) => {
    const stateSetter =
      name === "eBeeAccessFoundation" ? setEbeeAccessFoundation : setValidToken;

    stateSetter(isOn);

    if (isOn) {
      localStorage.setItem(name, "on");
      return;
    }

    localStorage.removeItem(name);
  };

  return process.env.REACT_APP_EAF_DEBUG === "true" ? (
    <div className="fixed bottom-0 right-0 z-[1000000] w-[205px] select-none">
      <div className="text-xs text-slate-500 bg-white px-3 border-t border-l border-slate-500">
        <div
          className="flex items-center justify-between py-1 cursor-pointer"
          onClick={toggleMinimized}
        >
          <h2 className="text-lg text-center">EAF Debug</h2>
          <Icon type={arrowClass} className="size-3" />
        </div>
        {!minimized && (
          <div className="space-y-2 pt-2 pb-3">
            <div className="flex items-center justify-between">
              <span className="mr-4">eBee Access Foundation</span>
              <Toggle
                name="eBeeAccessFoundation"
                enabled={isOn("eBeeAccessFoundation")}
                callback={setToggle}
              />
            </div>
            <div
              className={`flex items-center justify-between ${!isOn("eBeeAccessFoundation") && "opacity-50 pointer-events-none"}`}
            >
              <span className="mr-4">Valid Token</span>
              <Toggle
                name="validToken"
                enabled={isOn("validToken")}
                callback={setToggle}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  ) : null;
};

export default EAFDebug;
