import { useDispatch, useSelector } from "react-redux";

import { hideOverlay, selectOverlay } from "@reducers/overlaySlice";

import { OverlayProps } from "@types";

const Overlay = ({ name }: OverlayProps) => {
  const dispatch = useDispatch();

  const overlay = name === useSelector(selectOverlay);
  const overlayClass = overlay ? "visible opacity-100" : "invisible opacity-0";

  return (
    <div
      data-testid="overlay"
      className={`${overlayClass} overlay`}
      onClick={() => {
        dispatch(hideOverlay());
      }}
    ></div>
  );
};

export default Overlay;
