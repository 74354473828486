import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "@types";

const initialState = {
  overlay: "",
};

const overlaySlice = createSlice({
  name: "overlay",
  initialState,
  reducers: {
    showOverlay: (state, action) => {
      state.overlay = action.payload;
    },
    hideOverlay: (state) => {
      state.overlay = "";
    },
  },
});

export const { showOverlay, hideOverlay } = overlaySlice.actions;

export const selectOverlay = (state: RootState) => state.overlay.overlay;
export default overlaySlice.reducer;
