import axios from "axios";
import { toast } from "react-toastify";

import { axiosErrorMessages, toastOptionsError } from "@constants";

import { addQueryParams } from "@utils";

const paymentWithPrepaidAccount = (signbookNumber: string, billing: string) => {
  return axios
    .get(
      addQueryParams(
        `/api/v1/signbooks/${signbookNumber}/prepaid-account-payment`,
        { paymentBuyer: billing },
      ),
    )
    .then((response) => {
      return response.status;
    })
    .catch((error) => {
      if (axios.isAxiosError(error)) {
        const errorMessage = error.response?.data?.message || error.message;
        toast.error(axiosErrorMessages[errorMessage], toastOptionsError);
      }
    });
};

const paymentWithBankCard = (signbookNumber: string, billing: string) => {
  return axios
    .get(
      addQueryParams(`api/v1/signbooks/${signbookNumber}/payment-transaction`, {
        paymentBuyer: billing,
      }),
    )
    .then((response) => {
      const { sessionUrl } = response.data;

      window.location.href = addQueryParams(sessionUrl, {
        returnUrl: window.location.href,
      });
    })
    .catch((error) => {
      if (axios.isAxiosError(error)) {
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      }
    });
};

export { paymentWithBankCard, paymentWithPrepaidAccount };
