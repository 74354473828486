import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Button from "@ui/Button";
import Spinner from "@ui/Spinner";
import Table from "@ui/Table";

import { actMapAmount, actTypeNameMap, labels } from "@constants";

import useIsMobile from "@hooks/useIsMobile";
import useWindowSize from "@hooks/useWindowSize";

import { RequiredActionsContext } from "@pages/RequiredAction";

import { setModalData } from "@reducers/dataTransferSlice";
import { selectSettings } from "@reducers/metadataSlice";
import { showModal } from "@reducers/modalsSlice";

import { PaymentToBeDoneTableProps } from "@types";

import { formatTimestamp } from "@utils";

const PaymentToBeDoneTable = () => {
  const dispatch = useDispatch();
  const data = useContext(RequiredActionsContext);
  const isMobile = useIsMobile();
  const paddingMobile = !isMobile ? "pr-[40px]" : "";
  const settings = useSelector(selectSettings);

  const tableDataFormatted = data.tableData.payments.map((item: any) => {
    return {
      publicId: item.publicId,
      title: item.name,
      creationDate: formatTimestamp(item.creationDate, false),
      expirationDate: formatTimestamp(item.expirationDate, false),
      amount:
        settings[
          actMapAmount[
            item.type as keyof typeof actMapAmount
          ] as keyof typeof settings
        ],
      state: item.status,
      payed: item.payed,
      actType: actTypeNameMap[item.type],
      signingStatus: item.signingStatus,
      status: item.status,
    };
  });

  const columns = [
    {
      label: labels.title,
      render: (data: PaymentToBeDoneTableProps) => {
        return (
          <Link to={`/acts/${data.actType}/act-details/${data.publicId}`}>
            {data.title}
          </Link>
        );
      },
      className: "body-lg py-[15px] h-[90px]",
    },
    {
      label: labels.creationDate,
      render: (data: PaymentToBeDoneTableProps) => data.creationDate,
    },
    {
      label: labels.deadline,
      render: (data: PaymentToBeDoneTableProps) => data.expirationDate,
    },
    {
      label: "",
      render: (data: PaymentToBeDoneTableProps) => (
        <Button
          className="flex items-center justify-center px-[15px] py-[10px] body-md rounded-lg max-w-[90px] w-[100%] btn-secondary-emphasize"
          onClick={() => {
            dispatch(setModalData(data));
            dispatch(showModal("payment"));
          }}
        >
          Payer {data.amount} {labels.euroSign}
        </Button>
      ),
      className: `body-lg py-[15px] flex justify-end ${paddingMobile} h-[90px] items-center h-[90px] items-center`,
    },
  ];
  if (useWindowSize() <= 740) {
    columns.splice(1, columns.length - 2);
  }
  return (
    <>
      {data.loading ? (
        <div className="w-full h-full pt-[40px] flex justify-center items-center">
          <Spinner className="size-10" dark={true} />
        </div>
      ) : (
        <Table columns={columns} rows={tableDataFormatted} />
      )}
    </>
  );
};

export default PaymentToBeDoneTable;
