import { Field, useFormikContext } from "formik";
import { useEffect, useState } from "react";

import Button from "@ui/Button";
import Icon from "@ui/Icon";

import { countries } from "@constants/region";

import { CountryFieldProps } from "@types";

const CountryField = ({
  nationality = "countryNationalityCode",
  setFieldValue,
  inversedDropdown = false,
  disabled = false,
}: CountryFieldProps) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const { getFieldProps } = useFormikContext();
  const countryCodeValue = getFieldProps(nationality).value;
  const dropdownPositionClass = inversedDropdown ? "bottom-full" : "top-full";

  const countrySelectClass = disabled
    ? "country-select country-select-disabled"
    : "country-select";

  const disabledField = disabled
    ? "border-[#F5F5F5] bg-white"
    : "border-[#B4B4B4]";

  // Set default to "France" on mount
  useEffect(() => {
    if (!countryCodeValue) {
      setFieldValue(nationality, "FR");
    }
  }, [setFieldValue, nationality, countryCodeValue]);

  const CountriesDropdown = () => {
    useEffect(() => {
      const hideDropdown = () => {
        if (showDropdown) {
          setShowDropdown(false);
        }
      };

      window.addEventListener("click", hideDropdown);

      return () => {
        window.removeEventListener("click", hideDropdown);
      };
    }, []);

    useEffect(() => {
      if (showDropdown) {
        const container = document.getElementById("country-select-dropdown");
        const targetItem = document.getElementById("country-selected");

        if (container && targetItem) {
          const containerRect = container.getBoundingClientRect();
          const itemRect = targetItem.getBoundingClientRect();
          const scrollOffset = itemRect.top - containerRect.top;

          container.scrollBy({
            top: scrollOffset,
          });
        }
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showDropdown]);

    if (!showDropdown) {
      return null;
    }

    return (
      <div
        id="country-select-dropdown"
        className={`country-select-dropdown ${dropdownPositionClass}`}
        data-testid="country-select-dropdown"
      >
        <ul className="py-2 text-sm">
          {Object.entries(countries).map(([code, name], index) => {
            const selectedID =
              code === countryCodeValue ? "country-selected" : "";

            return (
              <li key={index} id={selectedID}>
                <Button
                  type="button"
                  className="inline-flex w-full px-4 py-2 text-sm body-sm"
                  role="menuitem"
                  onClick={() => {
                    setFieldValue(nationality, code);
                    setShowDropdown(false);
                  }}
                >
                  <div className="inline-flex items-center">{name}</div>
                </Button>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    <div className="relative w-full body-lg cursor-pointer">
      <div
        className={`self-stretch border ${disabledField} h-[34px] py-1 px-4 rounded-[4px]`}
      >
        <Field as="select" className="hidden" name={nationality}>
          {Object.entries(countries).map(([code, name], index) => (
            <option key={index} value={code}>
              {name}
            </option>
          ))}
        </Field>
        <div
          className={countrySelectClass}
          onClick={(event) => {
            if (disabled) {
              return;
            }

            event.stopPropagation();
            setShowDropdown(!showDropdown);
          }}
          data-testid="country-select-dropdown-toggle"
        >
          {countries[countryCodeValue] || "France"}
        </div>
        {disabled ? null : (
          <Icon
            type="dropdown-arrow"
            className="size-[12px] absolute top-1/2 right-[16px] -translate-y-2/4 pointer-events-none"
            color="#000"
          />
        )}
        <CountriesDropdown />
      </div>
    </div>
  );
};

export default CountryField;
