import { useContext } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import Icon from "@ui/Icon";
import Spinner from "@ui/Spinner";
import Table from "@ui/Table";

import { actTypeNameMap, labels } from "@constants";

import useIsMobile from "@hooks/useIsMobile";
import useWindowSize from "@hooks/useWindowSize";

import { RequiredActionsContext } from "@pages/RequiredAction";

import { setModalData } from "@reducers/dataTransferSlice";

import { DocumentForSigningTableProps } from "@types";

import { addQueryParams, formatTimestamp } from "@utils";

const DocumentForSigningTable = () => {
  const dispatch = useDispatch();
  const data = useContext(RequiredActionsContext);

  const isMobile = useIsMobile();
  const paddingMobile = !isMobile ? "pr-[40px]" : "";
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const actorCode = queryParams.get("actorCode") || "";
  const processNumber = queryParams.get("processNumber") || "";
  const mustReadAllDocs = queryParams.get("mustReadAllDocs") || "";

  const useSize = useWindowSize();
  const columnsMaxWidth =
    useSize > 932
      ? "max-w-[250px]"
      : useSize <= 667
        ? "max-w-[250px]"
        : "max-w-[100px]";

  const columns = [
    {
      label: labels.title,
      render: (data: DocumentForSigningTableProps) => (
        <div className="relative inline-block">
          <p
            className={`whitespace-nowrap relative overflow-hidden text-ellipsis ${columnsMaxWidth} mr-4`}
          >
            <Link
              to={`/acts/${actTypeNameMap[data.type]}/act-details/${data.publicId}`}
            >
              {data.name}
            </Link>
          </p>
        </div>
      ),
      className: "body-xl py-[15px] h-[90px]",
    },
    {
      label: labels.lawyer,
      render: (data: DocumentForSigningTableProps) => (
        <div className="flex items-center">
          <p className={`overflow-hidden text-ellipsis ${columnsMaxWidth}`}>
            {data.sbCreatorName}
          </p>
        </div>
      ),
    },
    {
      label: labels.creationDate,
      render: (data: DocumentForSigningTableProps) =>
        formatTimestamp(data.creationDate, false),
    },
    {
      label: labels.deadline,
      render: (data: DocumentForSigningTableProps) => {
        return (
          <div className="flex items-center">
            {formatTimestamp(data.expirationDate, false)}
          </div>
        );
      },
    },
    {
      label: labels.status,
      render: (tableData: DocumentForSigningTableProps) => {
        if (!tableData.publicId) {
          return null;
        }

        return (
          <data.ActStatus
            actData={tableData}
            loadedStatuses={data.loadedStatuses}
            loadedStatusesError={data.loadedStatusesError}
          />
        );
      },
      className: "text-[10px] whitespace-nowrap overflow-hidden text-ellipsis",
    },
    {
      label: "",
      render: (data: DocumentForSigningTableProps) => (
        <Link
          to={addQueryParams("/lawyer-signing", {
            actId: data.publicId,
            actorCode: actorCode,
            processNumber: processNumber,
            documentId: data.sbDocumentUid || "",
            returnPath: "/required-actions/sign-documents",
            actTitle: data.name,
            mustReadAll: mustReadAllDocs,
            isSecure: "false",
          })}
          className="flex items-center justify-start px-[15px] py-[10px] body-md rounded-lg max-w-[95px] w-[100%] btn-secondary-emphasize"
          onClick={() => dispatch(setModalData(data))}
        >
          <Icon type="pen" className="w-[14px] h-[14px] mr-[7px]" />
          {labels.sign}
        </Link>
      ),
      className: `body-lg py-[15px] flex justify-end h-[90px] items-center ${paddingMobile}`,
    },
  ];
  if (useWindowSize() <= 740) {
    columns.splice(1, columns.length - 2);
  }
  if (useWindowSize() <= 896) {
    columns.splice(2, columns.length - 4);
  }

  return (
    <div className="documents-for-signing-table">
      {data.loading ? (
        <div className="w-full h-full pt-[40px] flex justify-center items-center">
          <Spinner className="size-10" dark={true} />
        </div>
      ) : (
        <Table columns={columns} rows={data.tableData.signing} />
      )}
    </div>
  );
};

export default DocumentForSigningTable;
