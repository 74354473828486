import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import Button from "@ui/Button";
import Icon from "@ui/Icon";
import Loader from "@ui/Loader";
import Overlay from "@ui/Overlay";

import { labels } from "@constants";

import {
  selectErrors,
  selectRequiredActionsCount,
  selectSettings,
  setSettings,
} from "@reducers/metadataSlice";
import { hideOverlay, selectOverlay } from "@reducers/overlaySlice";

import { SidebarProps } from "@types";

const Sidebar = ({ version }: SidebarProps) => {
  const dispatch = useDispatch();

  const linkClassDefault =
    " flex gap-2 justify-start px-8 py-3.5 items-center hover:bg-violet-100";
  const linkClass = ({ isActive }: { isActive: boolean }) =>
    (isActive ? "text-ea-darkblue [&>p]:text-ea-darkblue lg:bg-violet-100" : "")
      .concat(linkClassDefault)
      .trim();
  const errors = useSelector(selectErrors);
  const settings = useSelector(selectSettings);
  const overlay = "sidebar" === useSelector(selectOverlay);
  const sidebarClass = overlay ? "left-0" : "left-[-200px]";
  const requiredActionsCount = useSelector(selectRequiredActionsCount);

  return (
    <>
      <Overlay name="sidebar" />
      <aside className={`${sidebarClass} sidebar`}>
        <div className="flex flex-col grow pt-3 lg:pt-9 w-full body-lg font-medium text-center bg-white border-r border-solid border-r-ea-gray-100 text-ea-gray-300">
          <div className="mb-24 flex items-center relative lg:justify-center">
            <NavLink to="/" rel="noreferrer" data-testid="logo-link">
              <img
                loading="lazy"
                srcSet="/assets/logo.svg"
                className="max-w-full mx-3.5 lg:mx-0 w-[102px] lg:w-[138px] lg:px-3.5 lg:py-3"
                alt="logo"
              />
            </NavLink>
            <div className="absolute top-[50%] right-[12px] -translate-y-2/4 text-black xl:hidden">
              <button
                onClick={() => {
                  dispatch(hideOverlay());
                }}
              >
                <Icon type="close" className="size-[14px]" />
              </button>
            </div>
          </div>
          <div className="flex flex-col justify-between h-full flex-grow">
            <ul>
              <li className="flex flex-col justify-center w-full">
                <NavLink className={linkClass} to="/" data-testid="home-link">
                  <Icon
                    type="home"
                    className="self-start aspect-square w-[20px]"
                  />
                  <p className="text-left">{labels.welcome}</p>
                </NavLink>
              </li>
              <li className="flex flex-col justify-center w-full bg-white">
                <NavLink
                  className={linkClass}
                  to="/required-actions"
                  data-testid="required-actions-link"
                >
                  <Icon
                    type="warning"
                    className="self-start aspect-square w-[20px]"
                  />
                  <p className="flex-auto text-left relative">
                    {Number(requiredActionsCount.totalCount) > 0 && (
                      <span
                        role="presentation"
                        className="absolute bg-ea-red w-[12px] h-[12px] top-[-3px] right-0 rounded-[50%]"
                      ></span>
                    )}
                    {labels.actionRequired}
                  </p>
                </NavLink>
              </li>
              <li className="flex flex-col justify-center w-full bg-white">
                <NavLink
                  className={linkClass}
                  to="/acts"
                  data-testid="acts-link"
                >
                  <Icon
                    type="document"
                    className="self-start aspect-square w-[20px]"
                  />
                  <p className="flex-auto text-left">{labels.acts}</p>
                </NavLink>
              </li>
              <li className="flex flex-col justify-center w-full bg-white">
                <NavLink
                  className={linkClass}
                  to="/signatures"
                  data-testid="signatures-link"
                >
                  <Icon
                    type="person"
                    className="self-start aspect-square w-[20px]"
                  />
                  <p className="flex-auto text-left">{labels.signatures}</p>
                </NavLink>
              </li>
            </ul>

            <div className="flex flex-col px-7 py-3.5 text-xs bg-white">
              <ul className="whitespace-nowrap flex flex-col items-left">
                <li className="mt-2.5 text-left">
                  <Button
                    onClick={() => {
                      document.cookie =
                        "CookieConsent=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

                      const $cookieDialog = document.getElementById(
                        "CybotCookiebotDialog",
                      );

                      if ($cookieDialog) {
                        $cookieDialog.style.display = "block";
                        $cookieDialog.style.opacity = "100";

                        return;
                      }

                      dispatch(
                        setSettings({
                          ...settings,
                          cookieBotKey: new Date().getTime(),
                        }),
                      );
                    }}
                  >
                    {labels.cookieManagement}
                  </Button>
                </li>
                <li className="mt-2.5 text-left">
                  <NavLink
                    to="https://www.cnb.avocat.fr/fr/e-actes-politique-de-confidentialite"
                    target="_blank"
                    rel="noreferrer"
                    data-testid="privacy-policy-link"
                  >
                    {labels.privacyPolicy}
                  </NavLink>
                </li>
                <li className="mt-2.5 text-left">
                  <NavLink
                    to="https://www.cnb.avocat.fr/fr/e-actes-mentions-legales"
                    target="_blank"
                    rel="noreferrer"
                    data-testid="legal-notice-link"
                  >
                    {labels.legalNotice}
                  </NavLink>
                </li>
                <li className="mt-2.5 text-left">
                  <NavLink
                    to="https://www.cnb.avocat.fr/fr/e-actes-politique-de-cookies"
                    target="_blank"
                    rel="noreferrer"
                    data-testid="cookie-policies-link"
                  >
                    {labels.cookiePolicies}
                  </NavLink>
                </li>
                <li className="mt-2.5 text-xs text-left">
                  {"" !== version ? (
                    version
                  ) : (
                    <Loader error={errors.version ? labels.version : ""} />
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
