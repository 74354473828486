import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import Button from "@ui/Button";
import Icon from "@ui/Icon";
import Modal from "@ui/Modal";
import Spinner from "@ui/Spinner";
import Tabs from "@ui/Tabs";

import { axiosErrorMessages, labels, toastOptionsError } from "@constants";

import { selectUser } from "@reducers/metadataSlice";

import { SharingEntryProps, SharingTabProps } from "@types";

const ShareDocuments = () => {
  const { "*": rest } = useParams();
  const user = useSelector(selectUser);
  const publicId = rest ? rest.split("/")[0] : "";
  const [activeTab, setActiveTab] = useState<number>(0);
  const [id, setId] = useState<number | null>(null);
  const [parties, setParties] = useState([]);
  const [lawyers, setLawyers] = useState([]);
  const [loadingParties, setLoadingParties] = useState<boolean>(true);
  const [loadingLawyers, setLoadingLawyers] = useState<boolean>(true);

  useEffect(() => {
    axios
      .get(`/api/v1/signbooks/${publicId}`)
      .then((response) => {
        setId(response.data.id);
      })
      .catch((error) => {
        const errorMessage = axiosErrorMessages[error.message] || error.message;
        toast.error(errorMessage, toastOptionsError);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!id) {
      return;
    }

    // Fetch parties and lawyers
    axios
      .get(`/api/v1/signbooks/${publicId}/signatories`)
      .then((response) => {
        setParties(response.data || []);
        setLoadingParties(false);
      })
      .catch((error) => {
        const errorMessage = axiosErrorMessages[error.message] || error.message;
        toast.error(errorMessage, toastOptionsError);
        setLoadingParties(false);
      });

    axios
      .get(`/api/v1/signbooks/${publicId}/lawyers`)
      .then((response) => {
        const responseLawyers = response.data || [];

        setLawyers(
          responseLawyers.filter(
            (lawyer: Record<string, any>) => lawyer.codeCNBF !== user.numCNBF,
          ),
        );
        setLoadingLawyers(false);
      })
      .catch((error) => {
        const errorMessage = axiosErrorMessages[error.message] || error.message;
        toast.error(errorMessage, toastOptionsError);
        setLoadingLawyers(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const tabList = [{ label: labels.parties }];

  if (lawyers.length) {
    tabList.push({ label: labels.lawyers });
  }

  const SharingEntry = ({ entry, name }: SharingEntryProps) => {
    const [loadingShare, setLoadingShare] = useState<boolean>(false);
    const [shared, setShared] = useState<boolean>(false);

    const shareButtonClass = loadingShare ? "opacity-0" : "";

    const entryCode = "parties" === name ? entry.code : entry.codeCNBF;
    const endpointType = "parties" === name ? "signatories" : "lawyers";
    const endpointUrl = `/api/v1/signbooks/${publicId}/${endpointType}/${entryCode}/sharing`;

    let avatarIcon = "profile-male";

    if ("parties" === name) {
      avatarIcon =
        "TYPE_NATURAL" === entry.personType ? "profile-male" : "apartment";
    }

    const shareDocument = () => {
      setLoadingShare(true);

      axios
        .post(endpointUrl, {})
        .then(() => {
          setShared(true);
          setLoadingShare(false);
        })
        .catch((error) => {
          const errorMessage =
            axiosErrorMessages[error.message] || error.message;

          toast.error(errorMessage, toastOptionsError);

          setLoadingShare(false);
        });
    };

    return (
      <div className="mt-[20px] flex justify-between items-center body-sm">
        <div className="flex justify-start items-center w-[120px]">
          <div>
            <Icon type={avatarIcon} className="size-10" />
          </div>
          <span className="ml-[11px]">
            {entry.firstName} {entry.lastName}
          </span>
        </div>

        <div className="relative w-[172px] mx-[9px]">
          <span className="py-[6px] px-[20px] w-full">{entry.email}</span>
        </div>
        <div className="w-[100px] h-[33px] flex items-center relative">
          {!shared ? (
            <div className="relative">
              <Button
                className={`${shareButtonClass} btn-base btn-secondary flex items-center justify-center`}
                type="button"
                disabled={loadingShare}
                onClick={() => {
                  shareDocument();
                }}
              >
                {labels.shareShort}
              </Button>

              {loadingShare && (
                <div className="absolute top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4">
                  <Spinner className="size-4" dark={true} />
                </div>
              )}
            </div>
          ) : (
            <div className="flex items-center">
              <Icon type="check" className="size-4 text-green-500 mr-[5px]" />
              {labels.sharing}
            </div>
          )}
        </div>
      </div>
    );
  };

  const SharingTab = ({ entries, name }: SharingTabProps) => {
    const isLoading = "parties" === name ? loadingParties : loadingLawyers;

    return (
      <div className="h-[220px] pr-[20px] overflow-y-auto">
        {isLoading && (
          <div className="flex justify-center items-center h-full">
            <Spinner className="size-10" dark={true} />
          </div>
        )}

        {!isLoading &&
          entries.map((entry: Record<string, string>, index: number) => {
            // We create separate component with its own state for each entry
            // to prevent rerenders of the tab which reset its scrollbar.
            return <SharingEntry key={index} entry={entry} name={name} />;
          })}
      </div>
    );
  };

  return (
    <Modal title={labels.shareDocuments} width={538}>
      <div className="text-left">
        <p className="body-sm mt-[10px]">{labels.malingListValidation}</p>

        <div className="mt-[20px] mb-[42px]">
          {!loadingParties && (
            <Tabs
              tabIndex={activeTab}
              tabList={tabList}
              setActiveTab={setActiveTab}
            />
          )}

          {0 === activeTab && <SharingTab entries={parties} name="parties" />}
          {1 === activeTab && lawyers.length && (
            <SharingTab entries={lawyers} name="lawyers" />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ShareDocuments;
