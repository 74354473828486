import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";

import Button from "@ui/Button";
import Modal from "@ui/Modal";

import { labels } from "@constants";

import { hideModal } from "@reducers/modalsSlice";

const DivorceFinalization = () => {
  const dispatch = useDispatch();

  return (
    <Modal title={labels.finalizingProcedure} width={640}>
      <Formik
        initialValues={{}}
        onSubmit={() => {
          dispatch(hideModal());
        }}
      >
        <Form>
          <div className="modal-content">
            <p>
              Votre acte a été signé par toutes les parties et les différents
              avocats. Pour finaliser la procédure de divorce, vous devez suivre
              les étapes suivantes :
            </p>

            <ol>
              <li>
                Télécharger les documents signés dans une archive compressée en
                cliquant sur le bouton Récupération de l’acte de divorce signé
                sur la page suivante
              </li>

              <li>
                <strong>Transmettre au Notaire</strong> : Vous devez transmettre
                le fichier compressé contenant l’acte et ses annexes signés au
                notaire par lettre recommandée électronique{" "}
                <strong>dans un délai de 7 jours maximum</strong>.
                <ul>
                  <li>
                    Une lettre recommandée électronique est un envoi recommandé
                    électronique qualifié au sens de l’article L. 100 du CPCE
                    (art. R. 53 du CPCE dans sa rédaction issue du{" "}
                    <a href="/" onClick={(event) => event.preventDefault()}>
                      décret n° 2018-347 du 9 mai 2018
                    </a>
                    )
                  </li>

                  <li>
                    L’article L. 100 du CPCE pose le principe d’équivalence
                    entre l’envoi recommandé électronique et l’envoi par lettre
                    recommandée à la condition que l’envoi électronique respecte
                    les exigences posées par l’article 44 du règlement « eIDAS »
                    (
                    <a href="/" onClick={(event) => event.preventDefault()}>
                      règlement UE n° 910/2014
                    </a>
                    )
                  </li>

                  <li>
                    Consultez la liste des produits et services de LRE qualifiés
                    par l’ANSSI :{" "}
                    <a
                      href="https://www.ssi.gouv.fr/liste-produits-et-services-qualifies"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.ssi.gouv.fr/liste-produits-et-services-qualifies
                    </a>
                  </li>
                </ul>
              </li>
            </ol>
          </div>
          <div className="flex justify-end items-center">
            <Button className="btn-base btn-primary" type="submit">
              {labels.continue}
            </Button>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default DivorceFinalization;
