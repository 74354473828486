import { useContext } from "react";
import { useDispatch } from "react-redux";

import Button from "@ui/Button";
import Icon from "@ui/Icon";
import Spinner from "@ui/Spinner";
import Table from "@ui/Table";

import { labels } from "@constants";

import useIsMobile from "@hooks/useIsMobile";
import useWindowSize from "@hooks/useWindowSize";

import { RequiredActionsContext } from "@pages/RequiredAction";

import { setModalData } from "@reducers/dataTransferSlice";
import { showModal } from "@reducers/modalsSlice";

import { PartsToBeSealedProps } from "@types";

import { formatTimestamp } from "@utils";

const PartsToBeSealedTable = () => {
  const dispatch = useDispatch();
  const data = useContext(RequiredActionsContext);
  const isMobile = useIsMobile();
  const paddingMobile = !isMobile ? "pr-[40px]" : "";
  const iconToDisplay = (type: string) => {
    switch (type) {
      case "TYPE_NATURAL":
        return (
          <Icon
            type="profile-male"
            className=" size-[30px] rounded-full mr-[16px]"
            color="black"
          />
        );
      case "COMPANY":
        return (
          <Icon
            type="apartment"
            className=" size-[30px] rounded-full mr-[16px]"
            color="black"
          />
        );
      default:
        return (
          <Icon
            type="profile-male"
            className=" size-[30px] rounded-full mr-[16px]"
            color="black"
          />
        );
    }
  };
  const tableDataFormatted = data.tableData.sealing.map((item: any) => {
    const [firstName, lastName] = item.sbMemberName.split(" ");
    return {
      id: item.sbMemberPublicId,
      imageUrl: item.asMemberType,
      lastName: lastName,
      firstName: firstName,
      document: item.name,
      deadline: formatTimestamp(item.expirationDate, false),
      type: item.sbMemberType,
      actId: item.publicId,
    };
  });

  const columns = [
    {
      label: labels.party,
      render: (data: PartsToBeSealedProps) => (
        <div className="flex justify-start items-center">
          {iconToDisplay(data.imageUrl)}
          {data.lastName} {data.firstName}
        </div>
      ),
      className: "body-lg py-[15px] h-[90px]",
    },
    {
      label: labels.document,
      render: (data: PartsToBeSealedProps) => data.document,
    },
    {
      label: labels.deadline,
      render: (data: PartsToBeSealedProps) => data.deadline,
    },
    {
      label: "",
      render: (data: PartsToBeSealedProps) => (
        <Button
          onClick={() => {
            dispatch(setModalData(data));
            dispatch(showModal("sealParty"));
          }}
          className="flex items-center justify-center px-[15px] py-[10px] body-md rounded-lg max-w-[133px] w-[100%] btn-secondary-emphasize"
        >
          {labels.verifyAndSeal}
        </Button>
      ),
      className: `body-lg py-[15px] flex justify-end h-[90px] items-center ${paddingMobile}`,
    },
  ];
  if (useWindowSize() <= 740) {
    columns.splice(1, columns.length - 2);
  }

  return data.loading ? (
    <div className="w-full h-full pt-[40px] flex justify-center items-center">
      <Spinner className="size-10" dark={true} />
    </div>
  ) : (
    <Table columns={columns} rows={tableDataFormatted} />
  );
};

export default PartsToBeSealedTable;
