import keycloak from "./Keycloak";
import "./index.css";
import store from "./store";
import { ReactKeycloakProvider } from "@react-keycloak/web";

import axios from "axios";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import App from "@components/App";
import OTP from "@components/OTP";

import DocumentValidation from "@pages/document-validation";
import SignatoryConfirmation from "@pages/signatory-confirmation";
import DocumentPreviewSignatoryConfirmation from "@pages/signatory-confirmation/DocumentPreviewSignatoryConfirmation";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

axios.defaults.baseURL = process.env.REACT_APP_BE_BASEURL;

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route
          path="/*"
          element={
            <ReactKeycloakProvider
              authClient={keycloak}
              initOptions={{
                onLoad:
                  process.env.REACT_APP_SKIP_LOGIN === "true"
                    ? "check-sso"
                    : "login-required",
                checkLoginIframe: false,
              }}
            >
              <App />
            </ReactKeycloakProvider>
          }
        />
        {
          // We need to list all the act tabbed routes paths here to avoid
          // overlapping with the wildcard routes below
          [
            "/signing/birth/*",
            "/signing/convention/*",
            "/signing/digital/*",
          ].map((path, index) => (
            <Route key={index} path={path} element={<OTP />} />
          ))
        }
        {/* <Route path="/signing/birth" element={<OTP />} /> */}
        <Route
          path="/signatory-confirmation"
          element={<SignatoryConfirmation />}
        />
        <Route
          path="/signatory-confirmation/document-preview"
          element={<DocumentPreviewSignatoryConfirmation />}
        />
        <Route path="/document-validation" element={<DocumentValidation />} />
      </Routes>
    </BrowserRouter>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
