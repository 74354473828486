import Welcome from "./step-1/Welcome";
import YourProfile from "./step-2/YourProfile";
import PreferredActs from "./step-3/PreferredActs";

import { Navigate, Route, Routes } from "react-router-dom";

import Layout from "@components/layout/Layout";

const Onboarding = () => {
  return (
    <Layout
      sidebar={false}
      backgroundColor="bg-[#F9F9F9]"
      quitButton={false}
      onboarding={true}
      logoLink={false}
    >
      <div className="flex">
        <div className="mx-auto">
          <div className="bg-white lg:w-[579px] mx-6 lg:mx-auto rounded-t-lg pb-2 mb-16">
            <Routes>
              {/* Step 1 */}
              <Route path="/welcome" element={<Welcome />} />
              {/* Step 2 */}
              <Route path="/your-profile" element={<YourProfile />} />
              {/* Step 3 */}
              <Route path="/preferred-acts" element={<PreferredActs />} />
              {/* Default Route */}
              <Route
                path="*"
                element={<Navigate to="/onboarding/welcome" replace />}
              />
            </Routes>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Onboarding;
