import axios from "axios";
import { toast } from "react-toastify";

import { axiosErrorMessages, toastOptionsError } from "@constants";

const partyInformationApi = (partyId: string) => {
  return axios
    .get(`/api/v1/contacts/${partyId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      toast.error(axiosErrorMessages[error.message], toastOptionsError);
    });
};

const partyIdentificationDocumentAPi = (
  publicId: string,
  signatoryCode: string,
) => {
  return axios
    .get(
      `/api/v1/signbooks/${publicId}/signatories/${signatoryCode}/identification-documents`,
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      toast.error(axiosErrorMessages[error.message], toastOptionsError);
    });
};

const deletePartyIdentificationDocumentAPi = (
  publicId: string,
  signatoryCode: string,
  documentId: string,
) => {
  return axios
    .delete(
      `/api/v1/signbooks/${publicId}/signatories/${signatoryCode}/identification-documents/${documentId}`,
    )
    .catch((error) => {
      toast.error(axiosErrorMessages[error.message], toastOptionsError);
    });
};

const downloadPartyIdentificationDocumentAPi = (
  publicId: string,
  documentId: string,
) => {
  return axios
    .get(`/api/v1/signbooks/${publicId}/documents/${documentId}/data`, {
      responseType: "arraybuffer",
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      toast.error(axiosErrorMessages[error.message], toastOptionsError);
    });
};

const partySignBooksInformationApi = (publicId: string) => {
  return axios
    .get(`/api/v1/signbooks/${publicId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      toast.error(axiosErrorMessages[error.message], toastOptionsError);
    });
};

export {
  partyInformationApi,
  partyIdentificationDocumentAPi,
  deletePartyIdentificationDocumentAPi,
  downloadPartyIdentificationDocumentAPi,
  partySignBooksInformationApi,
};
