import CookieBot from "react-cookiebot";
import { useSelector } from "react-redux";

import { selectSettings } from "@reducers/metadataSlice";

const CookieBotComponent = () => {
  const settingsData = useSelector(selectSettings);
  const domainGroupId = settingsData.cookieBotId || "";

  const getCookie = (name: string) => {
    let cookieArr = document.cookie.split(";");

    for (let i = 0; i < cookieArr.length; i++) {
      let cookie = cookieArr[i].trim();

      // Check if this cookie string matches the name
      if (cookie.startsWith(name + "=")) {
        return cookie.substring(name.length + 1);
      }
    }

    return null;
  };

  const isCookiePresent = getCookie("CookieConsent");

  // Do not render CookieBot if domainGroupId is empty or cookies are already accepted
  if (!domainGroupId || isCookiePresent) {
    return null;
  }

  return <CookieBot domainGroupId={domainGroupId} />;
};

export default CookieBotComponent;
