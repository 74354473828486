import { useKeycloak } from "@react-keycloak/web";

import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { useEffect, useState } from "react";

const useApiHook = <T>(
  baseUrl: string,
  url: string,
  params?: AxiosRequestConfig,
) => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { keycloak } = useKeycloak();

  const axiosInstance = axios.create({
    headers: {
      "Access-Control-Allow-Origin": `${process.env.REACT_APP_BE_BASEURL}`,
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    },
  });
  axiosInstance.defaults.maxRedirects = 0;

  useEffect(() => {
    if (keycloak.token !== undefined) {
      axiosInstance.interceptors.request.use(
        (config) => {
          config.headers.Authorization = `Bearer ${keycloak.token}`;
          return config;
        },
        (error) => {
          return Promise.reject(error);
        },
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keycloak.token]);

  useEffect(() => {
    if (keycloak.token !== undefined) {
      const fetchData = async () => {
        try {
          setLoading(true);
          const response: AxiosResponse<any> = await axiosInstance.get(
            baseUrl + url,
            {
              params: params,
              withCredentials: true,
            },
          );
          setData(response.data);
          setLoading(false);
        } catch (error) {
          setError("Error getting the data");
          setLoading(false);
        }
      };

      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keycloak.token]);

  return { data, loading, error };
};

export default useApiHook;
