import { useContext, useEffect, useState } from "react";

import Button from "@ui/Button";
import Icon from "@ui/Icon";

import { ParamsContext } from "@pages/Documents";

import { PaginationProps } from "@types";

const Pagination = ({
  totalPages,
  page = 1,
  setPage,
  positionClass,
}: PaginationProps) => {
  const params = useContext(ParamsContext);
  const [currentPage, setCurrentPage] = useState(
    params.requestParams?.page || page,
  );

  useEffect(() => {
    if (!params.requestParams?.page) {
      return;
    }

    if (params.requestParams?.page === currentPage) {
      return;
    }

    setCurrentPage(params.requestParams?.page);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.requestParams?.page]);

  useEffect(() => {
    if (
      params.requestParams?.page &&
      params.requestParams?.page === currentPage
    ) {
      return;
    }

    if (setPage) {
      setPage(currentPage);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const firstPage = () => {
    setCurrentPage(1);
  };

  const lastPage = () => {
    setCurrentPage(totalPages);
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage: number) => prevPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage: number) => prevPage - 1);
    }
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 3; // Number of pages to show around the current page

    let startPage = Math.max(1, currentPage - Math.floor(totalPagesToShow / 2));
    let endPage = startPage + totalPagesToShow - 1;

    // Ensure the end page does not exceed the total number of pages
    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, endPage - totalPagesToShow + 1);
    }
    startPage = Math.max(1, startPage);
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  };

  const goToPage = (pageNumber: number) => {
    setCurrentPage((prevPage: number) => {
      // Ensure the current page is updated first before setting the new page
      if (prevPage !== pageNumber) {
        // Only set the new page if it's different from the current page
        return pageNumber;
      } else {
        // If it's the same page, return the current page to avoid unnecessary state updates
        return prevPage;
      }
    });
  };

  const pageNumbers = () => {
    const pages = generatePageNumbers();
    const leftArrowBackgroundStyle = currentPage === 1 ? "bg-[#CFCFCF]" : "";
    const leftArrowStyle = currentPage === 1 ? "#ABABAB" : "#000";
    const buttonStyle = (pageNumber: number) =>
      currentPage === pageNumber
        ? "bg-ea-darkblue text-white rounded-[4px]"
        : "";
    const rightArrowBackgroundStyle =
      currentPage === totalPages ? "bg-[#CFCFCF]" : "";
    const rightArrowStyle = currentPage === totalPages ? "#ABABAB" : "#000";
    const positionStyle = positionClass ? positionClass : "right-[40px]";

    return (
      <div className="relative z-[41]">
        <div className={`flex items-center absolute ${positionStyle} z-10`}>
          {
            <Button
              className={`${leftArrowBackgroundStyle} px-[7px] py-[5px] rounded-[4px] mr-[2px] previous-page`}
              onClick={prevPage}
              disabled={currentPage === 1}
              type="button"
            >
              <Icon
                type="arrow-left-pagination"
                className="w-[8px] h-[12px]"
                color={leftArrowStyle}
              />
            </Button>
          }
          {currentPage >= 3 && totalPages !== 3 && (
            <Button
              className="px-[7px] mx-[2px] body-lg font-medium flex items-center justify-center"
              onClick={firstPage}
              disabled={currentPage === 1}
            >
              1
            </Button>
          )}
          {currentPage >= 4 && <span className="px-[7px] mx-[2px]">...</span>}
          {pages.map((pageNumber) => (
            <Button
              className={`px-[7px] mx-[2px] body-lg font-medium flex items-center justify-center page ${buttonStyle(
                pageNumber,
              )}`}
              key={pageNumber}
              onClick={() => goToPage(pageNumber)}
              disabled={currentPage === pageNumber}
            >
              {pageNumber}
            </Button>
          ))}
          {currentPage <= totalPages - 3 && (
            <span className="px-[7px] mx-[2px]">...</span>
          )}
          {currentPage <= totalPages - 2 && totalPages !== 3 && (
            <Button
              onClick={lastPage}
              className="px-[7px] mx-[2px] body-lg font-medium flex items-center justify-center"
            >
              {totalPages}
            </Button>
          )}
          {
            <Button
              className={`${rightArrowBackgroundStyle} px-[7px] py-[5px] rounded-[4px] ml-[2px] next-page`}
              onClick={nextPage}
              disabled={currentPage === totalPages}
              type="button"
            >
              <Icon
                type="arrow-right-pagination"
                className="w-[8px] h-[12px]"
                color={`${rightArrowStyle}`}
              />
            </Button>
          }
        </div>
      </div>
    );
  };

  return totalPages > 1 ? pageNumbers() : null;
};

export default Pagination;
