import Icon from "@ui/Icon";

const CloseIcon = () => {
  return (
    <Icon
      type="close-circle"
      className="text-white size-[28px]"
      color="#E40000"
    />
  );
};

export default CloseIcon;
