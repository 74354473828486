import axios from "axios";
import { Field, Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import Button from "@ui/Button";
import Icon from "@ui/Icon";
import Loader from "@ui/Loader";
import Modal from "@ui/Modal";
import Spinner from "@ui/Spinner";

import { axiosErrorMessages, labels, toastOptionsError } from "@constants";

import {
  selectModalData,
  setModalData,
  setPageData,
  setPostState,
} from "@reducers/dataTransferSlice";
import { selectUser } from "@reducers/metadataSlice";
import { hideModal } from "@reducers/modalsSlice";
import { selectPage, setPage } from "@reducers/tracesSlice";

import { Lawyer } from "@types";

import { formatLabel } from "@utils";

const ChangeLawyer = () => {
  const [dataForLawyers, setDataForLawyers] = useState<Lawyer[]>([]);
  const [updatedLawyer, setUpdatedLawyer] = useState<any>([]);
  const [advancedSearchVisible, setAdvancedSearchVisible] = useState(false);
  const [advancedSearchToggle, setAdvancedSearchToggle] = useState(false);
  const [advancedSearchResultsVisible, setAdvancedSearchResultsVisible] =
    useState(false);
  const [advancedSearchResults, setAdvancedSearchResults] = useState<Lawyer[]>(
    [],
  );
  const [error, setError] = useState("");
  const [regularSearchResults, setRegularSearchResults] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSealing, setIsSealing] = useState(false);
  const searchInputRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const message = useSelector(selectModalData).party.advisedBy[0];
  const data = useSelector(selectModalData);
  const name = message?.name ?? "";
  const { signBookNumber } = data;
  const signatoryCode = data.party.signatoryId;
  const page = useSelector(selectPage);
  const [seacrhCriterias, setSearchCriterias] = useState<any>();
  const [maxPageReached, setMaxPageReached] = useState(false);
  const [advisedBySearchValue, setAdvisedBySearchValue] = useState("");
  const regularListRef = useRef<HTMLUListElement>(null);
  const advancedListRef = useRef<HTMLUListElement>(null);
  const regularSearchButton = useRef<HTMLButtonElement>(null);
  const user = useSelector(selectUser);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (data.party.linkedTo === user.numCNBF) {
      setIsSealing(true);
    }
    if (data.seacrhCriterias) {
      setSearchCriterias(data.seacrhCriterias);
      dispatch(setModalData({ ...data, seacrhCriterias: null }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        searchInputRef.current &&
        !searchInputRef.current.contains(event.target) &&
        regularSearchResults
      ) {
        setRegularSearchResults(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchInputRef, regularSearchResults]);

  // Detect if the user has scrolled to the bottom of the list of lawyers
  useEffect(() => {
    const element = advancedSearchResultsVisible
      ? advancedListRef
      : regularListRef;

    const { current } = element;

    const handleScroll = () => {
      if (current) {
        const atBottom =
          current.scrollHeight - Math.ceil(current.scrollTop) ===
          current.clientHeight;

        if (atBottom && !maxPageReached) {
          dispatch(setPage(page + 1));
          setLoading(true);
        }

        if (page === 0 && dataForLawyers.length > 0) {
          setLoading(false);
        }
      }
    };

    if (element.current) {
      element.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (current) {
        current.removeEventListener("scroll", handleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    page,
    dataForLawyers.length,
    advancedSearchResults.length,
    advancedSearchResultsVisible,
  ]);

  useEffect(() => {
    if (seacrhCriterias) {
      handleAdvancedSearchLawyer(seacrhCriterias);
    } else {
      searchLawyer(advisedBySearchValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (page > 0) {
      dispatch(setPage(0));
      dispatch(setModalData({ ...data, seacrhCriterias: null }));
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advisedBySearchValue]);

  const resetSearch = () => {
    dispatch(setPage(0));
    setError("");
    setDataForLawyers([]);
    setAdvancedSearchResults([]);
    setAdvisedBySearchValue("");
    setMaxPageReached(false);
    setSearchCriterias(null);
    setLoading(false);
    setSearchLoading(false);
    setRegularSearchResults(false);
    setAdvancedSearchResultsVisible(false);
  };

  // Handles onChange of the Formik Field - when the user types in the input field
  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    formik: any,
    fieldName: string,
  ) => {
    const { value } = e.target;

    formik.setFieldValue(fieldName, value);
  };

  // Function to set the formik values when a lawyer is selected from the dropdown
  const handleFieldValueChange = (formik: any, result: Lawyer) => {
    formik.setFieldValue("search", `${result.firstName} ${result.lastName}`);
    formik.setFieldValue("newLawyerId", result.codeCNBF);
    formik.setFieldValue("firstName", result.firstName);
    formik.setFieldValue("lastName", result.lastName);
    setUpdatedLawyer({
      codeCNBF: result.codeCNBF,
      firstName: result.firstName,
      lastName: result.lastName,
      email: result.email,
      mobileNumber: result.mobileNumber,
    });
  };

  const handleAdvancedSearchLawyer = (values: any) => {
    if (maxPageReached) {
      setLoading(false);
      return;
    }
    setLoading(true);
    setSearchLoading(true);
    const { firstName, lastName, codeCNBF, email } = values;
    if (
      firstName.length === 0 &&
      lastName.length === 0 &&
      codeCNBF.length === 0 &&
      email.length === 0
    ) {
      setAdvancedSearchResults([]);
      return;
    }

    const terms = seacrhCriterias
      ? {
          ...seacrhCriterias,
          page: page,
          size: 5,
        }
      : {
          firstName,
          lastName,
          codeCNBF,
          email,
          page: page,
          size: 5,
        };

    axios
      .post(`/api/v1/lawyers/search`, terms)
      .then((response) => {
        if (response.data.page > 0 && response.data.total === 0) {
          setMaxPageReached(true);
          setSearchLoading(false);
          setLoading(false);
          return;
        }

        setAdvancedSearchResults([
          ...advancedSearchResults,
          ...response.data.lawyers,
        ]);

        setLoading(false);
        setSearchLoading(false);
        setAdvancedSearchResultsVisible(true);
      })
      .catch((error) => {
        const errorMessage = axiosErrorMessages[error.message] || error.message;
        toast.error(errorMessage, toastOptionsError);
      });
  };

  const initialValues = {
    newLawyerId: "",
    search: "",
    firstName: "",
    lastName: "",
    codeCNBF: "",
    email: "",
  };

  const searchLawyer = (searchValue: string) => {
    if (maxPageReached) {
      setLoading(false);
      return;
    }

    if (searchValue.trim() === "") {
      return;
    }
    const searchTerm = searchValue.toLowerCase();

    if (searchTerm.length >= 3) {
      setRegularSearchResults(true);

      setSearchLoading(true);

      axios
        .post(`/api/v1/lawyers/search`, {
          term: searchTerm,
          page: page,
          size: 5,
        })
        .then((response) => {
          if (response.data.page > 0 && response.data.total === 0) {
            setMaxPageReached(true);
            setSearchLoading(false);
            setLoading(false);
            return;
          }

          if (response.data.lawyers.length === 0) {
            setSearchLoading(false);
            setLoading(false);
            return;
          }
          setDataForLawyers([...dataForLawyers, ...response.data.lawyers]);

          setSearchLoading(false);
        })
        .catch((error) => {
          const errorMessage =
            axiosErrorMessages[error.message] || error.message;
          setSearchLoading(false);
          toast.error(errorMessage, toastOptionsError);
        });
    } else {
      setError(formatLabel(labels.minCharLabel, "3"));
    }
  };

  const resetForm = (formik: any) => {
    formik.resetForm();
    formik.setFieldValue("newLawyerId", "");
    formik.setFieldValue("search", "");
    formik.setFieldValue("firstName", "");
    formik.setFieldValue("lastName", "");
    formik.setFieldValue("codeCNBF", "");
    formik.setFieldValue("email", "");
    setAdvancedSearchResults([]);
    setError("");
  };

  const handleCurrentLawyer = () => {
    axios
      .put(
        `/api/v1/signbooks/${signBookNumber}/signatories/${signatoryCode}/sealing?lawyerCode=${user.numCNBF}`,
      )
      .then(() => {
        dispatch(setPostState(true));
        dispatch(hideModal());
      })
      .catch((error) => {
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      });
  };

  return (
    <Modal title={labels.changeLawyer} width={520}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          if (isSealing && values.newLawyerId === "") {
            handleCurrentLawyer();
            return;
          }
          // If the search field is empty, close the modal and do not change the current lawyer
          if (values.search.trim().length === 0 || values.newLawyerId === "") {
            dispatch(setModalData(null));
            dispatch(hideModal());
            return;
          }
          setLoading(true);
          const updatedValue = { ...updatedLawyer, isSealing: isSealing };
          axios
            .post(
              `/api/v1/signbooks/${signBookNumber}/signatories/${signatoryCode}/lawyer`,
              updatedValue,
            )
            .then(() => {
              dispatch(setPostState(true));
              dispatch(hideModal());
              resetSearch();
              setModalData(null);
            })
            .catch((error) => {
              toast.error(axiosErrorMessages[error.message], toastOptionsError);
            });
        }}
      >
        {(formik) => (
          <Form>
            {/* Button that opens the advanced search */}

            <div className="flex items-center justify-between mt-4 mb-2">
              <p className="relative body-md pl-11 py-3 text-left">
                <Icon
                  type="advanced-search"
                  className="absolute top-[14px] w-[20px] h-[13px] left-[10px]"
                />
                {labels.advancedSearchTabLabel}
              </p>

              <div className="flex flex-row justify-end">
                <label className="inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    name="advancedSearch"
                    id="advancedSearch"
                    className="sr-only peer justify-start"
                    onChange={() => {
                      if (advancedSearchToggle && advancedSearchVisible) {
                        setAdvancedSearchVisible(false);
                        setAdvancedSearchToggle(false);
                        return;
                      }

                      if (advancedSearchToggle) {
                        setAdvancedSearchToggle(false);
                      } else {
                        setAdvancedSearchToggle(true);
                        setAdvancedSearchVisible(true);
                      }
                      resetForm(formik);
                      resetSearch();
                    }}
                  />
                  <div className="h-[20px] w-[36px] relative bg-gray-200 peer-focus:outline-none rounded-full peer dark:ea-gray-100 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-ea-darkblue"></div>
                  <span className="ms-3 text-sm body-md justify-end w-[60px]">
                    {advancedSearchToggle ? "Activé" : "Désactivé"}
                  </span>
                </label>
              </div>
            </div>
            {!advancedSearchVisible ? (
              // Regular search form
              <>
                <div className="relative" ref={searchInputRef}>
                  {!advancedSearchResultsVisible ? (
                    // Regular search results
                    <>
                      <div className="flex items-center justify-between">
                        <div className="relative">
                          <Icon
                            type="search"
                            className="absolute top-[9px] left-[10px] size-[15px]"
                          />
                          <Field
                            type="text"
                            name="search"
                            placeholder={labels.searchByNameEmailOrCnbf}
                            className="body-md border rounded-[4px] border-ea-gray-100 px-[40px] py-[8px] mr-[5px] w-[327px] text-xs"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              const inputEvent = e.nativeEvent as InputEvent;
                              if (
                                inputEvent.inputType ===
                                  "deleteContentBackward" &&
                                formik.values.newLawyerId
                              ) {
                                formik.resetForm();
                                formik.setFieldValue("newLawyerId", "");
                                return;
                              }
                              setError("");
                              return handleFieldChange(e, formik, "search");
                            }}
                            onFocus={() => {
                              setDataForLawyers([]);
                              setRegularSearchResults(false);
                              setMaxPageReached(false);
                            }}
                          />
                          {formik.values.search.length > 0 && (
                            <div
                              className="cursor-pointer absolute top-[13px] right-[15px]"
                              onClick={() => {
                                formik.resetForm();
                                formik.setFieldValue("newLawyerId", "");
                                resetSearch();
                                if (advancedSearchToggle) {
                                  setAdvancedSearchVisible(true);
                                }
                              }}
                            >
                              <Icon type="close" className=" size-[8px]" />
                            </div>
                          )}
                        </div>
                        <button
                          className="btn-secondary border items-center text-center px-[9px] py-[6px] rounded-lg h-[34px] w-[90px] body-lg"
                          type="button"
                          ref={regularSearchButton}
                          onClick={() => {
                            if (formik.values.search.trim().length === 0) {
                              setError(
                                `${labels.invalidLawyer} ${labels.pleaseSelectLawyer}`,
                              );
                              return;
                            }

                            if (
                              formik.values.search === advisedBySearchValue &&
                              formik.values.search !== ""
                            ) {
                              return;
                            }
                            setAdvisedBySearchValue(formik.values.search);
                            setDataForLawyers([]);

                            searchLawyer(formik.values.search);
                          }}
                        >
                          {labels.search}
                        </button>
                      </div>
                      {regularSearchResults && (
                        <div className="border-ea-gray-200 border border-t-0 rounded-br-[4px] rounded-bl-[4px] max-w-[327px] absolute bg-white w-full z-20">
                          <ul
                            className="max-h-[100px] w-full overflow-auto"
                            ref={regularListRef}
                          >
                            {dataForLawyers.length === 0 && !searchLoading && (
                              <li className="flex items-center justify-between py-2 px-[30px]">
                                <p className="body-sm w-[45%] overflow-hidden text-ellipsis text-left">
                                  {labels.noResultsFound}
                                </p>
                              </li>
                            )}

                            {dataForLawyers.map((lawyer, index) => (
                              <li
                                key={index}
                                className="flex items-center justify-between py-2 px-[30px] cursor-pointer"
                                onClick={() => {
                                  handleFieldValueChange(formik, lawyer);
                                  formik.setFieldValue(
                                    "newLawyerId",
                                    lawyer.codeCNBF,
                                  );
                                  setRegularSearchResults(false);
                                  setError("");
                                }}
                              >
                                <p className="body-sm w-full overflow-hidden text-ellipsis text-left">
                                  {lawyer.firstName} {lawyer.lastName}
                                </p>
                                <p className="body-sm w-[50%] overflow-hidden text-ellipsis text-right">
                                  {lawyer.codeBarreau}
                                </p>
                              </li>
                            ))}

                            {searchLoading && (
                              <Loader
                                style={{
                                  height: "30px",
                                  width: "317px",
                                }}
                              />
                            )}
                          </ul>
                        </div>
                      )}
                    </>
                  ) : (
                    // Advanced search results
                    <>
                      <div className="w-full text-left">
                        <Icon
                          type="advanced-search"
                          className="absolute top-[9px] left-[10px] size-[15px]"
                        />
                        <Field
                          type="text"
                          name="search"
                          disabled
                          value={`${formik.values.firstName} ${formik.values.lastName}`}
                          className="body-md border rounded-[4px] border-ea-gray-100 px-[40px] py-[8px] mr-[5px] w-full text-xs bg-white"
                        />
                        {formik.values.firstName.length > 0 && (
                          <div
                            className="cursor-pointer absolute top-[13px] right-[10px]"
                            onClick={() => {
                              resetForm(formik);
                              setAdvancedSearchVisible(true);
                              resetSearch();
                            }}
                          >
                            <Icon type="close" className=" size-[8px]" />
                          </div>
                        )}
                      </div>

                      {advancedSearchResultsVisible && (
                        <div className="border-ea-gray-200 border border-t-0 rounded-br-[4px] rounded-bl-[4px] absolute bg-white w-full z-20">
                          <ul
                            className="max-h-[100px] w-full overflow-auto"
                            ref={advancedListRef}
                          >
                            {advancedSearchResults.length === 0 &&
                              formik.values.newLawyerId === "" &&
                              !searchLoading && (
                                <p className="flex items-center justify-between py-2 px-[30px] body-sm w-[45%] overflow-hidden text-ellipsis text-left">
                                  {labels.noResultsFound}
                                </p>
                              )}
                            {advancedSearchResults.map((result, index) => (
                              <li
                                key={index}
                                className="flex items-center justify-between py-2 px-[30px] cursor-pointer"
                                onClick={() => {
                                  formik.resetForm();
                                  handleFieldValueChange(formik, result);
                                  formik.setFieldValue(
                                    "newLawyerId",
                                    result.id,
                                  );
                                  setAdvancedSearchResults([]);
                                  setAdvancedSearchResultsVisible(false);
                                  setError("");
                                }}
                              >
                                <p className="body-sm w-[45%] overflow-hidden text-ellipsis text-left">
                                  {result.firstName} {result.lastName}
                                </p>
                                <p className="body-sm w-[50%] overflow-hidden text-ellipsis text-right">
                                  {result.codeBarreau}
                                </p>
                              </li>
                            ))}
                            {searchLoading && (
                              <Loader
                                style={{
                                  height: "30px",
                                  width: "444x",
                                }}
                              />
                            )}
                          </ul>
                        </div>
                      )}
                    </>
                  )}
                </div>

                {error.length > 0 && (
                  <span className="text-[10px] max-w-[327px] text-ea-red">
                    {error}
                  </span>
                )}
              </>
            ) : (
              // Advanced search form
              <>
                <div className="mb-7 mt-3 relative">
                  <label htmlFor="firstName" className="body-md">
                    {labels.name}
                  </label>
                  <div className="relative mt-2">
                    <Icon
                      type="search"
                      className="absolute top-[9px] left-[10px] size-[15px]"
                    />
                    <Field
                      type="text"
                      name="firstName"
                      className="body-md border rounded-[4px] border-ea-gray-100 px-[40px] py-[8px] mr-[5px] w-full text-xs"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleFieldChange(e, formik, "firstName")
                      }
                    />
                    {formik.values.firstName.length > 0 && (
                      <div
                        className="cursor-pointer absolute top-[13px] right-[10px]"
                        onClick={() => {
                          formik.resetForm();
                          formik.setFieldValue("firstName", "");
                        }}
                      >
                        <Icon type="close" className=" size-[8px]" />
                      </div>
                    )}
                  </div>
                </div>

                <div className="mb-7 relative">
                  <label htmlFor="lastName" className="body-md">
                    {labels.surname}
                  </label>
                  <div className="relative mt-2">
                    <Icon
                      type="search"
                      className="absolute top-[9px] left-[10px] size-[15px]"
                    />
                    <Field
                      type="text"
                      name="lastName"
                      className="body-md border rounded-[4px] border-ea-gray-100 px-[40px] py-[8px] mr-[5px] w-full text-xs"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleFieldChange(e, formik, "lastName")
                      }
                    />
                    {formik.values.lastName.length > 0 && (
                      <div
                        className="cursor-pointer absolute top-[13px] right-[10px]"
                        onClick={() => {
                          formik.resetForm();
                          formik.setFieldValue("lastName", "");
                          setError("");
                        }}
                      >
                        <Icon type="close" className=" size-[8px]" />
                      </div>
                    )}
                  </div>
                </div>

                <div className="mb-7 relative">
                  <label htmlFor="codeCNBF" className="body-md">
                    {labels.codeCNBF}
                  </label>
                  <div className="relative mt-2">
                    <Icon
                      type="search"
                      className="absolute top-[9px] left-[10px] size-[15px]"
                    />
                    <Field
                      type="text"
                      name="codeCNBF"
                      className="body-md border rounded-[4px] border-ea-gray-100 px-[40px] py-[8px] mr-[5px] w-full text-xs"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleFieldChange(e, formik, "codeCNBF")
                      }
                    />
                    {formik.values.codeCNBF.length > 0 && (
                      <div
                        className="cursor-pointer absolute top-[13px] right-[10px]"
                        onClick={() => {
                          formik.resetForm();
                          formik.setFieldValue("codeCNBF", "");
                          setError("");
                        }}
                      >
                        <Icon type="close" className=" size-[8px]" />
                      </div>
                    )}
                  </div>
                </div>

                <div className="mb-7 relative">
                  <label htmlFor="email" className="body-md">
                    {labels.email}
                  </label>
                  <div className="relative mt-2">
                    <Icon
                      type="search"
                      className="absolute top-[9px] left-[10px] size-[15px]"
                    />
                    <Field
                      type="email"
                      name="email"
                      className="body-md border rounded-[4px] border-ea-gray-100 px-[40px] py-[8px] mr-[5px] w-full text-xs"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleFieldChange(e, formik, "email")
                      }
                    />
                    {formik.values.email.length > 0 && (
                      <div
                        className="cursor-pointer absolute top-[13px] right-[10px]"
                        onClick={() => {
                          formik.resetForm();
                          formik.setFieldValue("email", "");
                          setError("");
                        }}
                      >
                        <Icon type="close" className=" size-[8px]" />
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}

            <div className="flex relative body-md text-center items-center custom-checkbox-container mt-2">
              <label htmlFor="isSealing">
                <input
                  name="isSealing"
                  id="isSealing"
                  type="checkbox"
                  checked={isSealing}
                  onChange={() => setIsSealing(!isSealing)}
                  className="custom-checkbox"
                />
                {labels.sealThisParty}
              </label>
            </div>
            <div className="mt-[10px] mb-[25px]">
              {name.trim() !== "" && (
                <p className="body-sm body-md">
                  {/* If we need it bold in future */}
                  {/* <strong>{name}</strong> {labels.adviceParty} */}
                  {formatLabel(labels.adviceParty, name)}
                </p>
              )}
            </div>

            {!advancedSearchVisible ? (
              // Regular search controls
              <div className="flex items-center justify-between">
                <Button
                  type="button"
                  className="btn-secondary body-md rounded-[8px] py-[5px] px-[20px]"
                  onClick={() => {
                    formik.resetForm();
                    dispatch(setPageData(null));
                    dispatch(hideModal());
                    resetSearch();
                  }}
                >
                  {labels.cancel}
                </Button>
                <Button
                  type="submit"
                  className="btn-secondary-emphasize body-md rounded-[8px] py-[5px] px-[20px] flex items-center justify-center"
                  disabled={loading}
                >
                  {loading && <Spinner className="size-4 mr-2" />}
                  {labels.save}
                </Button>
              </div>
            ) : (
              // Advanced search controls
              <div className="flex items-center justify-between">
                <Button
                  type="button"
                  className="btn-secondary body-md rounded-[8px] py-[5px] px-[20px]"
                  onClick={() => {
                    formik.resetForm();
                    dispatch(setPageData(null));
                    dispatch(hideModal());
                    resetSearch();
                  }}
                >
                  {labels.cancel}
                </Button>
                <Button
                  type="button"
                  className="btn-secondary-emphasize body-md rounded-[8px] py-[5px] px-[20px]"
                  onClick={(e) => {
                    e.preventDefault();
                    handleAdvancedSearchLawyer(formik.values);
                    setAdvancedSearchVisible(false);
                    setAdvancedSearchResultsVisible(true);
                    const { newLawyerId, search, ...rest } = formik.values;
                    dispatch(setModalData({ ...data, seacrhCriterias: rest }));
                  }}
                >
                  {labels.search}
                </Button>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ChangeLawyer;
