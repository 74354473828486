import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import Button from "@ui/Button";
import Modal from "@ui/Modal";
import Spinner from "@ui/Spinner";

import { axiosErrorMessages, labels, toastOptionsError } from "@constants";

import {
  selectModalData,
  setPageData,
  setPostState,
} from "@reducers/dataTransferSlice";
import { hideModal } from "@reducers/modalsSlice";

import { deleteIdentificationDocument } from "@utils/api/documentApi";

const DeleteParty = () => {
  const params = useParams();
  const { actType } = params;
  // The following code is used to determine the publicId of the act, depending on how is the route constructed
  const publicId = params.publicId
    ? params.publicId
    : params["*"]?.split("/")[0];
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const data = useSelector(selectModalData);
  const partyId = data.partyId;
  const actCreatorId = data.actCreator;
  const lawyerId = data.lawyerId;

  const handleDeleteParty = async () => {
    if (!publicId || !partyId) {
      return;
    }
    setLoading(true);
    const response = await deleteIdentificationDocument(publicId, partyId);
    if (response?.status === 204) {
      axios
        .delete(`/api/v1/signbooks/${publicId}/signatories/${partyId}`)
        .then(async () => {
          if (actCreatorId !== lawyerId) {
            axios
              .delete(`/api/v1/signbooks/${publicId}/lawyers/${lawyerId}`)
              .then(() => {
                dispatch(setPostState(true));
                dispatch(hideModal());
              })
              .catch((error) => {
                setLoading(false);
                toast.error(
                  axiosErrorMessages[error.message],
                  toastOptionsError,
                );
              });
          } else {
            dispatch(setPostState(true));
            dispatch(hideModal());
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(axiosErrorMessages[error.message], toastOptionsError);
        });
      dispatch(setPageData(null));
      return;
    }
    setLoading(false);
  };

  const titleLabels =
    actType === "convention" ? labels.deleteClient : labels.deleteParty;
  const description =
    actType === "convention"
      ? labels.deleteClientFomEconvention
      : labels.deletePartyAndAdvocatLabel;
  return (
    <Modal title={titleLabels} width={410}>
      <p className="mt-5 body-sm">{description}</p>
      <p className="mb-5 body-sm">{labels.continuePrompt}</p>
      <div className="flex items-center justify-between">
        <Button
          onClick={() => {
            dispatch(setPageData(null));
            dispatch(hideModal());
          }}
          className="btn-secondary rounded-[8px] px-4 py-2 body-lg"
        >
          {labels.cancel}
        </Button>
        <Button
          onClick={() => handleDeleteParty()}
          disabled={loading}
          className="btn-secondary-emphasize rounded-[8px] px-4 py-2 body-md flex items-center justify-center"
        >
          {loading && <Spinner className="size-4 mr-2" />}
          {labels.delete}
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteParty;
