import { Form, Formik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Button from "@ui/Button";
import Spinner from "@ui/Spinner";

import { labels, preferencesDropdownOptions } from "@constants";

import { setPostState } from "@reducers/dataTransferSlice";
import { selectUser } from "@reducers/metadataSlice";

import { updatePreferences } from "@utils/api/userInfoApi";

type Values = {
  checkbox: boolean;
  buttonType: string;
};

const PreferredActs = () => {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const [selected, setSelected] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const handleCheckboxChange = (option: string) => {
    if (selected.includes(option)) {
      setSelected(selected.filter((item) => item !== option));
    } else {
      setSelected([...selected, option]);
    }
  };

  return (
    <Formik
      initialValues={{ checkbox: false, buttonType: "" }}
      onSubmit={async (values: Values) => {
        setLoading(true);
        const response = await updatePreferences(selected, user.publicId);

        if (response === 204) {
          dispatch(setPostState(true));
          setLoading(false);

          if (values.buttonType === "finalize-Profile") {
            navigate(`/profile`);
          } else if (values.buttonType === "start-new-eAct") {
            navigate(`/`);
          }
        }
      }}
    >
      {({ setFieldValue }) => (
        <Form>
          <div className="flex flex-col px-[45px] py-[40px] mt-10 justify-center items-center">
            <h2 className="flex headline-lg mb-5 text-center">
              {labels.yourPreferredEActUse}
            </h2>
            <p className="body-lg w-[440px]">
              {labels.yourPreferredEActPageDescription}
            </p>
            <div className="flex mt-10 justify-center">
              <ul>
                {preferencesDropdownOptions.map((option, index) => (
                  <li key={index}>
                    <label className="relative">
                      <input
                        type="checkbox"
                        name="checkbox"
                        checked={selected.includes(option.value)}
                        onChange={() => {
                          handleCheckboxChange(option.value);
                        }}
                        className={`${
                          selected.includes(option.value)
                            ? "custom-checkbox-checked"
                            : "custom-checkbox"
                        }`}
                      />
                      {option.label}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
            <p className="body-lg w-[440px] mt-10 text-ea-gray-200">
              {labels.yourPreferredEActUseInfoText}
            </p>
            <div className="flex justify-between w-full mt-20">
              <Button
                type="submit"
                className="btn-base btn-secondary flex items-center justify-center w-[200px] h-[35px]"
                disabled={loading || selected.length === 0}
                onClick={() => setFieldValue("buttonType", "finalize-Profile")}
              >
                {loading && <Spinner className="size-4 mr-4" />}
                {labels.finaliseNyProfile}
              </Button>
              <Button
                type="submit"
                className="btn-base btn-primary flex items-center justify-center w-[252px]"
                data-testid="next"
                disabled={loading || selected.length === 0}
                onClick={() => setFieldValue("buttonType", "start-new-eAct")}
              >
                {loading && <Spinner className="size-4 mr-4" />}
                {labels.startNewEAct}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default PreferredActs;
