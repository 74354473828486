// src/services/lawyerService.ts
import axios from "axios";
import { toast } from "react-toastify";

import { axiosErrorMessages, toastOptionsError } from "@constants";

const getParty = (
  searchTerm: string,
  type: string,
  setDataForParty: (data: any) => void,
  setSearchLoading: (loading: boolean) => void,
) => {
  axios
    .get(`/api/v1/contacts?page=&size=&keyword=${searchTerm}&userType=${type}`)
    .then((response) => {
      setDataForParty(response.data.users);
      setSearchLoading(false);
    })
    .catch((error) => {
      const errorMessage = axiosErrorMessages[error.message] || error.message;
      setSearchLoading(false);
      toast.error(errorMessage, toastOptionsError);
    });
};

export default getParty;
