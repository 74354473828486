import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import Button from "@ui/Button";
import Icon from "@ui/Icon";
import Modal from "@ui/Modal";
import Spinner from "@ui/Spinner";

import { axiosErrorMessages, labels, toastOptionsError } from "@constants";

import { selectModalData, setModalData } from "@reducers/dataTransferSlice";
import { hideModal } from "@reducers/modalsSlice";

import { formatLabel, getActorDataItem, setTokenData } from "@utils";

const RestrictedAccess = () => {
  const [smsValidateCode, setSmsValidateCode] = useState(false);
  const [attempts, setAttempts] = useState(0);
  const [accessBlocked, setAccessBlocked] = useState(false);
  const [attemptsLeft, setAttemptsLeft] = useState(3);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const data = useSelector(selectModalData);
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const signBookNumber =
    queryParams.get("signBookNumber") || queryParams.get("signbookNumber");
  const actorCode = queryParams.get("actorCode");
  const processNumber = queryParams.get("processNumber");
  const type = queryParams.get("type");

  const getNextUri = () => {
    if (data?.phoneNumber) {
      setPhoneNumber(data.phoneNumber);
      return;
    }
    let url = `/shared-api/v1/signbooks/${signBookNumber}/actors/${actorCode}/landing?type=SIGNING&processNumber=${processNumber}`;
    if (data?.initiatedFrom === "signatoryConfirmation") {
      url = `/shared-api/v1/signbooks/${signBookNumber}/actors/${actorCode}/landing?type=${type}`;
    }
    if (data?.initiatedFrom === "documentValidation") {
      url = `/shared-api/v1/signbooks/${signBookNumber}/actors/${actorCode}/landing?type=${type}`;
    }
    axios
      .get(url)
      .then((response) => {
        const number = response.data.phoneNumber;
        setPhoneNumber(number);
      })
      .catch((error) => {
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      });
  };

  const getFirstStep = () => {
    let url = `/shared-api/v1/process/${processNumber}/actors/${actorCode}/step/one?authMode=SMS_AUTHENTICATION&signbookNumber=${signBookNumber}`;

    if (data?.initiatedFrom === "signatoryConfirmation") {
      url = `/shared-api/v1/signbooks/${signBookNumber}/actors/${actorCode}/generate/code`;
    } else if (data?.initiatedFrom === "documentValidation") {
      url = `/shared-api/v1/signbooks/${signBookNumber}/actors/${actorCode}/generate/code`;
    }
    setLoading(true);
    axios
      .get(url)
      .then((response) => {
        setLoading(false);
        setSmsValidateCode(true);
        setAccessBlocked(false);
        const waitingTime =
          response.data.waitingTime?.waitingTimeBeforeNextGeneration ||
          response.data.waitingTimeBeforeNextGeneration;
        const waitingTimeInMiliseconds = waitingTime * 1000;
        const timestampWaitingTimeBeforeCallOtp =
          Date.now() + waitingTimeInMiliseconds;

        if (actorCode) {
          setTokenData(actorCode, {
            timestampWaitingTimeBeforeCallOtp,
          });
        }
        setTimeout(() => {
          setShowMessage(true);
        }, waitingTimeInMiliseconds);
      })
      .catch((error) => {
        const errorData = error.response;
        if (errorData?.status === 400 || errorData?.status === 502) {
          const errorMessage = errorData.data;

          const code = errorMessage.split(": ")[1];

          if (code) {
            setLoading(false);
            setSmsValidateCode(true);
            return;
          }
        }

        setSmsValidateCode(true);
        setAccessBlocked(true);
        setLoading(false);
      });
  };
  useEffect(() => {
    getNextUri();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const formatPhoneNumber = (phoneNumber: string) => {
    const visiblePart = phoneNumber.slice(0, 4);
    const hiddenPart = phoneNumber
      .slice(4, phoneNumber.length - 4)
      .replace(/\d/g, "*");
    const visibleEnd = phoneNumber.slice(
      phoneNumber.length - 4,
      phoneNumber.length,
    );
    return `${visiblePart}${hiddenPart}${visibleEnd}`;
  };

  // Will made into one schema when API starts returning numbers only
  const validationSchema = Yup.object().shape({
    code: Yup.number().required(formatLabel(labels.requiredField, labels.code)),
  });

  const titleToUse = accessBlocked
    ? labels.blockedAccess
    : labels.restrictedAccess;

  const handleResendCode = () => {
    setAttempts(0);
    setAttemptsLeft(3);
    setShowMessage(false);
    getFirstStep();
  };

  return (
    <Modal width={400} blurredOverlay={true} closable={false}>
      <div className="flex items-center justify-center mb-3">
        <Icon type="padlock" className="w-[16px] h-[21px] mr-2" />
        <h2 className="headline-lg">{titleToUse}</h2>
      </div>
      {!smsValidateCode ? (
        <>
          <p className="body-sm mb-2">{labels.validateWithPhoneLabel}</p>
          <p className="body-xl font-normal mb-9">
            {formatPhoneNumber(phoneNumber ?? "")}
          </p>

          <Button
            onClick={() => {
              if (actorCode) {
                const timestampWaitingTimeBeforeCallOtp = getActorDataItem(
                  actorCode,
                  "timestampWaitingTimeBeforeCallOtp",
                );
                const currentTime = Date.now();
                if (
                  timestampWaitingTimeBeforeCallOtp &&
                  currentTime <= timestampWaitingTimeBeforeCallOtp
                ) {
                  setAccessBlocked(true);
                  setSmsValidateCode(true);
                  return;
                }
                getFirstStep();
              }
            }}
            className="btn-primary body-md rounded-[8px] py-2 px-4 mx-auto flex items-center"
            disabled={loading}
          >
            {loading && <Spinner className="mr-2 size-4" />}
            {labels.receiveCodeBySms}
          </Button>
        </>
      ) : accessBlocked ? (
        // Display this if access is blocked after 3 failed attempts
        <div className="text-center">
          <p className="body-sm mb-3 w-[260px] mx-auto">
            {labels.blockedAccessMessage}
          </p>
        </div>
      ) : (
        <>
          <Formik
            initialValues={{ code: "" }}
            onSubmit={(values, { resetForm }) => {
              const maxAttempts = 3; // Maximum number of attempts allowed
              setLoading(true);
              let url = `/shared-api/v1/process/${processNumber}/actors/${actorCode}/step/two?signbookNumber=${signBookNumber}`;
              if (
                data?.initiatedFrom === "signatoryConfirmation" ||
                data?.initiatedFrom === "documentValidation"
              ) {
                url = `/shared-api/v1/signbooks/${signBookNumber}/actors/${actorCode}/verify/codes/${values.code}`;
              }
              axios
                .post(url, {
                  authenticationCode: values.code,
                })
                .then((response) => {
                  const updatedData = {
                    ...data,
                    authenticated: true,
                  };
                  let tokenData;
                  if (
                    data?.initiatedFrom === "signatoryConfirmation" ||
                    data?.initiatedFrom === "documentValidation"
                  ) {
                    tokenData = response.data;
                  } else {
                    tokenData = response.data.token;
                  }

                  if (actorCode) {
                    setTokenData(actorCode, tokenData);
                  }

                  dispatch(setModalData(updatedData));
                  dispatch(hideModal());
                  setLoading(false);
                })
                .catch(() => {
                  const newAttempts = attempts + 1;
                  setAttempts(newAttempts);
                  setLoading(false);
                  if (newAttempts >= maxAttempts) {
                    setAccessBlocked(true);
                  } else {
                    const attemptsLeftCount = maxAttempts - newAttempts;
                    setAttemptsLeft(attemptsLeftCount);
                    resetForm();
                  }
                });
            }}
            validationSchema={validationSchema}
          >
            {() => {
              return (
                <Form>
                  <div className="flex items-center flex-col mb-9">
                    <p className="body-sm mb-3">
                      {labels.enterTheReceivedCodeBySms}
                    </p>
                    <Field
                      type="number"
                      name="code"
                      autoFocus
                      className="input border border-ea-gray-200 rounded body-lg h-[34px] p-2 bg-white max-w-[143px] mb-3"
                    />
                    <ErrorMessage name="code">
                      {(msg) => (
                        <span className="text-[10px] max-w-[293px] text-ea-red">
                          {msg}
                        </span>
                      )}
                    </ErrorMessage>

                    {showMessage && (
                      <p className="body-sm">
                        {labels.haveYouNotReceivedCode} &nbsp;
                        <Button
                          type="button"
                          className="body-sm underline"
                          onClick={handleResendCode}
                        >
                          {labels.resendCode}
                        </Button>
                      </p>
                    )}
                    {attemptsLeft <= 2 && (
                      <p className="text-[10px] font-medium text-center text-ea-red mb-2">
                        {formatLabel(
                          labels.attemptsNumberLeft,
                          attemptsLeft.toString(),
                        )}
                      </p>
                    )}
                  </div>
                  <Button
                    type="submit"
                    className="btn-primary body-md rounded-[8px] py-2 px-4 mx-auto flex items-center"
                    disabled={loading}
                  >
                    {loading && <Spinner className="mr-2 size-4" />}
                    {labels.validate}
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </>
      )}
    </Modal>
  );
};

export default RestrictedAccess;
