import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "@types";

const initialState = {
  formValue: "TYPE_NATURAL",
};

const partyFormSlice = createSlice({
  name: "partyForm",
  initialState,
  reducers: {
    showForm: (state, action) => {
      state.formValue = action.payload;
    },
    resetPartyForm: (state) => {
      state.formValue = "TYPE_NATURAL";
    },
  },
});

export const { showForm, resetPartyForm } = partyFormSlice.actions;

export const selectPartyForm = (state: RootState) => state.partyForm.formValue;

export default partyFormSlice.reducer;
