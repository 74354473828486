import { useEffect } from "react";

import Icon from "@ui/Icon";

import { labels } from "@constants";

import useOrientation from "@hooks/useOrientation";

const PhoneMessage = () => {
  const isPortrait = useOrientation();

  // Show the message on specific pages in portrait mode
  useEffect(() => {
    document.body.style.overflow = isPortrait ? "hidden" : "";
  }, [isPortrait]);

  if (!isPortrait) {
    return null;
  }

  return (
    <div className="fixed top-[77px] left-0 w-[100%] h-[100%] flex justify-center items-center bg-white headline-md z-[11] text-center sm:hidden overscroll-y-none">
      <div className="flex items-center justify-center flex-col px-3 max-w-[300px] w-full">
        <p className="mb-8">{labels.rotatePhoneMessage}</p>
        <Icon type="rotate-phone" className="size-[48px]" />
      </div>
    </div>
  );
};

export default PhoneMessage;
