import axios from "axios";
import { toast } from "react-toastify";

import { axiosErrorMessages, toastOptionsError } from "@constants";

const getLoggedUser = async () => {
  return axios
    .get("/api/v1/me")
    .then((response) => response.data)
    .catch((error) => {
      toast.error(axiosErrorMessages[error.message], toastOptionsError);
    });
};

const updateUser = async (userData: any) => {
  return axios
    .put("/api/v1/me", userData)
    .then((response) => response.status)
    .catch((error) => {
      toast.error(axiosErrorMessages[error.message], toastOptionsError);
    });
};

const updateCGU = async () => {
  return axios
    .patch("/api/v1/me/cgu")
    .then((response) => {
      return response.status;
    })
    .catch((error) => {
      toast.error(axiosErrorMessages[error.message], toastOptionsError);
    });
};

const updatePreferences = async (preferences: string[], publicId: string) => {
  return axios
    .put(`/api/v1/users/${publicId}/preferences`, { actTypes: preferences })
    .then((response) => {
      return response.status;
    })
    .catch((error) => {
      toast.error(axiosErrorMessages[error.message], toastOptionsError);
    });
};
export { getLoggedUser, updateCGU, updateUser, updatePreferences };
