import { Field, Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "@ui/Button";

import { guideTemplateName, labels } from "@constants";

import { setActData } from "@reducers/dataTransferSlice";
import { selectSettings } from "@reducers/metadataSlice";

import { fetchAndDownloadTemplate } from "@utils";

const DivorceInformation = () => {
  const [isActionConfirmed, setIsActionConfirmed] = useState(false);
  const dispatch = useDispatch();
  const dcmIntro = useSelector(selectSettings)?.dcmIntro || "";

  const contentRef = useRef<HTMLDivElement>(null);

  const handleGuideClick = (event: any) => {
    // Check if the clicked element is an anchor tag and the text content is "Practice Guide"
    if (
      event.target.tagName === "A" &&
      event.target.textContent === labels.practiceGuide
    ) {
      event.preventDefault();
      fetchAndDownloadTemplate(guideTemplateName);
    }
  };

  // Add event listener to the content element to handle the click event
  useEffect(() => {
    const contentElement = contentRef.current;
    if (contentElement) {
      contentElement.addEventListener("click", handleGuideClick);
    }

    return () => {
      if (contentElement) {
        contentElement.removeEventListener("click", handleGuideClick);
      }
    };
  }, []);

  return (
    <Formik
      onSubmit={() => {
        dispatch(setActData({ actionConfirmed: true }));
      }}
      initialValues={{
        actionConfirmation: false,
      }}
    >
      {(formik) => (
        <Form>
          <div className="terms">
            <h2 className="headline-lg mb-5">
              {labels.divorceProcedureInformation}
            </h2>
            <div className="scrollbar-outside body-sm max-h-[471px] overflow-y-auto [&_a]:underline">
              <div
                ref={contentRef}
                dangerouslySetInnerHTML={{ __html: dcmIntro }}
              />
            </div>

            <div className="flex justify-between items-center mt-[61px]">
              <div className="custom-checkbox-container my-[34px] pl-[24px] body-lg">
                <label data-testid="action-confirmation">
                  {labels.actionConfirmation}
                  <Field
                    name="actionConfirmation"
                    className="custom-checkbox"
                    type="checkbox"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const isChecked = event.target.checked;

                      formik.setFieldValue("actionConfirmation", isChecked);
                      setIsActionConfirmed(isChecked);
                    }}
                  />
                </label>
              </div>
              <Button
                type="submit"
                className="btn-base btn-primary"
                disabled={!isActionConfirmed}
                data-testid="confirm-action"
              >
                {labels.commence}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default DivorceInformation;
