import Icon from "@ui/Icon";

import { LoaderPropsWithStyle } from "@types";

const Loader = ({ error = "", style = {} }: LoaderPropsWithStyle) => {
  if (error && error !== "") {
    return (
      <div className="flex items-start" data-testid="loader-error">
        <Icon
          type="close-circle"
          className="text-white size-[18px]"
          color="#E40000"
        />
        <p className="text-red-600 text-xs ml-1">{error}</p>
      </div>
    );
  }

  return (
    <div className="animate-pulse my-2 w-full" data-testid="loader">
      <div
        className="bg-slate-200 w-full h-[12px] rounded-lg"
        style={style}
      ></div>
    </div>
  );
};

export default Loader;
