import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import Button from "@ui/Button";
import Icon from "@ui/Icon";

import { labels } from "@constants";

import { selectNotifictaions } from "@reducers/metadataSlice";

import { NotificationsProps } from "@types";

import { formatLabel } from "@utils";

const Notifications = ({ className = "" }: NotificationsProps) => {
  const [currentMessage, setCurrentMessage] = useState(0);
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [moreInfoExpanded, setMoreInfoExpanded] = useState(false);

  const notificationWrapperRef = useRef<HTMLDivElement>(null);
  const notificationRef = useRef<HTMLDivElement>(null);

  const notificationWrapperClass =
    showMoreInfo && moreInfoExpanded ? "w-full" : "";
  const notificationClass = moreInfoExpanded ? "" : "whitespace-nowrap";
  const infoButtonText = moreInfoExpanded
    ? labels.lessInformation
    : labels.moreInformation;

  const messages = useSelector(selectNotifictaions);

  const nextMessage = () => {
    let nextIndex = currentMessage + 1;

    if (nextIndex === messages.length) {
      nextIndex = 0;
    }

    setCurrentMessage(nextIndex);
    setMoreInfoExpanded(false);
  };

  const prevMessage = () => {
    let prevIndex = currentMessage - 1;

    if (prevIndex < 0) {
      prevIndex = messages.length - 1;
    }

    setCurrentMessage(prevIndex);
  };

  useEffect(() => {
    const notificationsWrapperWidth =
      notificationWrapperRef.current?.clientWidth;
    const notificationWidth = notificationRef.current?.clientWidth;

    if (notificationsWrapperWidth && notificationWidth) {
      setShowMoreInfo(notificationWidth > notificationsWrapperWidth);
    }
  }, [currentMessage, messages]);

  if (!messages.length) {
    return null;
  }

  return (
    <div
      className={`bg-ea-yellow py-[8px] px-[24px] body-md rounded-[4px] ${className}`}
    >
      <div className="flex items-center justify-between">
        <div className="hidden md:flex items-center">
          <Button
            type="button"
            onClick={prevMessage}
            data-testid="button-prev"
            disabled={messages.length < 2}
          >
            <Icon type="arrow-left-pagination" className="size-[16px]" />
          </Button>
          <span className="px-1">
            {formatLabel(
              labels.XofY,
              (currentMessage + 1).toString(),
              messages.length.toString(),
            )}
          </span>
          <Button
            type="button"
            onClick={nextMessage}
            data-testid="button-next"
            disabled={messages.length < 2}
          >
            <Icon type="arrow-right-pagination" className="size-[16px]" />
          </Button>
        </div>
        <div
          className="flex flex-1 w-full items-center md:max-w-[430px] xl:max-w-[776px] overflow-hidden relative"
          ref={notificationWrapperRef}
          data-testid="notification-wrapper"
        >
          <div
            className={`flex items-center ${notificationWrapperClass}`}
            ref={notificationRef}
          >
            <Icon
              type="exclamation-triangle"
              className="size-[24px] mx-[8px] shrink-0"
            />
            {showMoreInfo && moreInfoExpanded ? (
              <p
                className={`${notificationClass} w-full`}
                data-testid="notification-message"
                dangerouslySetInnerHTML={{
                  __html: messages[currentMessage].content,
                }}
              ></p>
            ) : (
              <p
                className={notificationClass}
                data-testid="notification-message"
              >
                {messages[currentMessage].content.replace(/(<([^>]+)>)/gi, "")}
              </p>
            )}
          </div>
          {showMoreInfo && !moreInfoExpanded && (
            <span
              className="absolute top-1/2 right-0 -translate-y-2/4 bg-ea-yellow pl-[2px]"
              data-testid="notification-ellipsis"
            >
              ...
            </span>
          )}
        </div>
        <div className="w-[65px]">
          {showMoreInfo && (
            <Button
              className="font-bold underline whitespace-nowrap"
              onClick={() => {
                setMoreInfoExpanded(!moreInfoExpanded);
              }}
              data-testid="more-info-button"
            >
              {infoButtonText}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Notifications;
