import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Modal from "@ui/Modal";

import { labels } from "@constants";

import {
  selectModalData,
  setModalData,
  setPageData,
} from "@reducers/dataTransferSlice";
import { hideModal } from "@reducers/modalsSlice";

function LogOut() {
  const dispatch = useDispatch();

  const modalData = useSelector(selectModalData);

  const ifLogoutData = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    if (modalData.logOut) {
      event.preventDefault();

      dispatch(
        setPageData({
          logOut: true,
        }),
      );
    }
  };

  return (
    <Modal
      title={labels.logOut}
      description={labels.saveModification}
      width={400}
    >
      <div className="flex items-center justify-between mt-[40px]">
        <Link
          className="btn-secondary rounded-[8px] px-[13px] py-[6px] body-md"
          to=""
          onClick={(event) => {
            ifLogoutData(event);
            dispatch(setModalData(null));
            dispatch(hideModal());
          }}
        >
          {labels.quitWithoutSaving}
        </Link>
        <Link
          className="btn-primary rounded-[8px] px-[13px] py-[6px] body-md"
          to=""
          onClick={(event) => {
            ifLogoutData(event);
            dispatch(setModalData(null));
            dispatch(hideModal());
          }}
        >
          {labels.save}
        </Link>
      </div>
    </Modal>
  );
}
export default LogOut;
