import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "@types";

const initialState = {
  sealedAllParties: false,
  validatedData: false,
  lastReachedStep: "",
};

const actCreationSlice = createSlice({
  name: "actCreation",
  initialState,
  reducers: {
    setValidatedData: (state, action) => {
      state.validatedData = action.payload;
    },
    setLastReachedStep: (state, action) => {
      state.lastReachedStep = action.payload;
    },
  },
});

export const { setValidatedData, setLastReachedStep } =
  actCreationSlice.actions;

export const selectValidatedData = (state: RootState) =>
  state.actCreation.validatedData;
export const selectLastReachedStep = (state: RootState) =>
  state.actCreation.lastReachedStep;
export default actCreationSlice.reducer;
