import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "@types";

const initialState = {
  version: "",
  user: {
    firstName: "",
    lastName: "",
    numCNBF: "",
    email: "",
    username: "",
    address: "",
    birthDate: "",
    city: "",
    commonName: "",
    companyCode: "",
    displayName: "",
    gender: "",
    id: "",
    lawyerRole: "",
    mainPhoneNumber: null,
    metaData: null,
    metaDataValues: null,
    ownerId: "",
    publicId: "",
    relation: "",
    representedBirthDate: null,
    representedFirstname: "",
    representedLastname: "",
    title: "",
    type: "",
    zipCode: "",
    actTypes: [],
    keycloakToken: "",
  },
  notifications: [],
  errors: {},
  settings: {
    cookieBotId: "",
    cookieBotKey: new Date().getTime(),
    dcmAmount: 0,
    eConventionAmount: 0,
    natifAmount: 0,
    numeriseAmount: 0,
    procedureAmount: 0,
    dcmIntro: "",
  },
  prepaidAccountInfo: {
    balance: 0,
    currentEstimatedBalance: 0,
    warningNotificationLimit: 0,
    warningNotificationLimitType: "",
  },
  requiredActionsCount: {
    countToSign: null,
    countToValidate: null,
    countForPayment: null,
    countToSealMember: null,
    totalCount: null,
  },
};

const metadataSlice = createSlice({
  name: "metadata",
  initialState,
  reducers: {
    setVersion: (state, action) => {
      state.version = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    setSettings: (state, action) => {
      state.settings = action.payload;
    },
    setPrepaidAccountInfo: (state, action) => {
      state.prepaidAccountInfo = action.payload;
    },
    setRequiredActionsCount: (state, action) => {
      state.requiredActionsCount = action.payload;
    },
  },
});

export const {
  setVersion,
  setUser,
  setNotifications,
  setErrors,
  setSettings,
  setPrepaidAccountInfo,
  setRequiredActionsCount,
} = metadataSlice.actions;

export const selectVersion = (state: RootState) => state.metadata.version;
export const selectUser = (state: RootState) => state.metadata.user;
export const selectNotifictaions = (state: RootState) =>
  state.metadata.notifications;
export const selectErrors = (state: RootState) => state.metadata.errors;
export const selectSettings = (state: RootState) => state.metadata.settings;
export const selectPrepaidAccountInfo = (state: RootState) =>
  state.metadata.prepaidAccountInfo;
export const selectRequiredActionsCount = (state: RootState) =>
  state.metadata.requiredActionsCount;

export default metadataSlice.reducer;
