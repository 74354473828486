import axios from "axios";
import { toast } from "react-toastify";

import { axiosErrorMessages, toastOptionsError } from "@constants";

const getIdentificationDocuments = (
  publicId: string,
  signatoryCode: string,
) => {
  return axios
    .get(
      `/api/v1/signbooks/${publicId}/signatories/${signatoryCode}/identification-documents`,
    )
    .then((response) => response.data)
    .catch((error) => {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(axiosErrorMessages[errorMessage], toastOptionsError);
    });
};

const deleteIdentificationDocument = (
  publicId: string,
  signatoryCode: string,
) => {
  return axios
    .delete(
      `/api/v1/signbooks/${publicId}/signatories/${signatoryCode}/identification-documents`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(axiosErrorMessages[errorMessage], toastOptionsError);
    });
};

export { getIdentificationDocuments, deleteIdentificationDocument };
