import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  UniqueIdentifier,
  closestCorners,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import React from "react";
import { useDispatch } from "react-redux";

import { setOrderChanged } from "@reducers/validationSlice";

type DndComponentProps = {
  items: (UniqueIdentifier | { id: UniqueIdentifier })[];
  setItems: (item: any) => void;
  children: React.ReactNode;
};
const DndComponent = ({ items, setItems, children }: DndComponentProps) => {
  const dispatch = useDispatch();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const getItemsPos = (id: number) =>
    items.findIndex((item: any) => item.id === id);

  const handleDragEnd = (event: { active: any; over: any }) => {
    const { active, over } = event;

    if (active.id === over.id) {
      return;
    }

    setItems((items: any[]) => {
      const newPos = getItemsPos(over.id);

      // Prevent moving any item above the fixed item
      if (newPos === 0 && items[0].fixed) {
        return items;
      }

      // Remove the fixed item from the items array
      const fixedItem = items.find((item) => item.fixed);
      const sortableItems = items.filter((item) => !item.fixed);

      const originalPosInSortable = sortableItems.findIndex(
        (item) => item.id === active.id,
      );
      const newPosInSortable = sortableItems.findIndex(
        (item) => item.id === over.id,
      );

      const newItems = arrayMove(
        sortableItems,
        originalPosInSortable,
        newPosInSortable,
      );

      // Reinsert the fixed item at the top
      if (fixedItem) {
        newItems.unshift(fixedItem);
      }

      // Update orderChanged state
      dispatch(setOrderChanged(true));

      // Update order property based on new position
      return newItems.map((item: any, index) => ({
        ...item,
        order: index + 1,
      }));
    });
  };

  return (
    <div>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCorners}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToParentElement]}
      >
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          {children}
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default DndComponent;
