import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Icon from "@ui/Icon";

import useIsMobile from "@hooks/useIsMobile";

import { TabListProps, TabsProps } from "@types";

const TabButtons = ({ tab, setTab, tabsList, pillMap = {} }: TabsProps) => {
  const isMobile = useIsMobile();

  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [selectedDropdownTab, setSelectedDropdownTab] =
    useState<TabListProps | null>(null);
  const [dynamicTabsList, setDynamicTabsList] = useState<TabListProps[]>([]);
  // We need this useEffect to set the initial selected dropdown tab and the dynamic tabs list
  // This is needed because the tabsList prop is fetched asynchronously
  useEffect(() => {
    const initialSelectedDropdownTab = tabsList[3];
    const initialDynamicTabsList = tabsList.slice(4);

    setSelectedDropdownTab(initialSelectedDropdownTab);
    setDynamicTabsList(initialDynamicTabsList);
  }, [tabsList]);

  const handleSelectTab = (tabId: number) => {
    const selectedTabData = tabsList.find((tab) => tab.id === tabId);
    if (selectedTabData) {
      setTab(tabId);
      if (selectedTabData.url) navigate(selectedTabData.url);
    }
  };

  const handleSelectDropdownTab = (tabId: number) => {
    const selectedTabData = tabsList.find((tab) => tab.id === tabId);
    if (selectedTabData) {
      // Swap the selected dropdown tab with the fourth tab
      const replacedTab = selectedDropdownTab;
      setSelectedDropdownTab(selectedTabData);

      const newDynamicTabsList = dynamicTabsList
        .map((tab) => (tab.id === selectedTabData.id ? replacedTab : tab))
        .filter((tab) => tab !== null) as TabListProps[];

      setDynamicTabsList(newDynamicTabsList);
      handleSelectTab(tabId);
      setDropdownOpen(false);
    }
  };

  return (
    <div
      className="flex gap-4 lg:gap-5 justify-between self-start md:mt-14 text-base font-medium text-ea-gray-300 max-md:flex-wrap"
      data-testid="tab-buttons-container"
    >
      {!isMobile ? (
        tabsList.map((tabListData, index) => {
          if (tabListData.hidden) return null;

          const isActiveTabStyle =
            tab === tabListData.id ? "text-black active-tab__line" : "";
          const hasPill = tabListData?.type && pillMap?.[tabListData.type];
          const hasNewDocumentStyle =
            hasPill || tabListData.hasExpiringSoon ? (
              <span
                role="presentation"
                className="absolute bg-ea-red w-[12px] h-[12px] top-[7px] right-[-12px] rounded-[50%]"
              ></span>
            ) : null;

          return (
            <div
              key={index}
              className={`py-2 cursor-pointer relative ${isActiveTabStyle}`}
              onClick={() => handleSelectTab(tabListData.id)}
              data-testid={tabListData.testId}
            >
              {hasNewDocumentStyle}
              {tabListData.title}
            </div>
          );
        })
      ) : (
        <>
          {tabsList.slice(0, 3).map((tabItem, index) => (
            <div
              key={tabItem.id}
              className={`py-2 cursor-pointer relative ${
                tab === tabItem.id ? "text-black active-tab__line" : ""
              }`}
              onClick={() => handleSelectTab(tabItem.id)}
              data-testid={`tab-${index + 1}`}
            >
              {tabItem.title}
            </div>
          ))}

          <div
            className={`py-2 cursor-pointer relative ${
              tab === selectedDropdownTab?.id
                ? "text-black active-tab__line"
                : ""
            }`}
            onClick={() => handleSelectTab(selectedDropdownTab?.id ?? 0)}
            data-testid="fourth-tab"
          >
            {selectedDropdownTab?.title}
          </div>

          {tabsList.length > 4 && (
            <div className="relative">
              <button
                onClick={() => setDropdownOpen(!dropdownOpen)}
                className="py-2 cursor-pointer"
                data-testid="tab-dropdown-toggle"
              >
                <Icon
                  type={dropdownOpen ? "arrow-sort-up" : "arrow-sort-down"}
                  className="size-[12px] mt-2"
                />
              </button>
              {dropdownOpen && (
                <div className="absolute bg-white shadow-md rounded-md mb-2 z-[999] w-[200px] ml-[-180px] p-2">
                  {dynamicTabsList.map((tabListData, index) => (
                    <div
                      key={index}
                      className="py-1 px-2 cursor-pointer hover:bg-gray-100"
                      onClick={() => handleSelectDropdownTab(tabListData.id)}
                    >
                      {tabListData.title}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TabButtons;
