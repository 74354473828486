import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "@types";

const initialState = {
  locale: "fr",
};

const localeSlice = createSlice({
  name: "locale",
  initialState,
  reducers: {
    setLocale: (state, action) => {
      const { locale } = action.payload;
      state.locale = locale;
    },
  },
});

export const { setLocale } = localeSlice.actions;

export const selectLocale = (state: RootState) => state.locale.locale;

export default localeSlice.reducer;
