import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "@types";

const initialState = {
  eafOn: false,
  tokens: [],
};

const eafSlice = createSlice({
  name: "eaf",
  initialState,
  reducers: {
    setEafOn: (state, action) => {
      state.eafOn = action.payload;
    },
    setTokens: (state, action) => {
      state.tokens = action.payload;
    },
  },
});

export const { setEafOn, setTokens } = eafSlice.actions;

export const selectEafOn = (state: RootState) => state.eaf.eafOn;
export const selectTokens = (state: RootState) => state.eaf.tokens;

export default eafSlice.reducer;
