import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import Button from "@ui/Button";
import Modal from "@ui/Modal";
import Spinner from "@ui/Spinner";

import { axiosErrorMessages, labels, toastOptionsError } from "@constants";

import { setValidatedData } from "@reducers/actCreationSlice";
import { setLoadingState } from "@reducers/dataTransferSlice";
import { hideModal } from "@reducers/modalsSlice";
import {
  selectDocuments,
  setValidatedDocuments,
} from "@reducers/validationSlice";

import { PathMap } from "@types";

import { getPathMap } from "@utils";

const ValidateDocuments = () => {
  const dispatch = useDispatch();
  const { actType, "*": rest } = useParams();
  const publicId = rest ? rest.split("/")[0] : "";
  const pathMap: PathMap = getPathMap(actType, publicId);
  const thirdStepPath = pathMap[3].url;
  const documents = useSelector(selectDocuments);
  const [loading, setLoading] = useState<boolean>(false);

  const validateDocuments = () => {
    setLoading(true);
    axios
      .put(`/api/v1/signbooks/${publicId}/documents/validation`)
      .then((response) => {
        if (response.status === 204) {
          setLoading(false);
          dispatch(setLoadingState(false));
          dispatch(setValidatedDocuments(documents));
          dispatch(setValidatedData(true));
          dispatch(hideModal());
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      });
  };
  // To be removed when  we start using publicId in the url
  // For now it is nessesary to disable the button if publicId is not available
  const buttonDisabled = !publicId || loading ? "btn-disabled" : "";

  return (
    <Modal title={labels.validateDocuments} width={466}>
      <div className="text-left">
        <p className="body-sm mt-[10px]">
          {labels.aboutToValidate}
          <br />
          {labels.canNoLongerModify}
        </p>
        <p className="body-sm mt-[10px]">{labels.continuePrompt}</p>
        <div className="flex justify-between mt-8">
          <Button
            className="btn-secondary flex justify-start items-center rounded-[8px] px-[21px] py-[8px] body-md h-[34px]"
            onClick={() => dispatch(hideModal())}
            disabled={loading}
          >
            {labels.cancel}
          </Button>
          <Link
            to={`/acts/${actType}${thirdStepPath}`}
            className={`btn btn-primary flex justify-end items-center rounded-[8px] px-[21px] py-[8px] body-md h-[34px] ${buttonDisabled}`}
            onClick={() => {
              dispatch(setLoadingState(true));
              validateDocuments();
            }}
            data-testid="validate-documents"
          >
            {loading && <Spinner className="size-4 mr-2" />}
            {labels.validateDocuments}
          </Link>
        </div>
      </div>
    </Modal>
  );
};

export default ValidateDocuments;
