import { useKeycloak } from "@react-keycloak/web";

import { Field } from "formik";
import { useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";

import { labels } from "@constants";

import { selectPrepaidAccountInfo, selectUser } from "@reducers/metadataSlice";
import { showModal } from "@reducers/modalsSlice";

import { addQueryParams } from "@utils";
import { updatePrepaidAccount } from "@utils/api/prepaidAccountApi";

const Account = ({ formik }: { formik: any }) => {
  const { keycloak } = useKeycloak();
  const userData = useSelector(selectUser);
  const prepaidAccountInfo = useSelector(selectPrepaidAccountInfo);
  const bankCardAccountUrl = process.env.REACT_APP_BEPAY_CARD_URL;
  const balanceAmount = prepaidAccountInfo?.balance
    .toString()
    .replace(".", ",");
  const [event, updateEvent] = useReducer(
    (prev: any, next: any) => {
      const newEvent = { ...prev, ...next };
      return newEvent;
    },
    {
      toggle0: formik.values.accountLimitWarning,
    },
  );
  const dispatch = useDispatch();

  const scrollToElement = () => {
    const element = document.getElementById("my-prepaid-account");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleWarningActivation = (status: boolean) => {
    updateEvent({ toggle0: status });
    formik.setFieldValue("accountLimitWarning", status);
  };

  useEffect(() => {
    if (window.location.hash === "#my-prepaid-account") {
      scrollToElement();
    }
  }, []);

  return (
    <div className="headline-lg mt-[24px] mr-auto ml-auto mb-[39px]">
      <section className="lg:w-[612px] mr-auto ml-auto" id="my-prepaid-account">
        <h2 className="mb-3">{labels.myPrepaidAccount}</h2>
        <div className="flex flex-row flex-wrap mb-[24px] justify-between items-center">
          <div className="flex flex-row justify-start md:justify-between md:w-[293px]">
            <p className="flex headline-md mr-3 md:mr-0">{labels.balance}</p>
            <p className="flex headline-md">
              {balanceAmount} {labels.euroSign}
            </p>
          </div>
          <div className="flex flex-row justify-end md:w-[293px]">
            <div className="inline-flex rounded-md shadow-sm" role="group">
              <button
                type="button"
                className="px-4 py-2 body-md rounded-s-lg btn-secondary "
                onClick={() => {
                  dispatch(showModal("reloadAccount"));
                }}
              >
                {labels.recharge}
              </button>
              <button
                type="button"
                className="px-4 py-2 body-md rounded-e-lg btn-secondary"
                onClick={() => {
                  updatePrepaidAccount(
                    keycloak?.token,
                    "30",
                    userData?.lastName,
                  ).then((response) => {
                    if (response === undefined) {
                      return;
                    }
                    window.location.href = response.sessionUrl;
                  });
                }}
              >
                <div className="flex justify-center">
                  <p className="mr-[2px]">{labels.rechargeAmount}</p>
                  <p>{labels.euroSign}</p>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-col mb-[24px]">
          <div className="flex flex-row justify-between">
            <p className="flex justify-start body-lg font-bold">
              {labels.accountAlertThreshold}
            </p>
            <div className="flex flex-row justify-end">
              <label className="inline-flex items-center cursor-pointer">
                <Field
                  type="checkbox"
                  name="accountLimitWarning"
                  id="accountLimitWarning"
                  className="sr-only peer justify-start"
                  onChange={() => handleWarningActivation(!event.toggle0)}
                />
                <div className="h-[20px] w-[36px] relative bg-gray-200 peer-focus:outline-none rounded-full peer dark:ea-gray-100 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-ea-darkblue"></div>
                <span className="ms-3 text-sm body-md justify-end w-[60px]">
                  {formik.values.accountLimitWarning ? "Activé" : "Désactivé"}
                </span>
              </label>
            </div>
          </div>
          <p className="body-sm w-full lg:w-[293px] mt-[10px]">
            {labels.accountAlertThresholdDescription}
          </p>
        </div>
        <div className="flex justify-between items-center mb-[37px]">
          <div className="flex justify-start flex-row">
            <p className="flex body-lg font-bold">
              {labels.registredBankCards}
            </p>
          </div>
          <div className="flex flex-row md:w-[293px] items-center justify-end">
            <button
              type="button"
              className="px-[8px] md:px-4 py-2 body-md btn-secondary rounded-[8px]"
              onClick={() => {
                if (bankCardAccountUrl) {
                  window.open(
                    addQueryParams(bankCardAccountUrl, {
                      redirectUrl: window.location.href,
                    }),
                    "_self",
                  );
                }
              }}
            >
              {labels.manageMyBankCards}
            </button>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <div className="flex flex-row">
            <p className="flex justify-start body-lg font-bold">
              {labels.invoicesHistory}
            </p>
          </div>
          <div className="flex flex-row lg:w-[293px] items-center justify-end">
            <button
              type="button"
              className="px-4 py-2 body-md btn-secondary rounded-[8px]"
              onClick={() => {
                dispatch(showModal("invoiceHistory"));
              }}
            >
              {labels.viewHistory}
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Account;
