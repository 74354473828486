import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "@types";

const initialState = {
  modal: "",
};

const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    showModal: (state, action) => {
      state.modal = action.payload;
    },
    hideModal: (state) => {
      state.modal = "";
    },
  },
});

export const { showModal, hideModal } = modalsSlice.actions;

export const selectModal = (state: RootState) => state.modals.modal;
export default modalsSlice.reducer;
