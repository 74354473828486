import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";

import Button from "@ui/Button";
import CustomDatePickerHeader from "@ui/DatepickerCustomHeader";
import FieldDropdown from "@ui/FieldDropdown";
import FileUpload from "@ui/FileUpload";
import Icon from "@ui/Icon";
import Loader from "@ui/Loader";
import PhoneField from "@ui/PhoneField";

import {
  axiosErrorMessages,
  labels,
  toastOptions,
  toastOptionsError,
} from "@constants";

import Modals from "@modals/Modals";

import { selectModalData, setModalData } from "@reducers/dataTransferSlice";
import { showModal } from "@reducers/modalsSlice";

import { Document } from "@types";

import {
  addQueryParams,
  formatDateBirth,
  formatLabel,
  getActorDataItem,
  handleSessionExpiration,
  isPastDate,
  noop,
  refreshToken,
  setTokenData,
} from "@utils";
import { getPhoneCode, getPhoneNumber } from "@utils/phone";
import { nameRegex } from "@utils/regex";

const SignatoryConfirmation = () => {
  const [documents, setDocuments] = useState<
    Document[] | Record<string, string>[]
  >([]);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    birthDate: "",
    mobileNumber: "",
    nationality: "",
    commonName: "",
    mobilePhoneCountryCode: "",
  });
  const [phone, setPhone] = useState<string>();
  const [phoneCode, setPhoneCode] = useState<string>();
  const [phoneCountryCode, setPhoneCountryCode] = useState<string>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const signBookNumber = queryParams.get("signbookNumber") || "";
  const actorCode = queryParams.get("actorCode") || "";
  const data = useSelector(selectModalData);
  const [accessToken, setAccessToken] = useState<string | null>(
    getActorDataItem(actorCode, "accessToken"),
  );
  const [tokenForRefresh, setTokenForRefresh] = useState<string | null>(
    getActorDataItem(actorCode, "refreshToken"),
  );
  const [accessTokenExpiresIn, setAccessTokenExpiresIn] = useState(
    getActorDataItem(actorCode, "expiresIn"),
  );
  const [refreshTokenExpiresIn, setRefreshTokenExpiresIn] = useState(
    getActorDataItem(actorCode, "refreshToken"),
  );

  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  const [deletingIndices, setDeletingIndices] = useState<number[]>([]);
  const [initializing, setInitializing] = useState(true);
  const [numberFilesUploaded, setNumberFilesUploaded] = useState(1);
  const notAuthorized = !accessToken as boolean;

  const fetchUserData = async () => {
    axios
      .get(
        `/shared-api/v1/signbooks/${signBookNumber}/actors/${actorCode}/actor/info`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      )
      .then((response) => {
        const [year, month, day] = response.data.birthDate.split("/");
        // Reformat the date as dd/mm/yyyy
        const formattedBirthDate = `${day}/${month}/${year}`;
        const userData = {
          ...response.data,
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          email: response.data.email,
          birthDate: formattedBirthDate,
          mobilePhoneCountryCode: response.data.mobilePhoneCountryCode,
        };

        setUserData(userData);
        const splittedPhone = getPhoneNumber(
          userData.mobileNumber,
          userData.mobilePhoneCountryCode,
        );
        const splittedPhoneCode = getPhoneCode(
          userData.mobileNumber,
          userData.mobilePhoneCountryCode,
        );
        setPhone(splittedPhone);
        setPhoneCode(splittedPhoneCode);
        setPhoneCountryCode(userData.mobilePhoneCountryCode);
      })
      .catch((error) => {
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      });
  };

  const fetchDocuments = async () => {
    axios
      .get(
        `/shared-api/v1/signbooks/${signBookNumber}/actors/${actorCode}/signbook/identification-documents`,
        { headers: { Authorization: `Bearer ${accessToken}` } },
      )
      .then((response) => {
        const documents = response.data;
        if (documents.length > 0) {
          const newDocuments = documents.map((document: Document) => {
            return {
              id: document.documentUid,
              name:
                document.documentName?.split("/")[1] || document.documentName,
              size: document.fileSize,
            };
          });

          setDocuments(newDocuments);
        }
        setLoading(false);
        setInitializing(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      });
  };

  useEffect(() => {
    if (!accessToken) {
      dispatch(showModal("restrictedAccess"));
      dispatch(setModalData({ initiatedFrom: "signatoryConfirmation" }));
    }
  }, [dispatch, accessToken]);

  useEffect(() => {
    if (!accessToken) {
      return;
    }

    const currentTime = Date.now();

    // Get expiration timestamps from localStorage if they exist
    let accessTokenExpiryTime = Number(
      getActorDataItem(actorCode, "accessTokenExpiryTimestamp"),
    );
    let refreshTokenExpiryTime = Number(
      getActorDataItem(actorCode, "refreshTokenExpiryTimestamp"),
    );

    // If no stored expiry timestamps, calculate new ones based on expiresIn
    if (!accessTokenExpiryTime) {
      accessTokenExpiryTime = currentTime + Number(accessTokenExpiresIn) * 1000;
      setTokenData(actorCode, {
        accessTokenExpiryTimestamp: String(accessTokenExpiryTime),
      });
    }
    if (!refreshTokenExpiryTime) {
      refreshTokenExpiryTime =
        currentTime + Number(refreshTokenExpiresIn) * 1000;

      setTokenData(actorCode, {
        refreshTokenExpiryTimestamp: String(refreshTokenExpiryTime),
      });
    }

    const remainingAccessTokenTime = accessTokenExpiryTime - currentTime;
    const remainingRefreshTokenTime = refreshTokenExpiryTime - currentTime;

    // If the access token is expired, try to refresh it
    if (remainingAccessTokenTime <= 0) {
      if (remainingRefreshTokenTime > 0) {
        refreshToken(
          signBookNumber,
          actorCode,
          tokenForRefresh ?? "",
          accessTokenExpiresIn ?? "",
          refreshTokenExpiresIn ?? "",
          setAccessToken,
          setTokenForRefresh,
          `/shared-api/v1/signbooks/${signBookNumber}/actors/${actorCode}/token/refresh`,
        );
      } else {
        // Both tokens are expired, re-authenticate
        handleSessionExpiration(actorCode);
        dispatch(showModal("restrictedAccess"));

        return;
      }
    } else {
      // Timeout to refresh the token when it is about to expire
      const tokenRefreshTimeout = setTimeout(() => {
        refreshToken(
          signBookNumber,
          actorCode,
          tokenForRefresh ?? "",
          accessTokenExpiresIn ?? "",
          refreshTokenExpiresIn ?? "",
          setAccessToken,
          setTokenForRefresh,
          `/shared-api/v1/signbooks/${signBookNumber}/actors/${actorCode}/token/refresh`,
        );
      }, remainingAccessTokenTime);

      return () => clearTimeout(tokenRefreshTimeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, tokenForRefresh]);

  useEffect(() => {
    if (!data?.authenticated) {
      return;
    }

    setAccessToken(getActorDataItem(actorCode, "accessToken"));
    setTokenForRefresh(getActorDataItem(actorCode, "refreshToken"));
    setAccessTokenExpiresIn(getActorDataItem(actorCode, "expiresIn"));
    setRefreshTokenExpiresIn(getActorDataItem(actorCode, "refreshExpiresIn"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (!accessToken) {
      return;
    }

    fetchUserData();
    fetchDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  const uploadFiles = (
    filesToStore: Document[] | Record<string, string>[],
    existingFiles: Document[] | Record<string, string>[] = documents,
  ) => {
    const newFiles = [...existingFiles, ...filesToStore] as Document[];

    setDocuments(newFiles);
  };

  const downloadFile = (documentId: string, documentName: string) => () => {
    axios
      .get(
        `/shared-api/v1/signbooks/${signBookNumber}/actors/${actorCode}/signbook/documents/download/${documentId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          responseType: "blob",
        },
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", documentName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      });
  };

  const deleteFile = (documentId: string, index: number, formik: any) => {
    setLoading(true);
    setDeletingIndices((prev) => [...prev, index]);

    axios
      .delete(
        `/shared-api/v1/signbooks/${signBookNumber}/actors/${actorCode}/signbook/documents/delete/${documentId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      )
      .then((response) => {
        const newDocuments = (documents as Document[]).filter(
          (_, i) => i !== index,
        );

        setDocuments(newDocuments);

        if (0 === newDocuments.length) {
          formik.setFieldValue("IDdocument", []);
        }
        setLoading(false);
        setDeletingIndices((prev) => prev.filter((i) => i !== index));
      })
      .catch((error) => {
        setDeletingIndices((prev) => prev.filter((i) => i !== index));
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      });
  };

  const fieldClass =
    "body-lg border border-[#B4B4B4] py-[4px] px-[16px] rounded-[4px] w-full";
  const fieldClassDisabled = fieldClass.replace(
    "border-[#B4B4B4]",
    "border-[#F5F5F5] bg-white",
  );
  const iconClass =
    "absolute top-1/2 -translate-y-2/4 right-[16px] size-[14px]";

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .trim(formatLabel(labels.requiredField, labels.name))
      .required(formatLabel(labels.requiredField, labels.name))
      .matches(nameRegex, formatLabel(labels.compliantField, labels.name)),
    lastName: Yup.string()
      .trim(formatLabel(labels.requiredField, labels.surname))
      .required(formatLabel(labels.requiredField, labels.surname))
      .matches(nameRegex, formatLabel(labels.compliantField, labels.surname)),
    birthDate: Yup.string()
      .trim(formatLabel(labels.requiredField, labels.birthDate))
      .required(formatLabel(labels.requiredField, labels.birthDate))
      .test(
        "is-past-date",
        labels.birthDateMustBeBeforeCurrentDate,
        isPastDate,
      ),
    nationality: Yup.string().required(
      formatLabel(labels.requiredField, labels.nationality),
    ),
    IDdocument: Yup.array().required(
      formatLabel(labels.requiredField, labels.addNewID),
    ),
    inPresence: Yup.boolean(),
  });

  return (
    <>
      <ToastContainer className="body-lg" />
      <Modals />
      <header className="relative z-[12] w-full h-[77px] flex justify-between border-b-[1px] px-[24px] lg:px-0 bg-white">
        <img
          srcSet="/assets/logo.svg"
          alt=""
          className="lg:block w-[102px] h-[60px] mt-[8px] xl:ml-[74px]"
        />
      </header>
      {notAuthorized ? null : (
        <div className="flex items-center justify-center">
          <div className="bg-white lg:w-[695px] mx-6 lg:mx-auto rounded-t-lg my-8 p-[40px] max-w-[417px]">
            <div className="relative flex-row">
              <h2 className="headline-lg pr-4 text-left">{labels.party}</h2>
              <p className="body-sm text-justify mt-[10px]">
                {labels.partySealed}
              </p>
              <Formik
                enableReinitialize
                initialValues={{
                  phone: phone,
                  phoneCode: phoneCode,
                  phoneCountryCode: phoneCountryCode,
                  email: userData?.email,
                  birthDate: userData?.birthDate,
                  nationality: userData?.nationality,
                  commonName: userData?.commonName,
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  const { phone, phoneCode, email } = values;
                  const formattedBirthDate = formatDateBirth(
                    new Date(values.birthDate),
                  );
                  const mobileNumber = `${phoneCode}${phone}`;
                  const {
                    mobileNumber: _,
                    email: __,
                    birthDate: ___,
                    ...restUserData
                  } = userData;
                  const data = {
                    mobileNumber,
                    email,
                    birthDate: formattedBirthDate,
                    ...restUserData,
                  };

                  axios
                    .put(
                      `/shared-api/v1/signbooks/${signBookNumber}/actors/${actorCode}/signbook/actors/${actorCode}/confirmation`,
                      data,
                      {
                        headers: {
                          Authorization: `Bearer ${accessToken}`,
                        },
                      },
                    )
                    .then(() => {
                      toast.success(
                        labels.documentSuccesfullyUpdated,
                        toastOptions,
                      );
                    })
                    .catch((error) => {
                      toast.error(
                        axiosErrorMessages[error.message],
                        toastOptionsError,
                      );
                    });
                }}
              >
                {(formik) => {
                  return (
                    <Form>
                      <div className="scrollbar-outside max-h-[660px] mt-8 mb-14">
                        <div className="party-type-dropdown relative">
                          <input
                            name="type"
                            type="hidden"
                            value=""
                            onChange={noop}
                          />
                          <FieldDropdown
                            arrowPosition="right"
                            initialValue="TYPE_NATURAL"
                            options={[
                              {
                                label: labels.physicalPerson,
                                value: "TYPE_NATURAL",
                              },
                              { label: labels.company, value: "TYPE_LEGAL" },
                            ]}
                            onChange={noop}
                            disabled={true}
                          />
                          <Icon
                            type="check"
                            className={iconClass}
                            color="#00C45A"
                          />
                        </div>
                        <div>
                          <p className="my-2">
                            <label
                              className="body-sm text-[#8F8F8F]"
                              htmlFor="lastName"
                            >
                              {labels.surname}
                            </label>
                          </p>
                          <div className="relative [&amp;>.react-datepicker-wrapper]:block">
                            <input
                              name="lastName"
                              id="lastName"
                              type="text"
                              className={fieldClassDisabled}
                              value={userData.lastName}
                              onChange={noop}
                            />
                            <Icon
                              type="check"
                              className={iconClass}
                              color="#00C45A"
                            />
                          </div>
                        </div>
                        <div>
                          <p className="my-2">
                            <label
                              className="body-sm text-[#8F8F8F]"
                              htmlFor="firstName"
                            >
                              {labels.name}
                            </label>
                          </p>
                          <div className="relative [&amp;>.react-datepicker-wrapper]:block">
                            <input
                              name="firstName"
                              id="firstName"
                              type="text"
                              className={fieldClassDisabled}
                              value={userData.firstName}
                              onChange={noop}
                            />
                            <Icon
                              type="check"
                              className={iconClass}
                              color="#00C45A"
                            />
                          </div>
                        </div>
                        <div>
                          <p className="my-2">
                            <label
                              className="body-sm text-[#8F8F8F]"
                              htmlFor="birthDate"
                            >
                              {labels.birthDate}
                            </label>
                          </p>
                          <div className="relative [&amp;>.react-datepicker-wrapper]:block">
                            <Field name="birthDate">
                              {({ field }: any) => (
                                <DatePicker
                                  {...field}
                                  renderCustomHeader={(props) => (
                                    <CustomDatePickerHeader {...props} />
                                  )}
                                  id="birthDate"
                                  className={`${fieldClass} bg-white w-full`}
                                  onChange={(date) => {
                                    if (date) {
                                      const formattedDate = new Date(
                                        date,
                                      ).toLocaleDateString("fr-FR");
                                      formik.setFieldValue(
                                        "birthDate",
                                        formattedDate,
                                      );
                                    }
                                  }}
                                  dateFormat={"dd/MM/yyyy"}
                                />
                              )}
                            </Field>

                            <ErrorMessage name="birthDate">
                              {(msg) => (
                                <span className="text-[10px] max-w-[293px] text-ea-red">
                                  {msg}
                                </span>
                              )}
                            </ErrorMessage>
                          </div>
                        </div>
                        <div>
                          <p className="my-2">
                            <label
                              className="body-sm text-[#8F8F8F]"
                              htmlFor="nationality"
                            >
                              {labels.nationality}
                            </label>
                          </p>
                          <div className="relative">
                            <Field
                              name="nationality"
                              id="nationality"
                              type="text"
                              className={fieldClass}
                            />
                          </div>
                          <ErrorMessage name="nationality">
                            {(msg) => (
                              <span className="text-[10px] max-w-[293px] text-ea-red">
                                {msg}
                              </span>
                            )}
                          </ErrorMessage>
                        </div>
                        {/* if there is a value in commonName show it */}
                        {userData.commonName && (
                          <div>
                            <p className="my-2">
                              <label
                                className="body-sm text-[#8F8F8F]"
                                htmlFor="commonName"
                              >
                                {labels.commonName}
                              </label>
                            </p>
                            <div className="relative">
                              <Field
                                name="commonName"
                                id="commonName"
                                type="text"
                                className={fieldClass}
                              />
                            </div>
                            <ErrorMessage name="commonName">
                              {(msg) => (
                                <span className="text-[10px] max-w-[293px] text-ea-red">
                                  {msg}
                                </span>
                              )}
                            </ErrorMessage>
                          </div>
                        )}
                        <div>
                          <p className="my-2">
                            <label
                              className="body-sm text-[#8F8F8F]"
                              htmlFor="email"
                            >
                              {labels.email}
                            </label>
                          </p>
                          <div className="relative">
                            <Field
                              name="email"
                              id="email"
                              type="text"
                              className={fieldClass}
                            />
                          </div>
                          <ErrorMessage name="email">
                            {(msg) => (
                              <span className="text-[10px] max-w-[293px] text-ea-red">
                                {msg}
                              </span>
                            )}
                          </ErrorMessage>
                        </div>
                        <p className="my-2">
                          <label
                            className="body-sm text-[#8F8F8F]"
                            htmlFor="phone"
                          >
                            {labels.phone}
                          </label>
                        </p>
                        <PhoneField
                          setFieldValue={formik.setFieldValue}
                          inversedDropdown={true}
                        />
                        <p className="my-2">
                          <label className="body-sm text-[#8F8F8F]">
                            {labels.addNewID}
                          </label>
                        </p>
                        <div className="relative my-3">
                          <label className="flex relative body-md text-center items-center custom-checkbox-container">
                            <Field
                              type="checkbox"
                              name="inPresence"
                              id="inPresence"
                              className="custom-checkbox"
                            />
                            {labels.signatureInPresence}
                          </label>
                        </div>
                        <div className="modal-file-upload">
                          <FileUpload
                            files={documents}
                            setFiles={uploadFiles}
                            minimized={true}
                            url={`/shared-api/v1/signbooks/${signBookNumber}/actors/${actorCode}/signbook/identification-documents/upload`}
                            acceptedFileTypes={{
                              "image/jpeg": [],
                              "image/png": [],
                              "image/bmp": [],
                              "application/pdf": [],
                            }}
                            onChange={(files: Document[]) => {
                              setNumberFilesUploaded(files.length);

                              const IDfiles =
                                files.length !== 0
                                  ? files.map((file) => {
                                      const { name } = file;
                                      return { name };
                                    })
                                  : [];

                              formik.setFieldValue("IDdocument", IDfiles);
                              setDocuments(IDfiles);
                            }}
                            setLoading={setLoading}
                            accessToken={accessToken || ""}
                          />
                          {!initializing ? (
                            <div className="max-h-[122px] mt-[10px] space-y-[10px] overflow-auto scrollbar-outside">
                              {documents.map((document, index) => {
                                const isDeleting =
                                  deletingIndices.includes(index);
                                if (isDeleting) {
                                  return <Loader style={{ height: "30px" }} />;
                                }
                                return (
                                  <div
                                    className="flex items-center py-1 px-2 border border-black border-dashed rounded-md h-[34px] w-full mx-auto relative"
                                    key={index}
                                  >
                                    <span className="body-sm truncate max-w-[165px]">
                                      {document.name}
                                    </span>
                                    <div className="absolute top-0 right-0 px-2 h-full flex justify-center items-center">
                                      <a
                                        className="mr-5"
                                        href={addQueryParams(
                                          "/signatory-confirmation/document-preview",
                                          {
                                            documentId: document.id,
                                            actorCode: actorCode,
                                            signBookNumber: signBookNumber,
                                            returnPath: `/signatory-confirmation?signbookNumber=${signBookNumber}&actorCode=${actorCode}`,
                                            documentName: document.name,
                                          },
                                        )}
                                      >
                                        <Icon
                                          type="preview"
                                          className="size-[18px]"
                                        />
                                      </a>
                                      <Button
                                        type="button"
                                        className="mr-5"
                                        onClick={downloadFile(
                                          document.id,
                                          document.name,
                                        )}
                                      >
                                        <Icon
                                          type="download"
                                          className="size-[18px]"
                                        />
                                      </Button>
                                      <Button
                                        type="button"
                                        onClick={() => {
                                          deleteFile(
                                            document.id,
                                            index,
                                            formik,
                                          );
                                        }}
                                      >
                                        <Icon
                                          type="trash"
                                          className="size-[18px]"
                                        />
                                      </Button>
                                    </div>
                                  </div>
                                );
                              })}
                              {/* Display as much loaders as number of uploaded files */}
                              {loading &&
                                deletingIndices.length === 0 &&
                                [...Array(numberFilesUploaded)].map(
                                  (_, index) => (
                                    <Loader
                                      key={`loading-${index}`}
                                      style={{ height: "30px" }}
                                    />
                                  ),
                                )}
                            </div>
                          ) : (
                            <Loader style={{ height: "30px" }} />
                          )}
                        </div>
                      </div>
                      <div className="flex justify-end">
                        <Button
                          className="btn btn-secondary-emphasize flex justify-end items-center rounded-[8px] px-[21px] py-[8px] body-md h-[34px]"
                          type="submit"
                        >
                          {labels.validate}
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SignatoryConfirmation;
