import { Field } from "formik";

import { labels } from "@constants";

const NotificationSetup = ({ formik }: { formik: any }) => {
  return (
    <div className="headline-lg mr-auto ml-auto">
      <section className="lg:w-[612px] mr-auto ml-auto">
        <h2 className="mb-[24px]">{labels.notificationMessageTitle}</h2>
        <div className="flex flex-row flex-wrap gap-x-[24px] mb-[24px]">
          <div className="flex flex-col w-full md:w-[293px] mb-6 md:mb-0">
            <label htmlFor="greeting" className="body-lg mb-[10px]">
              {labels.greetingEmail}
            </label>
            <Field
              type="text"
              id="greeting"
              name="greeting"
              className="input md:w-[293px] border border-[#B4B4B4] rounded body-lg h-[34px] p-2"
              placeholder=""
              value={formik.values.greeting}
              onChange={formik.handleChange}
            />
          </div>
          <div className="flex flex-col w-full md:w-[293px] ">
            <label htmlFor="signature" className="body-lg mb-[10px] ">
              {labels.signatureEmail}
            </label>
            <Field
              type="text"
              id="signature"
              name="signature"
              className="input md:w-[293px] border border-[#B4B4B4] rounded body-lg h-[34px] p-2"
              placeholder=""
              value={formik.values.signature}
              onChange={formik.handleChange}
            />
          </div>
        </div>
      </section>
      <div />
    </div>
  );
};

export default NotificationSetup;
