import { configureStore } from "@reduxjs/toolkit";

import actCreationReducer from "@reducers/actCreationSlice";
import dataTransferSlice from "@reducers/dataTransferSlice";
import eafSlice from "@reducers/eafSlice";
import localeReducer from "@reducers/localeSlice";
import metadataSlice from "@reducers/metadataSlice";
import modalsReducer from "@reducers/modalsSlice";
import overlayReducer from "@reducers/overlaySlice";
import partyFormReducer from "@reducers/partyFormSlice";
import signatureReducer from "@reducers/signatureSlice";
import tracesReducer from "@reducers/tracesSlice";
import validationReducer from "@reducers/validationSlice";

const store = configureStore({
  reducer: {
    modals: modalsReducer,
    locale: localeReducer,
    eaf: eafSlice,
    overlay: overlayReducer,
    signature: signatureReducer,
    validation: validationReducer,
    partyForm: partyFormReducer,
    actCreation: actCreationReducer,
    dataTransfer: dataTransferSlice,
    metadata: metadataSlice,
    traces: tracesReducer,
  },
});

export default store;
