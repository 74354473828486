import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import Button from "@ui/Button";
import Modal from "@ui/Modal";
import Spinner from "@ui/Spinner";

import { axiosErrorMessages, labels, toastOptionsError } from "@constants";

import { selectModalData } from "@reducers/dataTransferSlice";

import { addQueryParams, removeBaseUrl } from "@utils";

const WaitingForSignature = () => {
  const [lawyerName, setLawyerName] = useState("");
  const [loading, setLoading] = useState(false);

  const data = useSelector(selectModalData);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const signBookNumber = queryParams.get("signBookNumber") || "";

  useEffect(() => {
    if (!data) {
      return;
    }
    setLawyerName(data.lawyerName);
  }, [data]);

  const handleReload = () => {
    setLoading(true);

    axios
      .get(`/api/v1/signbooks/${signBookNumber}/signings/order`)
      .then((response) => {
        const { nextActor, redirectURI } = response.data;

        if (nextActor?.order === 4 && redirectURI) {
          const newUrl = removeBaseUrl(redirectURI);

          document.location.href = addQueryParams(newUrl, {
            returnPath: "/acts/divorce",
          });

          return;
        }

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      });
  };

  return (
    <Modal title={labels.waitingForSignature} width={466}>
      <p className="body-sm my-3">
        Me {lawyerName}
        {labels.signOnAnotherDeviceText}
      </p>
      <div className="flex justify-end mt-6">
        <Button
          className="btn-base btn-primary flex justify-center items-center body-md h-[34px]"
          type="button"
          onClick={handleReload}
          disabled={loading || "" === signBookNumber}
        >
          {loading && <Spinner className="size-4 mr-4" />}
          {labels.reload}
        </Button>
      </div>
    </Modal>
  );
};

export default WaitingForSignature;
