import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import Icon from "@ui/Icon";

import {
  selectLastReachedStep,
  selectValidatedData,
} from "@reducers/actCreationSlice";

import { BreadcrumbsProps } from "@types";

import { getStepFromPathMap } from "@utils";

function Breadcrumb({
  pathMap,
  formikState,
  setFormikState,
}: BreadcrumbsProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const { actType } = useParams();
  const validatedData = useSelector(selectValidatedData);
  const lastReachedStep = useSelector(selectLastReachedStep);

  const validateStep = (step: number) => {
    const splitPath = location.pathname.split("/");
    const locationPath = splitPath[splitPath.length - 1];
    const currentStep = getStepFromPathMap(locationPath, pathMap);
    const isStepCompleted = !isNaN(currentStep) && currentStep >= step;
    const pathMapUrl = pathMap[step].url;
    if (!pathMapUrl) {
      return false;
    }

    if (
      !validatedData &&
      step !== currentStep &&
      !isStepCompleted &&
      lastReachedStep !== "documents"
    ) {
      return false;
    }

    if (
      lastReachedStep === "informations" &&
      (currentStep === 1 || currentStep === 2) &&
      step > 2
    ) {
      return false;
    }

    if (
      (currentStep === 1 || currentStep === 2) &&
      lastReachedStep === "signatories" &&
      step > 3
    ) {
      return false;
    }

    if (lastReachedStep === "signatories" && step > 3) {
      return false;
    }

    if (lastReachedStep === "documents" && !validatedData && step > 3) {
      return false;
    }

    return true;
  };

  const setCursorStyle = (step: number) => {
    return validateStep(step) ? "cursor-pointer" : "cursor-not-allowed";
  };

  const setLink = (step: number) => {
    return validateStep(step) ? `/acts/${actType}${pathMap[step].url}` : "#";
  };

  const activeTextStyle = (step: number) => {
    return validateStep(step)
      ? "underline text-ea-red text-[12px] w-[95px] font-bold"
      : "no-underline text-black body-sm w-[95px]";
  };

  const activeCircleStyle = (step: number) => {
    return validateStep(step)
      ? "bg-ea-red text-white text-[20px]"
      : "bg-ea-gray-100";
  };

  const finishedIcon = (step: number) => {
    const splitPath = location.pathname.split("/");
    const locationPath = splitPath[splitPath.length - 1];
    const currentStep = getStepFromPathMap(locationPath, pathMap);
    const result = validateStep(step);

    const isSignatoriesPassed =
      lastReachedStep === "signatories" &&
      ((currentStep === 1 && step === 3) ||
        (currentStep === 2 && step === 3) ||
        (currentStep === 3 && step === 4));

    if (result && currentStep === 1 && lastReachedStep === "informations") {
      return step;
    }

    if (
      result &&
      ((currentStep === 1 && lastReachedStep === "informations") ||
        isSignatoriesPassed)
    ) {
      return step;
    }

    if (result && currentStep !== step) {
      return <Icon type="check" color="#fff" className="w-[16px] h-[12px]" />;
    } else {
      return step;
    }
  };

  const lineColor = (step: number) => {
    return validateStep(step) ? "bg-ea-red" : "bg-ea-gray-100";
  };

  return (
    <nav className="flex">
      {Object.keys(pathMap).map((key) => {
        const step = parseInt(key);
        return (
          <div key={step} className="flex items-center">
            <Link
              onClick={(event) => {
                if (!validateStep(step)) {
                  event.preventDefault();
                  return;
                }

                // Update information if we're not clicking on step 1
                if (
                  formikState?.isDirty &&
                  1 === formikState.step &&
                  step !== 1
                ) {
                  event.preventDefault();

                  if (setFormikState) {
                    setFormikState({
                      formik: null,
                      isDirty: false,
                      callback: null,
                      step: 0,
                    });
                  }

                  if (formikState.callback) {
                    const result = formikState.callback();

                    if (
                      result &&
                      typeof result.then === "function" &&
                      result.callback
                    ) {
                      result.callback().then(() => {
                        navigate(setLink(step), { replace: true });
                      });

                      return;
                    }
                  }
                }
              }}
              to={setLink(step)}
              className={`flex flex-col items-center w-[70px] text-center ${setCursorStyle(
                step,
              )}`}
            >
              <span
                className={`rounded-full text-[12px] size-[38px] flex items-center justify-center mb-1 ${activeCircleStyle(
                  step,
                )}`}
              >
                {typeof finishedIcon(step) === "number" ? (
                  <span className="mb-[5px]">{finishedIcon(step)}</span>
                ) : (
                  finishedIcon(step)
                )}
              </span>

              <p className={`${activeTextStyle(step)}`}>
                {pathMap[step].label}
              </p>
            </Link>

            {step < Object.keys(pathMap).length && (
              <div className="flex mt-[-20px] mx-[4px]">
                <span className={`w-[36px] h-[5px] ${lineColor(step)}`}></span>
                <span
                  className={`w-[36px] h-[5px] ${lineColor(step + 1)}`}
                ></span>
              </div>
            )}
          </div>
        );
      })}
    </nav>
  );
}

export default Breadcrumb;
