import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import Button from "@ui/Button";
import Icon from "@ui/Icon";
import Modal from "@ui/Modal";
import Spinner from "@ui/Spinner";

import {
  axiosErrorMessages,
  labels,
  logos,
  toastOptions,
  toastOptionsError,
} from "@constants";

import { initEaf } from "@eaf";

import { selectEafOn, selectTokens } from "@reducers/eafSlice";
import { selectUser } from "@reducers/metadataSlice";

import { BrowserInfo, OSInfo } from "@types";

import { filterTokens } from "@utils";

const Diagnostics = () => {
  const eafOn = useSelector(selectEafOn);
  const tokens = useSelector(selectTokens);
  const user = useSelector(selectUser);

  const [loading, setLoading] = useState<boolean>(true);
  const [testLoading, setTestLoading] = useState<boolean>(false);
  const [eafRunning, setEafRunning] = useState<boolean>(false);

  const [selectedToken, setSelectedToken] = useState<null | number>(null);
  const [certificateNumber, setCertificateNumber] = useState("");
  const keyRPVA = tokens.length > 0;

  useEffect(() => {
    if (tokens.length !== 0) {
      setSelectedToken(0);
      setCertificateNumber(tokens[0].certificate.json.serialNumber);
    }
  }, [tokens]);

  const determineTextColor = (isSuccess: boolean) => {
    return isSuccess ? "text-black" : "text-[#E40000]";
  };

  const textColorSignatureSoftware = determineTextColor(eafOn);
  const textColorDiagnosticEBEE = determineTextColor(eafRunning);
  const textColorKeyRPVA = determineTextColor(keyRPVA);

  const [osInfo, setOSInfo] = useState<OSInfo>({
    name: "Unknown",
    version: "Unknown",
  });
  const [browserVersion, setBrowserVersion] = useState<BrowserInfo>({
    name: "Unknown",
    version: "Unknown",
  });
  const [osLogo, setOsLogo] = useState<string>("");
  const [browserLogo, setBrowserLogo] = useState<string>("");

  const signatureSoftwareStatus = eafOn
    ? labels.installed
    : labels.notInstalled;
  const diagnosticEBEEStatus = eafRunning ? labels.ok : labels.notStarted;
  const keyRPVAStatus = keyRPVA ? labels.ok : labels.notConnected;
  //Icon used for verification of the status of the signature software, diagnosticEBEE , keyRPVA, Os and Browser
  const checked = (
    <Icon type="check" className="w-[15px] h-[11px] ml-2" color="green" />
  );
  const unchecked = (
    <Icon type="close" className="size-[13px] ml-2" color="red" />
  );

  useEffect(() => {
    const userAgent = navigator.userAgent;

    // Detect browser
    let browserName;
    let browserVersion;

    if (userAgent.includes("Edg")) {
      browserName = labels.edgeBrowser;
      browserVersion = userAgent.match(/Edg\/([0-9.]+)/)![1];
      setBrowserLogo(logos.edge);
    } else if (userAgent.includes("Firefox")) {
      browserName = labels.fireFoxBrowser;
      browserVersion = userAgent.match(/Firefox\/([0-9.]+)/)![1];
      setBrowserLogo(logos.fireFox);
    } else if (userAgent.includes("MSIE") || userAgent.includes("Trident/")) {
      browserName = labels.internetExplorerBrowser;
      browserVersion = userAgent.match(/(?:MSIE |rv:)(\d+(\.\d+)?)/)![1];
    } else if (userAgent.includes("Chrome")) {
      browserName = labels.chromeBrowser;
      browserVersion = userAgent.match(/Chrome\/([0-9.]+)/)![1];
      setBrowserLogo(logos.chrome);
    } else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
      browserName = labels.operaBrowser;
      browserVersion = userAgent.match(/(?:Opera|OPR)\/([0-9.]+)/)![1];
    } else if (userAgent.includes("Safari")) {
      browserName = "Safari";
      browserVersion = userAgent.match(/Version\/([0-9.]+)/)![1];
      setBrowserLogo(logos.safari);
    } else {
      browserName = labels.browserNotSupported;
      browserVersion = "";
    }

    setBrowserVersion({ name: browserName, version: browserVersion });

    // Detect OS
    let osName;
    let osVersion;

    if (userAgent.includes("Windows NT 10.0")) {
      osName = labels.windowsOs;
      if (userAgent.match(/Windows NT\s([\d.]+)/)![1] < "10.0") {
        osVersion = userAgent.match(/Windows NT\s([\d.]+)/)![1];
      } else {
        osVersion = "10 +";
      }

      setOsLogo(logos.windows);
    } else if (userAgent.includes("Mac OS X")) {
      osName = labels.macOs;
      if (userAgent.match(/OS X\s([\d_]+)/) === null) {
        osVersion = userAgent
          .match(/iPhone OS\s([\d_]+)/)![1]
          .replace(/_/g, ".");
      } else {
        osVersion = userAgent.match(/OS X\s([\d_]+)/)![1].replace(/_/g, ".");
      }

      setOsLogo(logos.apple);
    } else {
      osName = labels.osNotSupported;
      osVersion = "";
    }

    setOSInfo({ name: osName, version: osVersion });

    axios
      .get("https://127.0.0.1:5443/")
      .then(() => {
        setLoading(false);
        setEafRunning(true);
      })
      .catch(() => {
        setLoading(false);
        setEafRunning(false);
      });
  }, []);

  const OsStatusIcon = () => {
    if (osInfo?.name === "Windows") {
      return osInfo?.version === "10 +" ? checked : unchecked;
    } else if (osInfo?.name === "Mac OS X") {
      return osInfo?.version >= "12" ? checked : unchecked;
    } else {
      return unchecked;
    }
  };

  const BrowserStatusIcon = () => {
    if (
      browserVersion?.name === "Google Chrome" ||
      browserVersion?.name === "Mozilla Firefox" ||
      browserVersion?.name === "Microsoft Edge" ||
      browserVersion?.name === "Safari" ||
      browserVersion?.name === "Opera"
    ) {
      const versionNumber = parseInt(browserVersion?.version);
      if (!isNaN(versionNumber)) {
        if (
          (browserVersion?.name === "Google Chrome" ||
            browserVersion?.name === "Mozilla Firefox" ||
            browserVersion?.name === "Microsoft Edge") &&
          versionNumber >= 90
        ) {
          return checked;
        } else if (browserVersion?.name === "Safari" && versionNumber > 12) {
          return checked;
        } else if (browserVersion?.name === "Opera" && versionNumber > 72) {
          return checked;
        } else {
          return unchecked;
        }
      }
    }
    return unchecked;
  };

  const StateDiagnosticCheck = ({ stateCheck }: { stateCheck: boolean }) => {
    return stateCheck ? checked : unchecked;
  };

  return (
    <Modal
      title={labels.diagnostic}
      width={460}
      paddingClass="px-[44px] py-[32px]"
    >
      <div className="mt-[20px]">
        <div className="flex justify-between w-full my-[20px] body-lg mb-[24px]">
          <div className="flex justify-start">{labels.operatingSystem}</div>
          <div className="flex justify-end">
            <div className="flex justify-between items-center">
              <img src={osLogo} alt="" className="mr-1 size-[24px]" />
              {`${osInfo.name} ${osInfo.version}`}
              <OsStatusIcon />
            </div>
          </div>
        </div>
        <div className="mb-[24px]">
          <div className="flex justify-between w-full body-lg mb-4">
            <div className="flex justify-start">{labels.browser}</div>
            <div className="flex justify-end items-center">
              <img src={browserLogo} alt="" className="flex mr-1 size-[24px]" />
              {`${browserVersion.name} ${browserVersion.version}`}
              <BrowserStatusIcon />
            </div>
          </div>
          <p className="body-sm text-ea-gray-300">
            {labels.diagnosticsSupportedBrowsers}
          </p>
        </div>
        <div className="flex justify-between w-full mb-[24px] body-lg">
          <div className="flex justify-start">{labels.signatureSoftware}</div>
          <div className="flex justify-end">
            <div className={`flex items-center ${textColorSignatureSoftware}`}>
              {signatureSoftwareStatus}
              <StateDiagnosticCheck stateCheck={eafOn} />
            </div>
          </div>
        </div>
        <div className="flex justify-between w-full mb-[24px] body-lg">
          <div className="flex justify-start">{labels.diagnosticEBEE}</div>
          <div className="flex justify-end">
            <div className={`flex items-center ${textColorDiagnosticEBEE}`}>
              {loading && <Spinner className="size-4" dark={true} />}
              {!loading && (
                <>
                  {diagnosticEBEEStatus}
                  <StateDiagnosticCheck stateCheck={eafRunning} />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between w-full mb-[10px] body-lg">
          <div className="flex justify-start">{labels.keyRPVA}</div>
          <div className="flex justify-end">
            <div className={`flex items-center ${textColorKeyRPVA}`}>
              {keyRPVAStatus}
              <StateDiagnosticCheck stateCheck={keyRPVA} />
            </div>
          </div>
        </div>
        <div className="ml-[9px] mb-[24px]">
          <a
            href="https://www.certeurope.fr/fichiers/certeurope/tkm/CertEurope_TKM_Suite_2.0.0_Installer.exe"
            target="_blank"
            rel="noreferrer"
            className="flex items-center mb-[10px]"
          >
            <Icon type="download" />
            <p className="body-md font-bold ml-[10px]">
              {labels.downloadTrustKeyManager}
            </p>
          </a>
          <a
            href="https://pki.almerys.com/download/InstallG2SeBeeAccessFoundationWin.exe"
            target="_blank"
            rel="noreferrer"
            className="flex items-center mb-[10px]"
          >
            <Icon type="download" />
            <p className="body-md font-bold ml-[10px]">
              {labels.downloadEbeeAccessWindows}
            </p>
          </a>
          <a
            href="https://pki.almerys.com/download/InstallG2SeBeeAccessFoundationMAC.pkg"
            target="_blank"
            rel="noreferrer"
            className="flex items-center mb-[10px]"
          >
            <Icon type="download" />
            <p className="body-md font-bold ml-[10px]">
              {labels.downloadEbeeAccessMac}
            </p>
          </a>
          <a
            href="/"
            target="_blank"
            rel="noreferrer"
            className="flex items-center"
          >
            <Icon type="download" />
            <p className="body-md font-bold ml-[10px]">
              {labels.downloadEbeeAccessLinux}
            </p>
          </a>
        </div>
        {certificateNumber && (
          <div className="flex justify-between w-full body-lg items-center text-center">
            <div className="flex items-center justify-start">
              {labels.certificate}
              {tokens.length > 1 ? (
                <div className="flex items-center justify-center">
                  <select
                    className="focus:outline-0"
                    value={selectedToken?.toString()}
                    onChange={(event) => {
                      const selectedOption = tokens.find(
                        (_, index) => index === Number(event.target.value),
                      );

                      if (selectedOption) {
                        setSelectedToken(Number(event.target.value));
                        setCertificateNumber(
                          selectedOption.certificate.json.serialNumber,
                        );
                      }
                    }}
                  >
                    {tokens.map((token, index) => {
                      return (
                        <option value={index} key={index}>
                          {token.certificate.json.serialNumber}
                        </option>
                      );
                    })}
                  </select>
                </div>
              ) : (
                <div className="ml-1">{certificateNumber}</div>
              )}
            </div>
            <Button
              className="flex items-center justify-center btn-secondary rounded-lg px-2 py-1 h-[34px]"
              disabled={testLoading}
              onClick={() => {
                setTestLoading(true);

                axios
                  .get("/api/v1/signature-test")
                  .then((response) => {
                    const { documentHash } = response.data;
                    const { numCNBF } = user;

                    if (documentHash && numCNBF) {
                      const token = filterTokens(tokens, numCNBF);

                      const eaf = initEaf();

                      eaf.sign(documentHash, token);

                      eaf
                        .onSignSuccess((result: Record<string, any>) => {
                          const { encryptedData, token } = result;

                          if (encryptedData && token?.certificate?.original) {
                            const data = new URLSearchParams();
                            data.append("cert", token.certificate.original);

                            encryptedData.forEach((hash: string) => {
                              data.append(`encrypted[]`, hash);
                            });

                            axios
                              .post("/api/v1/decrypt", data, {
                                headers: {
                                  "Content-Type":
                                    "application/x-www-form-urlencoded",
                                },
                              })
                              .then((response) => {
                                setTestLoading(false);

                                const documentHashToCheck =
                                  response.data?.result;

                                if (documentHashToCheck === documentHash) {
                                  toast.success(
                                    labels.signatureTestSuccess,
                                    toastOptions,
                                  );

                                  return;
                                }

                                toast.error(
                                  labels.signatureTestNotSuccess,
                                  toastOptionsError,
                                );
                              })
                              .catch((error) => {
                                toast.error(
                                  labels.signatureTestNotSuccess,
                                  toastOptionsError,
                                );

                                setTestLoading(false);
                              });
                          }
                        })
                        .onSignError(() => {
                          toast.error(labels.signingError, toastOptionsError);

                          setTestLoading(false);
                        })
                        .onSignCancel(() => {
                          toast.error(
                            labels.signingCancelled,
                            toastOptionsError,
                          );

                          setTestLoading(false);
                        });

                      return;
                    }

                    setTestLoading(false);
                  })
                  .catch((error) => {
                    setTestLoading(false);
                    toast.error(
                      axiosErrorMessages[error.message],
                      toastOptionsError,
                    );
                  });
              }}
            >
              {testLoading && <Spinner className="size-4 mr-4" dark={true} />}
              {labels.test}
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default Diagnostics;
