import axios from "axios";
import { Field, Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { Document, Page } from "react-pdf";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

import Button from "@ui/Button";
import Icon from "@ui/Icon";
import ProgressBar from "@ui/ProgressBar";
import Spinner from "@ui/Spinner";

import { axiosErrorMessages, labels, toastOptionsError } from "@constants";

import useIsMobile from "@hooks/useIsMobile";
import useOrientation from "@hooks/useOrientation";

import Modals from "@modals/Modals";

import {
  selectModalData,
  selectPageData,
  setModalData,
  setPageData,
} from "@reducers/dataTransferSlice";
import { showModal } from "@reducers/modalsSlice";
import {
  selectSignedDocument,
  setSignedDocument,
} from "@reducers/validationSlice";

import {
  downloadFile,
  downloadPDF,
  getActorDataItem,
  handleSessionExpiration,
  mergeAndPrint,
  printFile,
  refreshToken,
  setTokenData,
} from "@utils";

const DivorceSigning = () => {
  const [pdf, setPdf] = useState<number>(0);
  const [mimeType, setMimeType] = useState<string>("application/pdf");
  const [fileUrl, setFileUrl] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [numPagesMap, setNumPagesMap] = useState<Record<string, number>>({});
  const [zoom, setZoom] = useState<number>(1);
  const [hasError, setHasError] = useState(false);
  const documentRef = useRef(null);
  const pdfContainerRef = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const url = location.pathname;
  const actType = url.split("/")[2];
  const queryParams = new URLSearchParams(location.search);
  const documentId: string | null = queryParams.get("documentId");
  const actId = queryParams.get("actId") || "";
  const mustReadAllDocs = queryParams.get("mustReadAllDocs") ?? "";
  const mustReadAll = mustReadAllDocs === "true";
  const processNumber = queryParams.get("processNumber") ?? "";
  const actorCode = queryParams.get("actorCode") ?? "";
  const signBookNumber = queryParams.get("signBookNumber") ?? "";
  const signBookName = queryParams.get("signBookName") ?? "";
  const [hasReadAll, setHasReadAll] = useState<boolean>(
    mustReadAllDocs !== "true",
  );
  // controls if the footer buttons for signing are displayed
  const signedDocumentStatus = useSelector(selectSignedDocument);

  const actTitle = queryParams.get("actTitle") || "";
  const data = useSelector(selectModalData);
  const [readAllDocumentsChecked, setReadAllDocumentsChecked] = useState(false);
  const [acceptedCondition, setAcceptedCondition] = useState(false);
  const [pdfUrls, setPdfUrls] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [pdfUids, setPdfUids] = useState<string[]>([]);
  const [loadedPdfUids, setLoadedPdfUids] = useState<string[]>([]);
  const [documents, setDocuments] = useState([]);
  const isPortrait = useOrientation();
  const isMobile = useIsMobile();
  const pageData = useSelector(selectPageData);
  const [accessToken, setAccessToken] = useState<string | null>(
    getActorDataItem(actorCode, "accessToken"),
  );
  const [tokenForRefresh, setTokenForRefresh] = useState<string | null>(
    getActorDataItem(actorCode, "refreshToken"),
  );
  const [accessTokenExpiresIn, setAccessTokenExpiresIn] = useState(
    getActorDataItem(actorCode, "expiresIn"),
  );
  const [refreshTokenExpiresIn, setRefreshTokenExpiresIn] = useState(
    getActorDataItem(actorCode, "refreshToken"),
  );
  const notAuthorized = !accessToken as boolean;
  const [documentsSignedByAllMembers, setDocumentsSignedByAllMembers] =
    useState(false);
  const [order, setOrder] = useState<string>("");
  const [signatoryName, setSignatoryName] = useState<string>("");

  useEffect(() => {
    if (!accessToken) {
      return;
    }

    const currentTime = Date.now();

    // Get expiration timestamps from localStorage if they exist
    let accessTokenExpiryTime = Number(
      getActorDataItem(actorCode, "accessTokenExpiryTimestamp"),
    );
    let refreshTokenExpiryTime = Number(
      getActorDataItem(actorCode, "refreshTokenExpiryTimestamp"),
    );

    // If no stored expiry timestamps, calculate new ones based on expiresIn
    if (!accessTokenExpiryTime) {
      accessTokenExpiryTime = currentTime + Number(accessTokenExpiresIn) * 1000;
      setTokenData(actorCode, {
        accessTokenExpiryTimestamp: String(accessTokenExpiryTime),
      });
    }
    if (!refreshTokenExpiryTime) {
      refreshTokenExpiryTime =
        currentTime + Number(refreshTokenExpiresIn) * 1000;
      setTokenData(actorCode, {
        refreshTokenExpiryTimestamp: String(refreshTokenExpiryTime),
      });
    }

    const remainingAccessTokenTime = accessTokenExpiryTime - currentTime;
    const remainingRefreshTokenTime = refreshTokenExpiryTime - currentTime;

    // If the access token is expired, try to refresh it
    if (remainingAccessTokenTime <= 0) {
      if (remainingRefreshTokenTime > 0) {
        refreshToken(
          processNumber,
          actorCode,
          tokenForRefresh ?? "",
          accessTokenExpiresIn ?? "",
          refreshTokenExpiresIn ?? "",
          setAccessToken,
          setTokenForRefresh,
          `/shared-api/v1/process/${processNumber}/actors/${actorCode}/token/refresh`,
        );
      } else {
        // Both tokens are expired, re-authenticate
        handleSessionExpiration(actorCode);
        dispatch(showModal("restrictedAccess"));
        return;
      }
    } else {
      // Timeout to refresh the token when it is about to expire
      const tokenRefreshTimeout = setTimeout(() => {
        refreshToken(
          processNumber,
          actorCode,
          tokenForRefresh ?? "",
          accessTokenExpiresIn ?? "",
          refreshTokenExpiresIn ?? "",
          setAccessToken,
          setTokenForRefresh,
          `/shared-api/v1/process/${processNumber}/actors/${actorCode}/token/refresh`,
        );
      }, remainingAccessTokenTime);

      return () => clearTimeout(tokenRefreshTimeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, tokenForRefresh]);

  useEffect(() => {
    if (!data?.authenticated) {
      return;
    }

    setAccessToken(getActorDataItem(actorCode, "accessToken"));
    setTokenForRefresh(getActorDataItem(actorCode, "refreshToken"));
    setAccessTokenExpiresIn(getActorDataItem(actorCode, "expiresIn"));
    setRefreshTokenExpiresIn(getActorDataItem(actorCode, "refreshExpiresIn"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (actType === "divorce") {
      getOrder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPdfData = async (documentId: string) => {
    if (loadedPdfUids.includes(documentId) || pdfUids === loadedPdfUids) {
      return;
    }

    if (documents.length === 0) {
      return;
    }

    setLoading(true);

    const documentToSend = documents.find(
      (document: any) => document.documentUid === documentId,
    );

    try {
      const response = await axios.post(
        `/shared-api/v1/process/${processNumber}/actors/${actorCode}/signbooks/${signBookNumber}/main-documents`,
        documentToSend,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          responseType: "arraybuffer",
        },
      );

      const contentType = response.headers["content-type"];
      setMimeType(contentType);
      const fileBlob = new Blob([response.data], { type: contentType });
      const fileUrl = URL.createObjectURL(fileBlob);

      if (mimeType !== "application/pdf") {
        setFileUrl(fileUrl);
        setLoading(false);
        return;
      } else {
        setPdfUrls([...pdfUrls, fileUrl]);
      }
      dispatch(
        setPageData({ ...pageData, pdfForDownload: [...pdfUrls, fileUrl] }),
      );
      if (contentType === "application/pdf") {
        setLoadedPdfUids([...loadedPdfUids, documentId]);
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(axiosErrorMessages[error.message], toastOptionsError);
    }
  };

  const getOrder = () => {
    axios
      .get(`/api/v1/signbooks/${signBookNumber}/signings/order`)
      .then((response) => {
        const orderData = response.data;
        setOrder(orderData.nextActor.order);
        setSignatoryName(
          `${orderData.nextActor.lastName} ${orderData.nextActor.firstName}`,
        );
      })
      .catch((error) => {
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      });
  };

  useEffect(() => {
    const fetchMultiplePdfs = async (
      processNumber?: string,
      actorCode?: string,
    ) => {
      setLoading(true);
      try {
        await axios
          .get(
            `/shared-api/v1/process/${processNumber}/actors/${actorCode}/main-documents`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            },
          )
          .then((response) => {
            const documents = response.data;
            const uids = documents.map((pdf: any) => pdf.documentUid);
            setPdfUids(uids);
            setLoading(false);
            setDocuments(documents);
            const allDocumentsSignedByEveryone = documents.every(
              (document: any) => document.nbWaiting === 0,
            );
            setDocumentsSignedByAllMembers(allDocumentsSignedByEveryone);
          });
      } catch (error: any) {
        setLoading(false);
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      }
    };

    if (notAuthorized) {
      return;
    }

    fetchMultiplePdfs(processNumber, actorCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId, mimeType, accessToken]);

  useEffect(() => {
    // fetchPdfData only if pdfUids and documents are populated correctly
    if (pdfUids.length > 0 && documents.length > 0) {
      fetchPdfData(pdfUids[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfUids, documents]);

  useEffect(() => {
    axios
      .get(
        `/shared-api/v1/signbooks/${signBookNumber}/actors/${actorCode}/landing?type=SIGNING&processNumber=${processNumber}`,
      )
      .then((response) => {
        const number = response.data.phoneNumber;
        dispatch(setModalData({ phoneNumber: number, ...data }));
        const processSigned = response.data.processSigned;
        dispatch(setSignedDocument(processSigned));
      })
      .catch((error) => {
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!accessToken) {
      dispatch(showModal("restrictedAccess"));
    }
  }, [dispatch, accessToken]);

  useEffect(() => {
    const pdfPreview = document.querySelector(".pdf-container");

    if (pdfPreview) {
      (pdfPreview as HTMLElement).style.overflow =
        zoom !== 1 ? "auto" : "hidden";
    }
  }, [zoom]);

  useEffect(() => {
    let lastPageSeen = false;
    if (fileUrl.length > 0) {
      return;
    }
    const checkLastPageVisibility = () => {
      const lastPdf = pdfUrls.length - 1;

      const lastPage = numPagesMap[pdfUrls[pdfUrls.length - 1]];
      const lastPageElement = document.getElementById(
        `pdf_${lastPdf}_page_${lastPage}`,
      );

      if (lastPageElement) {
        const containerElement = lastPageElement.closest(
          ".scrollable-container",
        );

        if (containerElement) {
          const containerRect = containerElement.getBoundingClientRect();
          const lastPageRect = lastPageElement.getBoundingClientRect();
          const isVisible =
            lastPageRect.top >= containerRect.top &&
            lastPageRect.bottom <= containerRect.bottom;

          if (isVisible) {
            // Mark the last page as seen and set hasReadAll to true
            lastPageSeen = true;
            if (pdfUids.length === pdfUrls.length) {
              setHasReadAll(true);
            }
          } else {
            // If the last page is not visible and has been seen before, reset lastPageSeen
            if (lastPageSeen) {
              lastPageSeen = false;
            }
          }
        }
      }
    };

    const containerElement = document.querySelector(".scrollable-container");
    if (containerElement) {
      containerElement.addEventListener("scroll", checkLastPageVisibility);
    }

    return () => {
      if (containerElement) {
        containerElement.removeEventListener("scroll", checkLastPageVisibility);
      }
    };
  }, [numPagesMap, pdfUrls, fileUrl, pdfUids]);

  // When the component is loading, disable scroll
  useEffect(() => {
    document.body.style.overflow = loading ? "hidden" : "";

    return () => {
      document.body.style.overflow = "";
    };
  }, [loading]);

  const onDocumentLoadSuccess = (
    { numPages }: { numPages: number },
    pdfUrl: string,
  ): void => {
    setNumPagesMap({
      ...numPagesMap,
      [pdfUrl]: numPages,
    });
  };

  const scrollToThumbnail = (pdf: number, page: number) => {
    const thumbnail: HTMLElement | null = document.getElementById(
      `pdf_${pdf}_page_${page}`,
    );

    if (thumbnail) {
      const scrollableContainer: HTMLElement | Window =
        (thumbnail.closest(".scrollable-container") as HTMLElement) || window;
      const thumbnailRect: DOMRect = thumbnail.getBoundingClientRect();
      const containerRect: DOMRect =
        scrollableContainer instanceof Window
          ? {
              top: 0,
              left: 0,
              bottom: window.innerHeight,
              right: window.innerWidth,
              width: window.innerWidth,
              height: window.innerHeight,
              x: 0,
              y: 0,
              toJSON: () => {
                /* empty implementation */
              },
            }
          : scrollableContainer.getBoundingClientRect();

      const scrollOptions: ScrollToOptions = {
        top:
          thumbnailRect.top -
          containerRect.top +
          (scrollableContainer instanceof Window
            ? window.scrollY
            : scrollableContainer.scrollTop) -
          containerRect.height / 2 +
          thumbnailRect.height / 2,
        left:
          thumbnailRect.left -
          containerRect.left +
          (scrollableContainer instanceof Window
            ? window.scrollX
            : scrollableContainer.scrollLeft) -
          containerRect.width / 2 +
          thumbnailRect.width / 2,
        behavior: "smooth",
      };

      if (scrollableContainer instanceof Window) {
        window.scrollTo(scrollOptions);
      } else {
        scrollableContainer.scrollTo(scrollOptions);
      }
    }
  };

  const prevPage = () => {
    let thumbnailPdf = pdf;
    let thumbnailPage = page;

    if (page > 1) {
      setPage(page - 1);
      thumbnailPage = page - 1;
    } else if (pdf > 0) {
      setPdf(pdf - 1);
      setPage(numPagesMap[pdfUrls[pdf - 1]]);

      thumbnailPdf = pdf - 1;
      thumbnailPage = numPagesMap[pdfUrls[pdf - 1]];
    } else if (pdf === 0 && page === 1) {
      setPdf(pdfUrls.length - 1);
      setPage(numPagesMap[pdfUrls[pdfUrls.length - 1]]);

      thumbnailPdf = pdfUrls.length - 1;
      thumbnailPage = numPagesMap[pdfUrls[pdfUrls.length - 1]];
    }

    scrollToThumbnail(thumbnailPdf, thumbnailPage);
  };

  useEffect(() => {
    if (documentId) {
      return;
    }
    const currentPdfPages = numPagesMap[pdfUrls[pdf]];
    const hasMorePdfs = pdfUids.length !== loadedPdfUids.length;

    if (currentPdfPages === page) {
      hasMorePdfs ? fetchPdfData(pdfUids[pdf + 1]) : setHasReadAll(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdf, page]);

  const nextPage = () => {
    if (loading) {
      return;
    }
    let thumbnailPdf = pdf;
    let thumbnailPage = page;

    // If you have not reached the last page of the document
    if (page < numPagesMap[pdfUrls[pdf]]) {
      setPage(page + 1);
      thumbnailPage = page + 1;
      //If the last page of the document has been reached
    } else if (pdf < pdfUrls.length - 1) {
      setPdf(pdf + 1);
      setPage(1);
      thumbnailPdf = pdf + 1;
      thumbnailPage = 1;
      // If you are on the last page of the last document and there are no more documents to fetch
    } else if (pdf === pdfUrls.length && page === numPagesMap[pdfUrls[pdf]]) {
      setPdf(0);
      setPage(1);

      thumbnailPdf = 0;
      thumbnailPage = 1;
    }

    scrollToThumbnail(thumbnailPdf, thumbnailPage);
  };

  const handleChangingPage = (pdfIndex: number, index: number) => {
    setPdf(pdfIndex);
    setPage(index + 1);

    if (documentId) {
      return;
    }
  };

  const errorTextClass = hasError ? "text-ea-red" : "text-black";

  const initialValues = {
    validated: false,
    acceptedTerms: false,
    documentRead: false,
  };

  const areDocumentsAccepted = readAllDocumentsChecked && acceptedCondition;

  const isDisabled = mustReadAll
    ? !(hasReadAll && areDocumentsAccepted)
    : !areDocumentsAccepted;

  const documentContainerHeightClass = mustReadAll
    ? "min-h-[calc((100dvh-77px)-161px)]"
    : "min-h-[calc(100dvh-77px)]";

  const documentWidth = isMobile ? (isPortrait ? 300 : 250) : 600;

  const documentDistanceClass = isMobile
    ? isPortrait
      ? "top-[280px]"
      : "top-[128px]"
    : "top-[280px]";

  return (
    <>
      <ToastContainer className="body-lg" />
      <Modals />
      <header className="relative z-[12] w-full h-[77px] flex justify-between border-b-[1px] px-[24px] lg:px-0 bg-white">
        <img
          srcSet="/assets/logo.svg"
          alt=""
          className="lg:block w-[102px] h-[60px] mt-[8px] xl:ml-[74px]"
        />
        {signedDocumentStatus && (
          <div className="relative h-full w-full flex flex-row z-0 items-center justify-end mr-0 lg:mr-[74px]">
            {documentsSignedByAllMembers && (
              <Button
                className="btn-secondary px-[15px] py-[4px] rounded-[8px] mr-[10px] hidden md:block"
                onClick={() => {
                  if (!documentId) {
                    const urls = data.pdfForDownload;
                    downloadPDF(urls);
                    return;
                  }
                  axios
                    .get(
                      `api/v1/signbooks/${actId}/documents/${documentId}/data`,
                      {
                        responseType: "arraybuffer",
                      },
                    )
                    .then((response) => {
                      const contentType = response.headers["content-type"];

                      // Create a Blob from the response data
                      const fileBlob = new Blob([response.data], {
                        type: contentType,
                      });

                      if (contentType === "application/pdf") {
                        const fileUrl = URL.createObjectURL(fileBlob);
                        downloadPDF([fileUrl], signBookName);
                      } else {
                        downloadFile(fileBlob, signBookName);
                      }
                    })
                    .catch((error) => {
                      toast.error(
                        axiosErrorMessages[error.message],
                        toastOptionsError,
                      );
                    });
                }}
                data-testid="download-button"
              >
                {labels.download}
              </Button>
            )}

            <Button
              className="btn-secondary px-[15px] py-[4px] rounded-[8px] mr-[10px]"
              onClick={() => {
                if (!documentId) {
                  const urls = data.pdfForDownload;
                  mergeAndPrint(urls);
                  return;
                }
                axios
                  .get(
                    `api/v1/signbooks/${actId}/documents/${documentId}/data`,
                    {
                      responseType: "arraybuffer",
                    },
                  )
                  .then((response) => {
                    const contentType = response.headers["content-type"];

                    // Create a Blob from the response data
                    const fileBlob = new Blob([response.data], {
                      type: contentType,
                    });

                    if (contentType === "application/pdf") {
                      const fileUrl = URL.createObjectURL(fileBlob);
                      mergeAndPrint([fileUrl]);
                    } else {
                      printFile(fileBlob, signBookName);
                    }
                  })
                  .catch((error) => {
                    toast.error(
                      axiosErrorMessages[error.message],
                      toastOptionsError,
                    );
                  });
              }}
              data-testid="print-button"
            >
              {labels.print}
            </Button>
          </div>
        )}
      </header>

      <div className="relative overflow-hidden min-h-[calc(100dvh-77px)] h-full">
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            let updatedData = {
              ...data,
              ...values,
              signed: true,
            };

            if (!values.acceptedTerms || !values.documentRead) {
              setHasError(true);
              return;
            } else {
              updatedData.validated = true;
              setHasError(false);
              dispatch(setPageData({ ...pageData, updatedData }));
              setLoading(true);
              axios
                .post(
                  `/shared-api/v1/process/${processNumber}/actors/${actorCode}/certificate`,
                  {},
                  {
                    headers: {
                      Authorization: `Bearer ${accessToken}`,
                    },
                  },
                )
                .then((response) => {
                  const certificate = response.data.certificate;
                  const documentsData = documents.map(
                    (document: {
                      documentUid: string;
                      documentName: string;
                    }) => {
                      return {
                        documentUid: document.documentUid,
                        documentName: document.documentName,
                      };
                    },
                  );
                  dispatch(setModalData({ certificate, documentsData }));
                  dispatch(showModal("certificate"));
                  setLoading(false);
                })
                .catch((error) => {
                  setLoading(false);
                  toast.error(
                    axiosErrorMessages[error.message],
                    toastOptionsError,
                  );
                });
            }
          }}
        >
          {(formik) => (
            <Form>
              {notAuthorized ? null : (
                <>
                  {/* Arrows previous/next */}
                  <div className="z-10 flex flex-col items-center xl:items-start justify-center w-full xl:w-auto mx-auto fixed xl:left-[20px] top-[85px]">
                    <h2 className="headline-lg mb-1 lg:mb-[17px]">
                      {actTitle}
                    </h2>
                    <div className="flex items-center text-center">
                      <div className="flex items-center">
                        <Button
                          onClick={() => {
                            prevPage();
                          }}
                          type="button"
                          className="px-[20px] py-[10px] btn-secondary border-r-0 rounded-l-[8px]"
                        >
                          <Icon
                            type="arrow-left-pagination"
                            className="w-[7px] h-[14px]"
                          />
                        </Button>
                        <p className="body-md border border-ea-darkblue px-[10px] py-[8px] bg-white">
                          {labels.page} {page} - {labels.doc} {pdf + 1}
                        </p>
                        <Button
                          onClick={() => {
                            nextPage();
                          }}
                          type="button"
                          className="px-[20px] py-[10px] btn-secondary border-l-0 rounded-r-[8px]"
                        >
                          <Icon
                            type="arrow-right-pagination"
                            className="w-[7px] h-[14px]"
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                  {/* Zoom in/out */}
                  <div className="z-10 items-center text-center bg-white rounded-md border border-slate-700 fixed right-[350px] hidden lg:flex top-[100px]">
                    <Button
                      onClick={() => {
                        setZoom((prevZoom) => Math.min(3.25, prevZoom + 0.25));
                      }}
                      type="button"
                      className="px-[10px] py-[5px] border-r border-slate-700"
                    >
                      <Icon type="zoom-in" className="size-[18px]" />
                    </Button>
                    <Button
                      onClick={() => {
                        setZoom((prevZoom) => Math.max(0.25, prevZoom - 0.25));
                      }}
                      type="button"
                      className="px-[10px] py-[5px]"
                    >
                      <Icon type="zoom-out" className="size-[18px]" />
                    </Button>
                  </div>
                </>
              )}
              <div className="flex flex-col md:flex-row items-start justify-between min-h-[calc(100vh-77px)]">
                <div className="flex-column md:flex-row w-full mt-[100px] xl:mt-0">
                  {/* First Div */}
                  {loading ? (
                    <div className="flex items-center justify-center w-full h-screen">
                      <Spinner className="size-20 !border-t-slate-500" />
                    </div>
                  ) : (
                    <div
                      className={`flex flex-col items-center justify-center pdf-container relative w-full ${documentContainerHeightClass}`}
                      ref={pdfContainerRef}
                    >
                      {/* PDF */}
                      <div className="cursor-zoom-in">
                        {mimeType?.startsWith("image/") && (
                          <img
                            src={fileUrl}
                            alt="Document"
                            style={{ maxWidth: "100%" }}
                          />
                        )}
                        {mimeType === "application/pdf" &&
                          pdfUrls.length > 0 &&
                          pdfUrls.map((pdfUrl, index) => {
                            const visibleClass = pdf === index ? "" : "hidden";

                            if (pdfContainerRef.current) {
                              const container =
                                pdfContainerRef.current as HTMLElement;
                              container.scrollTop = (647 * zoom) / 2;
                              container.scrollLeft = (600 * zoom) / 2;
                            }

                            return (
                              <div
                                className={`absolute ${documentDistanceClass} md:top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4`}
                                key={`pdf_preview_${index + 1}`}
                              >
                                <div
                                  className={`${visibleClass}`}
                                  style={{
                                    transform: `scale(${zoom})`,
                                    transformOrigin: "top center",
                                  }}
                                >
                                  <TransformWrapper
                                    initialScale={1}
                                    initialPositionX={0}
                                    initialPositionY={0}
                                    maxScale={3}
                                  >
                                    <TransformComponent>
                                      {pdf === index && (
                                        <Document
                                          file={pdfUrl}
                                          ref={documentRef}
                                          loading={<p>{labels.loading}</p>}
                                          error={
                                            <p>{labels.errorWhileLoadingPdf}</p>
                                          }
                                        >
                                          <Page
                                            pageNumber={page}
                                            renderTextLayer={false}
                                            renderAnnotationLayer={false}
                                            width={documentWidth}
                                            loading={<p>{labels.loading}</p>}
                                            error={
                                              <p>
                                                {labels.errorWhileLoadingPdf}
                                              </p>
                                            }
                                          />
                                        </Document>
                                      )}
                                    </TransformComponent>
                                  </TransformWrapper>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  )}

                  {/* Second Div */}
                  <>
                    {!signedDocumentStatus ? (
                      <div className="flex-column lg:w-[calc(100%-270px)] w-full h-[161px] bg-white absolute left-0 bottom-0 sm:px-5 xl">
                        <div className="max-w-[930px] mx-auto">
                          <div className="body-sm mx-auto pt-6">
                            {labels.documentViewLegalMentions}
                          </div>
                          <div className="flex justify-between mx-auto pt-4">
                            <div>
                              <label
                                className={`flex relative body-md items-center custom-checkbox-container ${errorTextClass}`}
                              >
                                <Field
                                  type="checkbox"
                                  name="documentRead"
                                  id="documentRead"
                                  className={`custom-checkbox ${hasError ? "error" : ""}`}
                                  onChange={() => {
                                    setHasError(false);
                                    setReadAllDocumentsChecked(
                                      !readAllDocumentsChecked,
                                    );
                                    formik.setFieldValue(
                                      "documentRead",
                                      !formik.values.documentRead,
                                    );
                                  }}
                                />
                                {labels.affirmationToHaveReadTheDocuments}
                              </label>
                              {hasError ? (
                                <span className="text-[10px] max-w-[293px] text-ea-red absolute">
                                  {labels.requiredFieldText}
                                </span>
                              ) : null}
                            </div>
                            <div>
                              <label
                                className={`flex relative body-md items-center custom-checkbox-container ${errorTextClass}`}
                              >
                                <Field
                                  type="checkbox"
                                  name="acceptedTerms"
                                  id="acceptedTerms"
                                  className={`custom-checkbox ${hasError ? "error" : ""}`}
                                  onChange={() => {
                                    setHasError(false);
                                    formik.setFieldValue(
                                      "acceptedTerms",
                                      !formik.values.acceptedTerms,
                                    );
                                    setAcceptedCondition(!acceptedCondition);
                                  }}
                                />
                                {labels.acceptTermsAndConditions}
                              </label>
                              {hasError ? (
                                <span className="text-[10px] max-w-[293px] text-ea-red absolute">
                                  {labels.requiredFieldText}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      // Displayed when the document has been signed
                      <div className="flex w-full md:w-[calc(100%-280px)] h-[161px] bg-white absolute left-0 bottom-0 md:px-5">
                        <div className="flex max-w-[930px] mx-auto items-center">
                          <Icon
                            type="circle-check"
                            color="#00B900"
                            className="absolute size-[24px] justify-start"
                          />
                          <div className="body-lg h-full ml-14 justify-end translate-y-[36%]">
                            <p>{labels.signedActAndAnnexesLabel}</p>
                            <p>{labels.downloadDocumentOnFinalizedActLabel}</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                </div>

                {/* Third Div */}
                <div className="relative w-full md:w-[290px] pt-[69px] pb-[50px] pr-[20px] pl-[37px] bg-white flex-col justify-between items-center min-h-[calc(100dvh-77px)] h-full hidden lg:flex">
                  <div className="max-h-[500px] overflow-auto mb-2 scrollable-container">
                    {fileUrl.length > 0 && mimeType.startsWith("image/") && (
                      <img src={fileUrl} alt="Document" className="mb-4" />
                    )}
                    {/* Thumbnails */}
                    {mimeType === "application/pdf" &&
                      pdfUrls.length > 0 &&
                      pdfUrls.map((pdfUrl, pdfIndex) => {
                        return (
                          <Document
                            file={pdfUrl}
                            key={`pdf_${pdfIndex + 1}`}
                            onLoadSuccess={(args) =>
                              onDocumentLoadSuccess(args, pdfUrl)
                            }
                            loading={<p>{labels.loading}</p>}
                            error={<p>{labels.errorWhileLoadingPdf}</p>}
                          >
                            <div className="flex">
                              <h2 className="mr-4 font-bold body-lg">
                                {labels.doc} {pdfIndex + 1}
                              </h2>
                              <div className="pt-1 mr-[30px]">
                                {Array.from(
                                  Array(numPagesMap[pdfUrl]).keys(),
                                ).map((_, index) => {
                                  const pageBorderClass =
                                    pdf === pdfIndex && index + 1 === page
                                      ? "border-red-700 ring-2 ring-red-700"
                                      : "border-slate-200";

                                  return (
                                    <div
                                      id={`pdf_${pdfIndex}_page_${index + 1}`}
                                      key={`page_${index + 1}`}
                                    >
                                      <Page
                                        pageNumber={index + 1}
                                        width={140}
                                        onClick={() => {
                                          handleChangingPage(pdfIndex, index);
                                        }}
                                        className={`${pageBorderClass} cursor-pointer mb-4 border`}
                                        renderTextLayer={false}
                                        renderAnnotationLayer={false}
                                        loading={<p>{labels.loading}</p>}
                                        error={
                                          <p>{labels.errorWhileLoadingPdf}</p>
                                        }
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </Document>
                        );
                      })}
                  </div>
                  {!signedDocumentStatus && (
                    <div>
                      {mustReadAllDocs && mustReadAll && (
                        <p className="p-1 bg-ea-yellow body-sm font-bold text-center">
                          {labels.pleaseReadAllDocuments}
                        </p>
                      )}
                      <p className="body-md mb-2">
                        <span className="font-bold mr-1">{labels.itsUpTo}</span>
                        <span className="font-bold mr-1">{signatoryName}</span>
                        <span className="font-bold mr-1">
                          {labels.isSigning}
                        </span>
                      </p>
                      <ProgressBar
                        currentStep={Number(order) - 1}
                        maxSteps={4}
                      />

                      <div className="max-w-[250px] mt-[25px] flex-column px-[15px]">
                        <Button
                          type="submit"
                          className="btn-primary w-full py-[6px] px-[30px] rounded-[8px] flex items-center justify-center"
                          disabled={isDisabled || loading}
                        >
                          {loading && <Spinner className="size-3 mr-1" />}
                          {labels.sign}
                        </Button>
                      </div>
                    </div>
                  )}
                </div>

                <div className="flex lg:hidden absolute mx-auto r-0 l-0 bottom-0 w-full bg-white">
                  <div className="w-full flex-column px-[15px] my-4">
                    <Button
                      type="submit"
                      className="btn-primary w-full py-[6px] px-[30px] rounded-[8px] flex items-center justify-center"
                      disabled={isDisabled || loading}
                    >
                      {loading && <Spinner className="size-3 mr-1" />}
                      {labels.sign}
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};
export default DivorceSigning;
