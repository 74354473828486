import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "@types";

const initialState = {
  documents: [],
  validatedDocuments: [],
  orderChanged: false,
  signedDocument: false,
};

const validationSlice = createSlice({
  name: "validation",
  initialState,
  reducers: {
    // Stores the IDs of all documents.
    setDocuments: (state, action) => {
      state.documents = action.payload;
    },
    // Stores the IDs of all validated documents.
    setValidatedDocuments: (state, action) => {
      state.validatedDocuments = action.payload;
    },
    // Indicates if the order of the documents is changed.
    setOrderChanged: (state, action) => {
      state.orderChanged = action.payload;
    },
    setSignedDocument: (state, action) => {
      state.signedDocument = action.payload;
    },
  },
});

export const {
  setDocuments,
  setValidatedDocuments,
  setOrderChanged,
  setSignedDocument,
} = validationSlice.actions;

export const selectDocuments = (state: RootState) => state.validation.documents;
export const selectValidatedDocuments = (state: RootState) =>
  state.validation.validatedDocuments;
export const selectOrderChanged = (state: RootState) =>
  state.validation.orderChanged;
export const selectSignedDocument = (state: RootState) =>
  state.validation.signedDocument;
export default validationSlice.reducer;
