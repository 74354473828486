import ProgressBar from "@ui/ProgressBar";
import Spinner from "@ui/Spinner";

import { labels } from "@constants";

import { DocumentForSigningTableProps } from "@types";

import { getStatusText } from "@utils";

const ActStatus = ({
  actData,
  loadedStatuses,
  loadedStatusesError,
}: {
  actData: DocumentForSigningTableProps;
  loadedStatuses: Record<string, number>;
  loadedStatusesError: string[];
  publicId: string;
}) => {
  const publicId = actData.publicId;

  if (!publicId) {
    return null;
  }

  const progress = loadedStatuses[publicId] || null;
  const error = loadedStatusesError.includes(publicId);

  if (error) {
    return (
      <p className="text-ea-red 2xl:ml-2 2xl:mt-0 mt-2">{labels.noInfo}</p>
    );
  }

  if (!progress) {
    return <Spinner className="size-4" dark={true} />;
  }

  const showProgress =
    actData.status &&
    !["closed", "cancelled", "expired"].includes(actData.status);

  return (
    <div className="flex flex-col 2xl:flex-row 2xl:items-center items-start">
      {showProgress && (
        <div className="min-w-[107px]">
          <ProgressBar currentStep={progress} maxSteps={100}></ProgressBar>
        </div>
      )}
      <p className="2xl:ml-2 2xl:mt-0 mt-2">
        {getStatusText(actData.status, actData.signingStatus)}
      </p>
    </div>
  );
};

export default ActStatus;
