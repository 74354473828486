type TabsProps = {
  tabIndex: number;
  tabList: {
    label: string;
  }[];
  setActiveTab: (index: number) => void;
};

const Tabs = ({ tabIndex, tabList, setActiveTab }: TabsProps) => {
  return (
    <div className="border-b border-[#D7D7D7] [&>button+button]:ml-[18px] mr-[30px]">
      {tabList.map((tab, index) => {
        const activeClass =
          tabIndex === index
            ? "font-bold border-black"
            : "font-normal border-white";

        return (
          <button
            key={index}
            className={`${activeClass} headline-sm border-b-[7px] pb-[7px]`}
            onClick={(event) => {
              event.preventDefault();
              setActiveTab(index);
            }}
          >
            {tab.label}
          </button>
        );
      })}
    </div>
  );
};

export default Tabs;
