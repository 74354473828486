import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "@types";

const initialState = {
  image: null,
  useByDefault: false,
  saveImageAction: false,
};

const signatureSlice = createSlice({
  name: "signature",
  initialState,
  reducers: {
    setImage: (state, action) => {
      const { image } = action.payload;
      state.image = image;
    },
    deleteImage: (state) => {
      state.image = null;
    },
    setUseByDefault: (state, action) => {
      const { useByDefault } = action.payload;
      state.useByDefault = useByDefault;
    },
    setSaveImageAction: (state, action) => {
      state.saveImageAction = action.payload.saveImageAction;
    },
  },
});

export const { setImage, deleteImage, setUseByDefault, setSaveImageAction } =
  signatureSlice.actions;

export const selectImage = (state: RootState) => state.signature.image;
export const selectUseByDefault = (state: RootState) =>
  state.signature.useByDefault;
export const selectSaveImageAction = (state: RootState) =>
  state.signature.saveImageAction;

export default signatureSlice.reducer;
