import Keycloak from "keycloak-js";
const keycloak = new Keycloak({
  url: `${process.env.REACT_APP_KEYCLOAK_URL}`,
  authServerUrl: `${process.env.REACT_APP_KEYCLOAK_AUTH_URL}`,
  realm: `${process.env.REACT_APP_KEYCLOAK_REALM}`,
  clientId: `${process.env.REACT_APP_KEYCLOAK_CLIENTID}`,
  resource: `${process.env.REACT_APP_KEYCLOAK_RESOURCE}`,
  publicClient: process.env.REACT_APP_KEYCLOAK_PUBLIC_CLIENT,
  confidentialPort: process.env.REACT_APP_KEYCLOAK_CONFIDENTIAL_PORT,
  sslRequired: process.env.REACT_APP_KEYCLOAK_SSL_REQUIRED,
  ignoreOauthQueryParameter:
    process.env.REACT_APP_KEYCLOAK_IGNORE_OAUTH_QUERY_PARAMETER,
  useResourceRoleMappings:
    process.env.REACT_APP_KEYCLOAK_USE_RESOURCE_ROLE_MAPPINGS,
  enableCors: process.env.REACT_APP_KEYCLOAK_ENABLE_CORS,
  corsMaxAge: process.env.REACT_APP_KEYCLOAK_CORS_MAX_AGE,
  bearerOnly: process.env.REACT_APP_KEYCLOAK_BEARER_ONLY,
  autodetectBearerOnly: process.env.REACT_APP_KEYCLOAK_AUTO_DETECT_BEARER_ONLY,
  enableBasicAuth: process.env.REACT_APP_KEYCLOAK_ENABLE_BASIC_AUTH,
  exposeToken: process.env.REACT_APP_KEYCLOAK_EXPOSE_TOKEN,
  disableTrustManager: process.env.REACT_APP_KEYCLOAK_DISABLE_TRUST_MANAGER,
  allowAnyHostname: process.env.REACT_APP_KEYCLOAK_ALLOW_ANY_HOSTNAME,
  alwaysRefreshToken: process.env.REACT_APP_KEYCLOAK_ALWAYS_REFRESH_TOKEN,
  registerNodeAtStartup:
    process.env.REACT_APP_KEYCLOAK_REGISTER_NODE_AT_STARTUP,
  registerNodePeriod: process.env.REACT_APP_KEYCLOAK_REGISTER_NODE_PERIOD,
  turnOffChangeSessionIdOnLogin:
    process.env.REACT_APP_KEYCLOAK_TURN_OFF_CHANGE_SESSION_ID_ON_LOGIN,
  connectionPoolSize: process.env.REACT_APP_KEYCLOAK_CONNECTION_POOL_SIZE,
  tokenMinimumTimeToLive:
    process.env.REACT_APP_KEYCLOAK_TOKEN_MINIMUM_TIME_TO_LIVE,
  minTimeBetweenJwksRequests:
    process.env.REACT_APP_KEYCLOAK_MIN_TIME_BETWEEN_JWKS_REQUESTS,
  publicKeyCacheTtl: process.env.REACT_APP_KEYCLOAK_PUBLIC_KEY_CACHE_TTL,
});

export default keycloak;
