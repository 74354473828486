import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import Button from "@ui/Button";
import Modal from "@ui/Modal";
import Spinner from "@ui/Spinner";

import { axiosErrorMessages, labels, toastOptionsError } from "@constants";

import { selectModalData } from "@reducers/dataTransferSlice";
import { showModal } from "@reducers/modalsSlice";

import { addQueryParams } from "@utils";

const LawyerSignatureConfirmation = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const data = useSelector(selectModalData);

  const queryParams = new URLSearchParams(location.search);
  const signBookNumber = queryParams.get("signBookNumber");

  const [confirmation, setConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lawyerCode, setLawyerCode] = useState("");

  useEffect(() => {
    if (data) {
      setLawyerCode(data.lawyerCode);
    }
  }, [data]);

  const handleWaitForSignature = () => {
    setLoading(true);

    axios
      .put(
        addQueryParams(
          `api/v1/signbooks/${signBookNumber}/lawyers/${lawyerCode}/signings/security`,
          {
            isSecure: "false",
          },
        ),
      )
      .then(() => {
        setLoading(false);
        dispatch(showModal("waitingForSignature"));
      })
      .catch((error) => {
        setLoading(false);
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      });
  };

  return (
    <Modal title={labels.signatureConfirmation} width={466}>
      <p className="body-sm my-3">{labels.youWillSignOnAnotherDeviceText}</p>

      <div className="custom-checkbox-container custom-checkbox-container-multiline">
        <label className="body-sm flex">
          <input
            name="confirmation"
            type="checkbox"
            className="custom-checkbox"
            checked={confirmation}
            onClick={() => setConfirmation(!confirmation)}
          />
          {labels.acceptThatWillSignOnAnotherDeviceText}
        </label>
      </div>

      <div className="flex justify-end mt-8">
        <Button
          className="btn-base btn-primary flex justify-start items-center rounded-[8px] px-[21px] py-[8px] body-md h-[34px]"
          onClick={() => handleWaitForSignature()}
          disabled={
            !confirmation || !signBookNumber || "" === lawyerCode || loading
          }
        >
          {loading && <Spinner className="size-4 mr-2" />}
          {labels.sign}
        </Button>
      </div>
    </Modal>
  );
};

export default LawyerSignatureConfirmation;
