import { ErrorMessage, Field, useFormikContext } from "formik";
import parsePhoneNumber from "libphonenumber-js";
import { useEffect, useState } from "react";

import Button from "@ui/Button";
import Icon from "@ui/Icon";

import { labels } from "@constants";
import { phones } from "@constants/region";

import { PhoneFieldProps } from "@types";

import { formatLabel } from "@utils";
import { getCountryByIndex, getPhoneCodes } from "@utils/phone";

const PhoneField = ({
  phoneName = "phone",
  phoneCodeName = "phoneCode",
  phoneCountryCode = "phoneCountryCode",
  setFieldValue,
  inversedDropdown = false,
  required = false,
  disabled = false,
}: PhoneFieldProps) => {
  const codes = getPhoneCodes();

  const [showDropdown, setShowDropdown] = useState(false);
  const { getFieldProps } = useFormikContext();
  const phoneCodeValue = getFieldProps(phoneCodeName)?.value || "+33";
  const phoneCountryCodeValue = getFieldProps(phoneCountryCode)?.value || "FR";

  const dropdownPositionClass = inversedDropdown ? "bottom-full" : "top-full";

  const fieldClass =
    "block input border border-[#B4B4B4] rounded-r body-lg h-[34px] p-2 ml-[-1px] w-full md:w-[calc(100%+1px)]";
  const fieldClassDisabled = fieldClass.replace(
    "border-[#B4B4B4]",
    "border-[#F5F5F5] bg-white",
  );
  const fieldClassFinal = disabled ? fieldClassDisabled : fieldClass;
  const phoneCodeSelectClass = disabled
    ? "phone-code-select phone-code-select-disabled"
    : "phone-code-select";

  const validatePhoneNumber = (value: string) => {
    const fullPhoneNumber = `${phoneCodeValue}${value}`;
    const parsedNumber = parsePhoneNumber(
      fullPhoneNumber,
      phoneCountryCodeValue,
    );

    if ("" === value.trim()) {
      return formatLabel(labels.requiredField, labels.phone);
    }

    if (!parsedNumber?.isValid()) {
      return formatLabel(labels.compliantField, labels.phone);
    }

    return "";
  };

  const PhoneCodeDropdown = () => {
    useEffect(() => {
      const hideDropdown = () => {
        if (showDropdown) {
          setShowDropdown(false);
        }
      };

      window.addEventListener("click", hideDropdown);

      return () => {
        window.removeEventListener("click", hideDropdown);
      };
    }, []);

    useEffect(() => {
      if (showDropdown) {
        const container = document.getElementById("phone-code-select-dropdown");
        const targetItem = document.getElementById("phone-code-selected");

        if (container && targetItem) {
          const containerRect = container.getBoundingClientRect();
          const itemRect = targetItem.getBoundingClientRect();
          const scrollOffset = itemRect.top - containerRect.top;

          container.scrollBy({
            top: scrollOffset,
          });
        }
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showDropdown]);

    if (!showDropdown) {
      return null;
    }

    return (
      <div
        id="phone-code-select-dropdown"
        className={`phone-code-select-dropdown ${dropdownPositionClass} scrollbar-outside`}
        data-testid="phone-code-select-dropdown"
      >
        <ul className="py-2 text-sm">
          {codes.map((code: string, index: number) => {
            const countryCode = getCountryByIndex(index);
            const selectedID =
              code === phoneCodeValue ? "phone-code-selected" : "";

            return (
              <li key={index} id={selectedID}>
                <Button
                  type="button"
                  className="pl-3 py-1 text-sm body-sm text-left inline-flex justify-between items-center w-full"
                  role="menuitem"
                  onClick={() => {
                    setFieldValue(phoneCodeName, code);
                    setFieldValue(phoneCountryCode, countryCode);
                    setShowDropdown(false);
                  }}
                >
                  {code}
                  <p className="text-right text-ea-gray-300 text-xs">
                    {phones[index].name}
                  </p>
                </Button>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    <div>
      <div className="flex items-center relative w-full">
        <div className="self-stretch relative">
          <Field as="select" className="hidden" name={phoneCodeName}>
            {codes.map((code: string, index: number) => (
              <option key={index} value={code}>
                {code}
              </option>
            ))}
          </Field>
          <div
            className={`${phoneCodeSelectClass} flex items-center`}
            onClick={(event) => {
              if (disabled) {
                return;
              }

              event.stopPropagation();

              setShowDropdown(!showDropdown);
            }}
            data-testid="phone-code-select-dropdown-toggle"
          >
            {phoneCodeValue}
          </div>
          <Icon
            type="dropdown-arrow"
            className="size-[12px] absolute top-1/2 right-[8px] -translate-y-2/4 pointer-events-none"
            color="#000"
          />
        </div>
        <PhoneCodeDropdown />
        <Field
          type="text"
          id={phoneName}
          name={phoneName}
          className={fieldClassFinal}
          required={required}
          disabled={disabled}
          validate={validatePhoneNumber}
        />
      </div>
      <ErrorMessage name={phoneCodeName}>
        {(msg) => (
          <span className="text-[10px] max-w-[293px] text-ea-red">{msg}</span>
        )}
      </ErrorMessage>
      <ErrorMessage name={phoneName}>
        {(msg) => (
          <span className="text-[10px] max-w-[293px] text-ea-red">{msg}</span>
        )}
      </ErrorMessage>
    </div>
  );
};

export default PhoneField;
