import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "@types";

const initialState = {
  page: 0,
  maxPage: 0,
  loading: false,
};

const tracesSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setPage, setLoading } = tracesSlice.actions;

export const selectPage = (state: RootState) => state.traces.page;
export const selectLoading = (state: RootState) => state.traces.loading;

export default tracesSlice.reducer;
