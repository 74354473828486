import axios from "axios";
import { Field, Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import { toast } from "react-toastify";

import Button from "@ui/Button";
import Icon from "@ui/Icon";
import Modal from "@ui/Modal";
import Spinner from "@ui/Spinner";

import { actTypeNameMap, labels, toastOptions } from "@constants";

import { selectModalData } from "@reducers/dataTransferSlice";
import { selectTokens } from "@reducers/eafSlice";
import { selectUser } from "@reducers/metadataSlice";
import { hideModal } from "@reducers/modalsSlice";
import { setSignedDocument } from "@reducers/validationSlice";

import { base64ToFile, filterTokens, formatLabel } from "@utils";
import { prepareAndFinalizeApi } from "@utils/api/prepareAndFinalizeApi";

const Signature = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  const [signatureData, setSignatureData] = useState<FormData>(new FormData());
  const [signatureUrl, setSignatureUrl] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [loadingSignature, setLoadingSignature] = useState(true);
  const [documents, setDocuments] = useState<string[]>([]);
  const [actData, setActData] = useState<Record<string, string>>({});
  const signatureRef = useRef<SignatureCanvas>(null);
  const [token, setToken] = useState<Record<string, any> | null>(null);

  const tokens = useSelector(selectTokens);

  const data = useSelector(selectModalData);
  const order = data?.order || 0;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const actId =
    queryParams.get("actId") || queryParams.get("signBookNumber") || "";
  const [showSignatureInput, setShowSignatureInput] = useState(false);
  const [clientSignature, setClientSignature] = useState(false);
  const [useClientSignature, setUseClientSignature] = useState(false);
  const actorCode = queryParams.get("actorCode") || "";
  const processNumber = queryParams.get("processNumber") || "";

  const signingSuccessful = () => {
    if (actData?.type) {
      const normalizedActType = actTypeNameMap[actData.type];

      if (["convention"].includes(normalizedActType)) {
        navigate(`/acts/${normalizedActType}`);
        return;
      }
    }

    toast.success(labels.successfullySignedAct, toastOptions);

    dispatch(hideModal());
    dispatch(setSignedDocument(true));
  };

  useEffect(() => {
    if (!user || !data) {
      return;
    }

    const { order } = data;
    const lawyerId = order === 3 ? data.lawyerCode : user.numCNBF;

    const token = filterTokens(tokens, lawyerId);

    if (token) {
      setToken(token);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, data]);

  useEffect(() => {
    let url = `api/v1/users/${data.lawyerCode}/signature`;

    if (actData?.type) {
      url = "api/v1/me/signature";
    }
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((response) => {
        if (response.data && response.data instanceof Blob) {
          // Create a URL for the Blob
          const url = URL.createObjectURL(response.data);

          signatureData.append("appearance", response.data, "signature.pdf");

          setSignatureData(signatureData);
          setSignatureUrl(url);
          setLoadingSignature(false);
        }
      })
      .catch(() => {
        setLoadingSignature(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!actId) {
      return;
    }

    axios
      .get(`/api/v1/signbooks/${actId}`)
      .then((response) => {
        setActData(response.data);
      })
      .catch(() => {
        setActData({});
      });
  }, [actId]);

  useEffect(() => {
    if (!data?.documents) {
      return;
    }

    setDocuments(data.documents);
  }, [data]);

  const handlePrepareAndFinalize = () => {
    const certificateCode = token?.certificate?.original;

    if (!certificateCode || !actId || !signatureData) {
      return;
    }

    setLoading(true);

    if (order === 3 && data?.isSecure) {
      return axios
        .post(
          `/shared-api/v1/signbooks/${processNumber}/actors/${actorCode}/authenticate`,
          {},
        )
        .then((response) => {
          const { accessToken } = response.data;

          prepareAndFinalizeApi(
            actId,
            token,
            signatureData,
            setLoading,
            signingSuccessful,
            {
              accessToken,
              signBookNumber: actId,
              lawyerCode: data.lawyerCode,
            },
          );
        })
        .catch((error) => {
          toast.error(error.message, toastOptions);
        });
    }

    prepareAndFinalizeApi(
      actId,
      token,
      signatureData,
      setLoading,
      signingSuccessful,
      {},
    );
  };

  const initialValues = {
    placeSignature: false,
  };

  const postSignature = () => {
    // Save the drawn signature as an image (if not empty).
    if (signatureRef.current && !signatureRef.current.isEmpty()) {
      const dataUrl = signatureRef.current.toDataURL();
      const file = base64ToFile(dataUrl, "signature");

      if (!file) {
        return;
      }

      const formData = new FormData();
      formData.append("file", file);

      let url = `api/v1/users/${data.lawyerCode}/signature`;
      if (actData?.type) {
        url = "api/v1/me/signature";
      }
      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            signatureData.append("appearance", file, "signature.pdf");
            setSignatureUrl(dataUrl);
            setShowSignatureInput(false);
            setClientSignature(false);
            setUseClientSignature(true);
          }
        })
        .catch((error) => {
          toast.error(error.message, toastOptions);
        });
      return;
    }
  };

  const handleImageDelete = () => {
    if (signatureUrl === "") {
      setSignatureData(new FormData());
      return;
    }

    let url = `api/v1/users/${data.lawyerCode}/signature`;

    if (actData?.type) {
      url = "api/v1/me/signature";
    }
    axios
      .delete(url)
      .then(() => {
        setSignatureUrl("");
        setSignatureData(new FormData());
      })
      .catch((error) => {
        toast.error(error.message, toastOptions);
      });
  };

  return (
    <Modal width={450} title={labels.signature}>
      <Formik
        initialValues={initialValues}
        onSubmit={() => {
          handlePrepareAndFinalize();
        }}
      >
        <Form>
          <div className="body-sm mt-4 leading-5">
            {formatLabel(
              labels.signingDocumentsList,
              documents.length.toString(),
            )}

            {documents && (
              <ul className="font-bold mt-3 [&>li]:mt-1">
                {documents.map((document, index) => (
                  <li key={index}>{document}</li>
                ))}
              </ul>
            )}

            <div className="text-center mt-8">
              <div className="text-ea-gray-100 border border-ea-gray-100 py-1 px-3 body-lg inline-block rounded w-[260px]">
                {token?.certificate?.alias || labels.noCertificateDetected}
              </div>
            </div>

            <div className="flex items-center justify-center mt-4">
              <label
                className={`flex relative body-md items-center custom-checkbox-container`}
              >
                <Field
                  type="checkbox"
                  name="placeSignature"
                  className="custom-checkbox"
                  checked={useClientSignature}
                  onClick={() => {
                    if (!useClientSignature) {
                      // When the checkbox is initially unchecked
                      setShowSignatureInput(true);
                      setClientSignature(true);
                    } else {
                      // When the checkbox is already checked
                      setShowSignatureInput(false);
                      setClientSignature(false);
                    }
                    setUseClientSignature(!useClientSignature);
                  }}
                />
                {labels.applyHandwrittenSignature}
              </label>
            </div>

            <div className="mt-4 relative w-[250px] h-[150px] lg:w-[101px] lg:h-[80px] bg-slate-200 rounded mx-auto">
              {loadingSignature ? (
                <Spinner className="size-4 mx-auto" />
              ) : showSignatureInput ? (
                <>
                  {signatureUrl === "" ? (
                    <SignatureCanvas
                      ref={signatureRef}
                      canvasProps={{
                        width: 100,
                        height: 80,
                      }}
                    />
                  ) : (
                    <img src={signatureUrl} alt="" />
                  )}
                  {/* This button is used to clear the signature. */}
                  <Button
                    className="flex absolute left-[-20px] bottom-[-10px] bg-white size-[25px] rounded-full items-center justify-center shadow-md"
                    type="button"
                    onClick={() => {
                      setSignatureUrl("");
                      if (signatureRef.current) {
                        signatureRef.current.clear();
                      }
                      handleImageDelete();
                    }}
                  >
                    <Icon
                      type="trash"
                      className="size-[24px] text-ea-gray-200"
                    />
                  </Button>
                </>
              ) : (
                <img src={signatureUrl} alt="" />
              )}
            </div>

            {clientSignature && (
              <Button
                disabled={loadingSignature}
                className="btn-base btn-primary flex items-center justify-center mt-5 text-center m-auto"
                type="button"
                onClick={() => {
                  postSignature();
                }}
              >
                {loading && <Spinner className="size-4 mr-4" />}
                {labels.validateSignature}
              </Button>
            )}

            <div className="mt-8 flex items-center justify-center">
              <Button
                disabled={
                  loading || !token?.certificate?.original || showSignatureInput
                }
                className="btn-base btn-primary flex items-center justify-center"
                type="submit"
              >
                {loading && <Spinner className="size-4 mr-4" />}
                {labels.sign}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default Signature;
