import { useState } from "react";
import { Route, Routes, useParams } from "react-router-dom";

import Layout from "@components/layout/Layout";

import Breadcrumb from "@ui/Breadcrumb";

import { labels } from "@constants";

import Information from "@pages/new-act/step-1/Information";
import PartySignatures from "@pages/new-act/step-2/PartySignatures";
import DocumentsForSigning from "@pages/new-act/step-3/DocumentsForSigning";
import SendForSignatures from "@pages/new-act/step-4/SendForSignatures";

import { getPathMap } from "@utils";

const NewAct = () => {
  const { actType, "*": rest } = useParams();
  const publicId = rest ? rest.split("/")[0] : undefined;
  const isNumericId = !isNaN(Number(publicId?.split("-")[0]));
  const pathMap = isNumericId
    ? getPathMap(actType, publicId)
    : getPathMap(actType);
  const [formikState, setFormikState] = useState({
    formik: null,
    isDirty: false,
    callback: null,
    step: 0,
  });

  let title;

  switch (actType) {
    case "birth":
      title = labels.newBirthAct;
      break;
    case "convention":
      title = labels.newConventionAgreement;
      break;
    case "divorce":
      title = labels.newDivorce;
      break;
    case "digital":
      title = labels.newActDigital;
      break;
    default:
      title = labels.newDocument;
  }

  return (
    <Layout sidebar={false} backgroundColor="bg-[#F9F9F9]" quitButton={true}>
      <div className="flex">
        <div className="mx-auto">
          <div className="flex items-center justify-around mt-[38px] mb-[38px]">
            <div className="mb-[16px] items-center text-center">
              <h1 className="headline-lg mb-2">{title}</h1>
              <Breadcrumb
                pathMap={pathMap}
                formikState={formikState}
                setFormikState={setFormikState}
              />
            </div>
          </div>
          <div className="bg-white lg:w-[695px] mx-6 lg:mx-auto rounded-t-lg pb-2 mb-16 shadow-md">
            <Routes>
              {/* Step 1 */}
              <Route
                path="new"
                element={<Information setFormikState={setFormikState} />}
              />
              <Route
                path="/:publicId/information"
                element={<Information setFormikState={setFormikState} />}
              />
              {/* Step 2 */}
              {["/:publicId/parties", "/:publicId/clients"].map(
                (path, index) => (
                  <Route
                    key={index}
                    path={path}
                    element={
                      <PartySignatures setFormikState={setFormikState} />
                    }
                  />
                ),
              )}
              {/* Step 3 */}
              {["/:publicId/documents", "/:publicId/convention"].map(
                (path, index) => (
                  <Route
                    key={index}
                    path={path}
                    element={<DocumentsForSigning />}
                  />
                ),
              )}

              {/* Step 4 */}
              <Route
                path="/:publicId/signatures"
                element={<SendForSignatures setFormikState={setFormikState} />}
              />
            </Routes>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NewAct;
