import axios from "axios";
import { toast } from "react-toastify";

import { axiosErrorMessages, toastOptionsError } from "@constants";

import { lawyerProps, partyProps } from "@types";

export const fetchSignBooks = async (signBookId: string) => {
  try {
    const response = await axios.get(`/api/v1/signbooks/${signBookId}`);
    if (!response.data) {
      return response.data;
    }
    return response.data;
  } catch (error: any) {
    toast.error(axiosErrorMessages[error.message], toastOptionsError);
    throw error;
  }
};

export const fetchSignatories = async (
  id: any,
  user: { numCNBF: any },
  labels: { advisingTheParty: any; advisedBy: any },
) => {
  if (!id) {
    return null;
  }
  try {
    const response = await axios.get(`/api/v1/signbooks/${id}/signatories`);

    if (!response.data) {
      return [];
    }

    const normalizedData = response.data?.map((party: partyProps) => {
      const messageToDisplay =
        party.advisor === user.numCNBF
          ? labels.advisingTheParty
          : labels.advisedBy;

      return {
        ...party,
        signBookId: id,
        id: party.code,
        name: party.firstName,
        surname: party.lastName,
        type:
          "TYPE_NATURAL" === party.personType ? "TYPE_NATURAL" : "TYPE_LEGAL",
        phone: party.mobileNumber.replace("+33", ""),
        phoneCode: "+33",
        lawyerId: party.linkedTo,
        advisedByMessage: messageToDisplay,
        advisedBy: [
          {
            id: party.advisor,
            name: "",
          },
        ],
      };
    });

    return normalizedData;
  } catch (error: any) {
    toast.error(axiosErrorMessages[error.message], toastOptionsError);
    throw error;
  }
};

export const fetchLawyers = async (
  publicId: string | undefined,
  user: { numCNBF: string },
  labels: { advise: string },
) => {
  if (!publicId) {
    return null;
  }
  try {
    const response = await axios.get(`/api/v1/signbooks/${publicId}/lawyers`);
    if (!response.data) {
      return [];
    }
    const normalizedData = response.data?.map(
      (lawyer: lawyerProps, index: { toString: () => any }) => {
        const masterLawyer = lawyer.codeCNBF === user.numCNBF;

        return {
          id: index.toString(),
          codeCNBF: lawyer.codeCNBF,
          firstName: lawyer.firstName,
          lastName: lawyer.lastName,
          email: lawyer.email,
          master: masterLawyer,
          editor: masterLawyer,
          phone: lawyer.mobileNumber?.replace("+33", ""),
          phoneCode: "+33",
          type: "lawyer",
          advisingThePartyMessage: `${labels.advise}`,
          nationality: lawyer.nationality,
          mobilePhoneCountryCode: lawyer.mobilePhoneCountryCode,
        };
      },
    );
    return normalizedData;
  } catch (error: any) {
    toast.error(axiosErrorMessages[error.message], toastOptionsError);
    throw error;
  }
};
