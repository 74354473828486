import { useEffect, useState } from "react";

// Helper function to check if the user agent is a mobile device
const isMobileUserAgent = () => {
  return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|Opera Mini|IEMobile|WPDesktop/i.test(
    navigator.userAgent,
  );
};

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  const checkMobile = () => {
    // Check if it's a touch device and use a broader screen size check (for both width and height)
    const isTouchDevice =
      "ontouchstart" in window || navigator.maxTouchPoints > 0;
    const isSmallScreen =
      window.innerWidth <= 844 && window.innerHeight <= 1024;

    // Combine all checks: touch capability, screen size, and fallback to user agent
    const isMobileDevice =
      (isTouchDevice && isSmallScreen) || isMobileUserAgent();

    setIsMobile(isMobileDevice);
  };

  useEffect(() => {
    checkMobile(); // Initial check on load

    // Add event listener for resize/orientation change
    window.addEventListener("resize", checkMobile);

    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  return isMobile;
};

export default useIsMobile;
