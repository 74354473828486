import axios from "axios";

import "@eaf/lib/eaf_client.min.js";

const initEaf = () => {
  // Mocked getEAFSmartCard function.
  if (process.env.REACT_APP_EAF_DEBUG === "true") {
    const eafIsOn = localStorage.getItem("eBeeAccessFoundation") === "on";
    const tokenIsOn = localStorage.getItem("validToken") === "on";

    return {
      onLoadSuccess(callback: () => void) {
        if (eafIsOn) {
          callback();
        }

        return this;
      },
      onLoadError(callback: () => void) {
        callback();
        return this;
      },
      onError(callback: () => void) {
        callback();
        return this;
      },
      onListTokens(callback: (tokens: any) => void) {
        axios
          .get(`${process.env.REACT_APP_FE_BASEURL}/api/v1/eaf-token`)
          .then((response) => {
            const token = response.data || [];

            const tokenReturn = eafIsOn && tokenIsOn ? token : [];

            callback(tokenReturn);
          });

        return this;
      },
      init() {},
    };
  }

  // @ts-ignore
  return getEAFSmartCard();
};

const callingPrepareDocument = (chosenToken: any) => {
  if (!chosenToken) {
    console.error("No token selected for preparation");
    return;
  }

  const cert = chosenToken.certificate.original;
  const p7cc = 8000; // Adjust this based on your logic

  const prepareData = {
    p7size: p7cc,
    algo: chosenToken.certificate.json.signatureOid,
    cert: cert,
    // Add more data as needed, e.g., imgRef, ged, etc.
  };

  // prepareUrl TBD with backend
  const prepareUrl = "/prepare";
  axios
    .post(prepareUrl, prepareData)
    .then((response) => {
      parsePrepareDocumentResponse(response.data);
    })
    .catch((error) => {
      console.error("Error preparing document", error);
    });
};

const parsePrepareDocumentResponse = (data: any) => {
  if (data) {
    signAllDocsWithAlias(data.preparedHashes, data.chosenToken);
  } else {
    console.error("Failed to prepare document");
  }
};

const signAllDocsWithAlias = (preparedHashes: any, chosenToken: any) => {
  if (!preparedHashes || !chosenToken) {
    console.error("No prepared hashes or chosen token found");
    return;
  }

  const eaf = initEaf();

  eaf
    .sign(preparedHashes.signature, chosenToken)
    .then((result: any) => {
      // Handle success
    })
    .catch((error: any) => {
      console.error("Error during signing", error);
    });
};

const sendLogs = (logs: string[]) => {
  const logsUrl = "/logs";
  const logsData = JSON.stringify({ logs: logs });

  axios
    .post(logsUrl, logsData)
    .then((response) => {})
    .catch((error) => {
      console.error("Error sending logs", error);
    });
};

const sendStats = (stats: string[]) => {
  const statsUrl = "/stats";
  const statsData = JSON.stringify({ stats: stats });

  axios
    .post(statsUrl, statsData)
    .then((response) => {})
    .catch((error) => {
      console.error("Error sending stats", error);
    });
};

export {
  initEaf,
  callingPrepareDocument,
  parsePrepareDocumentResponse,
  signAllDocsWithAlias,
  sendLogs,
  sendStats,
};
