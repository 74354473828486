const countries: Record<string, string> = {
  AF: "Afghanistan",
  ZA: "Afrique du Sud",
  AL: "Albanie",
  DZ: "Algérie",
  DE: "Allemagne",
  AD: "Andorre",
  AO: "Angola",
  AI: "Anguilla",
  AG: "Antigua-et-Barbuda",
  AN: "Antilles Néerlandaises",
  SA: "Arabie Saoudite",
  AR: "Argentine",
  AM: "Arménie",
  AW: "Aruba",
  AU: "Australie",
  AT: "Autriche",
  AZ: "Azerbaïdjan",
  BS: "Bahamas",
  BH: "Bahreïn",
  BD: "Bangladesh",
  BB: "Barbade",
  BE: "Belgique",
  BZ: "Belize",
  BJ: "Bénin",
  BM: "Bermudes",
  BT: "Bhoutan",
  BY: "Biélorussie",
  BO: "Bolivie",
  BA: "Bosnie-Herzégovine",
  BW: "Botswana",
  BR: "Brésil",
  BN: "Brunei",
  BG: "Bulgarie",
  BF: "Burkina Faso",
  BI: "Burundi",
  KH: "Cambodge",
  CM: "Cameroun",
  CA: "Canada",
  CV: "Cap-Vert",
  CL: "Chili",
  CN: "Chine",
  CY: "Chypre",
  CO: "Colombie",
  KM: "Comores",
  KR: "Corée du Sud",
  CR: "Costa Rica",
  CI: "Côte d'Ivoire",
  HR: "Croatie",
  CU: "Cuba",
  DK: "Danemark",
  DM: "Dominique",
  EG: "Égypte",
  AE: "Émirats Arabes Unis",
  EC: "Équateur",
  ES: "Espagne",
  EE: "Estonie",
  US: "États-Unis",
  ET: "Éthiopie",
  FJ: "Fidji",
  FI: "Finlande",
  FR: "France",
  GA: "Gabon",
  GM: "Gambie",
  GE: "Géorgie",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Grèce",
  GD: "Grenade",
  GL: "Groenland",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GN: "Guinée",
  GQ: "Guinée Équatoriale",
  GY: "Guyana",
  GF: "Guyane Française",
  HT: "Haïti",
  HN: "Honduras",
  HK: "Hong Kong",
  HU: "Hongrie",
  KY: "Îles Caïmans",
  FO: "Îles Féroé",
  IN: "Inde",
  ID: "Indonésie",
  IE: "Irlande",
  IS: "Islande",
  IL: "Israël",
  IT: "Italie",
  JM: "Jamaïque",
  JP: "Japon",
  JO: "Jordanie",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KG: "Kirghizistan",
  KW: "Koweït",
  LA: "Laos",
  LV: "Lettonie",
  LB: "Liban",
  LR: "Libéria",
  LY: "Libye",
  LI: "Liechtenstein",
  LT: "Lituanie",
  LU: "Luxembourg",
  MO: "Macao",
  MK: "Macédoine",
  MG: "Madagascar",
  MY: "Malaisie",
  MW: "Malawi",
  MV: "Maldives",
  ML: "Mali",
  MT: "Malte",
  MA: "Maroc",
  MQ: "Martinique",
  MU: "Maurice",
  MR: "Mauritanie",
  YT: "Mayotte",
  MX: "Mexique",
  MD: "Moldavie",
  MC: "Monaco",
  MN: "Mongolie",
  ME: "Monténégro",
  MS: "Montserrat",
  MZ: "Mozambique",
  NA: "Namibie",
  NP: "Népal",
  NI: "Nicaragua",
  NE: "Niger",
  NG: "Nigéria",
  NO: "Norvège",
  NC: "Nouvelle-Calédonie",
  NZ: "Nouvelle-Zélande",
  UG: "Ouganda",
  PY: "Paraguay",
  NL: "Pays-Bas",
  PE: "Pérou",
  PH: "Philippines",
  PL: "Pologne",
  PF: "Polynésie Française",
  PT: "Portugal",
  QA: "Qatar",
  DO: "République Dominicaine",
  CZ: "République Tchèque",
  RE: "Réunion",
  RO: "Roumanie",
  GB: "Royaume-Uni",
  RU: "Russie",
  RW: "Rwanda",
  KN: "Saint-Christophe-et-Niévès",
  LC: "Sainte-Lucie",
  SV: "Salvador",
  ST: "Sao Tomé-et-Principe",
  SN: "Sénégal",
  RS: "Serbie",
  SC: "Seychelles",
  SG: "Singapour",
  SK: "Slovaquie",
  SI: "Slovénie",
  SO: "Somalie",
  SD: "Soudan",
  LK: "Sri Lanka",
  SE: "Suède",
  CH: "Suisse",
  SR: "Suriname",
  SZ: "Swaziland",
  SY: "Syrie",
  TJ: "Tadjikistan",
  TW: "Taïwan",
  TZ: "Tanzanie",
  TH: "Thaïlande",
  TL: "Timor Oriental",
  TG: "Togo",
  TT: "Trinité-et-Tobago",
  TN: "Tunisie",
  TR: "Turquie",
  UA: "Ukraine",
  UY: "Uruguay",
  VE: "Venezuela",
  VN: "Vietnam",
  ZM: "Zambie",
  ZW: "Zimbabwe",
};

const phones = [
  { code: "+93", country: "AF", name: "Afghanistan" },
  { code: "+27", country: "ZA", name: "Afrique du Sud" },
  { code: "+355", country: "AL", name: "Albanie" },
  { code: "+213", country: "DZ", name: "Algérie" },
  { code: "+49", country: "DE", name: "Allemagne" },
  { code: "+376", country: "AD", name: "Andorre" },
  { code: "+244", country: "AO", name: "Angola" },
  { code: "+1264", country: "AI", name: "Anguilla" },
  { code: "+1268", country: "AG", name: "Antigua-et-Barbuda" },
  { code: "+966", country: "SA", name: "Arabie saoudite" },
  { code: "+54", country: "AR", name: "Argentine" },
  { code: "+374", country: "AM", name: "Arménie" },
  { code: "+297", country: "AW", name: "Aruba" },
  { code: "+61", country: "AU", name: "Australie" },
  { code: "+43", country: "AT", name: "Autriche" },
  { code: "+994", country: "AZ", name: "Azerbaïdjan" },
  { code: "+1242", country: "BS", name: "Bahamas" },
  { code: "+973", country: "BH", name: "Bahreïn" },
  { code: "+1246", country: "BB", name: "Barbade" },
  { code: "+32", country: "BE", name: "Belgique" },
  { code: "+501", country: "BZ", name: "Belize" },
  { code: "+229", country: "BJ", name: "Bénin" },
  { code: "+1441", country: "BM", name: "Bermudes" },
  { code: "+975", country: "BT", name: "Bhoutan" },
  { code: "+375", country: "BY", name: "Biélorussie" },
  { code: "+95", country: "MM", name: "Birmanie" },
  { code: "+591", country: "BO", name: "Bolivie" },
  { code: "+387", country: "BA", name: "Bosnie-Herzégovine" },
  { code: "+267", country: "BW", name: "Botswana" },
  { code: "+55", country: "BR", name: "Brésil" },
  { code: "+673", country: "BN", name: "Brunei" },
  { code: "+359", country: "BG", name: "Bulgarie" },
  { code: "+226", country: "BF", name: "Burkina Faso" },
  { code: "+257", country: "BI", name: "Burundi" },
  { code: "+855", country: "KH", name: "Cambodge" },
  { code: "+237", country: "CM", name: "Cameroun" },
  { code: "+1", country: "CA", name: "Canada" },
  { code: "+238", country: "CV", name: "Cap-Vert" },
  { code: "+236", country: "CF", name: "République centrafricaine" },
  { code: "+56", country: "CL", name: "Chili" },
  { code: "+86", country: "CN", name: "Chine" },
  { code: "+357", country: "CY", name: "Chypre" },
  { code: "+57", country: "CO", name: "Colombie" },
  { code: "+269", country: "KM", name: "Comores" },
  { code: "+850", country: "KP", name: "Corée du Nord" },
  { code: "+82", country: "KR", name: "Corée du Sud" },
  { code: "+506", country: "CR", name: "Costa Rica" },
  { code: "+385", country: "HR", name: "Croatie" },
  { code: "+53", country: "CU", name: "Cuba" },
  { code: "+599", country: "CW", name: "Curaçao" },
  { code: "+45", country: "DK", name: "Danemark" },
  { code: "+246", country: "DG", name: "Diego Garcia" },
  { code: "+253", country: "DJ", name: "Djibouti" },
  { code: "+1767", country: "DM", name: "Dominique" },
  { code: "+1809", country: "DO", name: "République dominicaine" },
  { code: "+1849", country: "DO", name: "République dominicaine" },
  { code: "+1829", country: "DO", name: "République dominicaine" },
  { code: "+20", country: "EG", name: "Égypte" },
  { code: "+503", country: "SV", name: "El Salvador" },
  { code: "+971", country: "AE", name: "Émirats arabes unis" },
  { code: "+593", country: "EC", name: "Équateur" },
  { code: "+291", country: "ER", name: "Érythrée" },
  { code: "+34", country: "ES", name: "Espagne" },
  { code: "+372", country: "EE", name: "Estonie" },
  { code: "+268", country: "SZ", name: "Eswatini" },
  { code: "+251", country: "ET", name: "Éthiopie" },
  { code: "+679", country: "FJ", name: "Fidji" },
  { code: "+358", country: "FI", name: "Finlande" },
  { code: "+33", country: "FR", name: "France" },
  { code: "+241", country: "GA", name: "Gabon" },
  { code: "+220", country: "GM", name: "Gambie" },
  { code: "+995", country: "GE", name: "Géorgie" },
  { code: "+233", country: "GH", name: "Ghana" },
  { code: "+350", country: "GI", name: "Gibraltar" },
  { code: "+30", country: "GR", name: "Grèce" },
  { code: "+1473", country: "GD", name: "Grenade" },
  { code: "+590", country: "GP", name: "Guadeloupe" },
  { code: "+502", country: "GT", name: "Guatemala" },
  { code: "+224", country: "GN", name: "Guinée" },
  { code: "+240", country: "GQ", name: "Guinée équatoriale" },
  { code: "+245", country: "GW", name: "Guinée-Bissau" },
  { code: "+592", country: "GY", name: "Guyana" },
  { code: "+594", country: "GF", name: "Guyane française" },
  { code: "+509", country: "HT", name: "Haïti" },
  { code: "+504", country: "HN", name: "Honduras" },
  { code: "+852", country: "HK", name: "Hong Kong" },
  { code: "+36", country: "HU", name: "Hongrie" },
  { code: "+354", country: "IS", name: "Islande" },
  { code: "+91", country: "IN", name: "Inde" },
  { code: "+62", country: "ID", name: "Indonésie" },
  { code: "+98", country: "IR", name: "Iran" },
  { code: "+964", country: "IQ", name: "Irak" },
  { code: "+353", country: "IE", name: "Irlande" },
  { code: "+972", country: "IL", name: "Israël" },
  { code: "+39", country: "IT", name: "Italie" },
  { code: "+1876", country: "JM", name: "Jamaïque" },
  { code: "+81", country: "JP", name: "Japon" },
  { code: "+962", country: "JO", name: "Jordanie" },
  { code: "+7", country: "KZ", name: "Kazakhstan" },
  { code: "+254", country: "KE", name: "Kenya" },
  { code: "+996", country: "KG", name: "Kirghizistan" },
  { code: "+686", country: "KI", name: "Kiribati" },
  { code: "+965", country: "KW", name: "Koweït" },
  { code: "+856", country: "LA", name: "Laos" },
  { code: "+266", country: "LS", name: "Lesotho" },
  { code: "+371", country: "LV", name: "Lettonie" },
  { code: "+961", country: "LB", name: "Liban" },
  { code: "+231", country: "LR", name: "Libéria" },
  { code: "+218", country: "LY", name: "Libye" },
  { code: "+423", country: "LI", name: "Liechtenstein" },
  { code: "+370", country: "LT", name: "Lituanie" },
  { code: "+352", country: "LU", name: "Luxembourg" },
  { code: "+853", country: "MO", name: "Macao" },
  { code: "+389", country: "MK", name: "Macédoine du Nord" },
  { code: "+261", country: "MG", name: "Madagascar" },
  { code: "+265", country: "MW", name: "Malawi" },
  { code: "+60", country: "MY", name: "Malaisie" },
  { code: "+960", country: "MV", name: "Maldives" },
  { code: "+223", country: "ML", name: "Mali" },
  { code: "+356", country: "MT", name: "Malte" },
  { code: "+212", country: "MA", name: "Maroc" },
  { code: "+692", country: "MH", name: "Îles Marshall" },
  { code: "+596", country: "MQ", name: "Martinique" },
  { code: "+222", country: "MR", name: "Mauritanie" },
  { code: "+230", country: "MU", name: "Maurice" },
  { code: "+262", country: "YT", name: "Mayotte" },
  { code: "+52", country: "MX", name: "Mexique" },
  { code: "+691", country: "FM", name: "Micronésie" },
  { code: "+373", country: "MD", name: "Moldavie" },
  { code: "+377", country: "MC", name: "Monaco" },
  { code: "+976", country: "MN", name: "Mongolie" },
  { code: "+382", country: "ME", name: "Monténégro" },
  { code: "+1664", country: "MS", name: "Montserrat" },
  { code: "+258", country: "MZ", name: "Mozambique" },
  { code: "+264", country: "NA", name: "Namibie" },
  { code: "+674", country: "NR", name: "Nauru" },
  { code: "+977", country: "NP", name: "Népal" },
  { code: "+505", country: "NI", name: "Nicaragua" },
  { code: "+227", country: "NE", name: "Niger" },
  { code: "+234", country: "NG", name: "Nigéria" },
  { code: "+683", country: "NU", name: "Niue" },
  { code: "+47", country: "NO", name: "Norvège" },
  { code: "+687", country: "NC", name: "Nouvelle-Calédonie" },
  { code: "+64", country: "NZ", name: "Nouvelle-Zélande" },
  { code: "+968", country: "OM", name: "Oman" },
  { code: "+256", country: "UG", name: "Ouganda" },
  { code: "+998", country: "UZ", name: "Ouzbékistan" },
  { code: "+92", country: "PK", name: "Pakistan" },
  { code: "+680", country: "PW", name: "Palaos" },
  { code: "+970", country: "PS", name: "Palestine" },
  { code: "+507", country: "PA", name: "Panama" },
  { code: "+675", country: "PG", name: "Papouasie-Nouvelle-Guinée" },
  { code: "+595", country: "PY", name: "Paraguay" },
  { code: "+31", country: "NL", name: "Pays-Bas" },
  { code: "+63", country: "PH", name: "Philippines" },
  { code: "+48", country: "PL", name: "Pologne" },
  { code: "+689", country: "PF", name: "Polynésie française" },
  { code: "+351", country: "PT", name: "Portugal" },
  { code: "+1", country: "PR", name: "Porto Rico" },
  { code: "+974", country: "QA", name: "Qatar" },
  { code: "+40", country: "RO", name: "Roumanie" },
  { code: "+44", country: "GB", name: "Royaume-Uni" },
  { code: "+7", country: "RU", name: "Russie" },
  { code: "+250", country: "RW", name: "Rwanda" },
  { code: "+290", country: "SH", name: "Sainte-Hélène" },
  { code: "+1758", country: "LC", name: "Sainte-Lucie" },
  { code: "+1784", country: "VC", name: "Saint-Vincent-et-les-Grenadines" },
  { code: "+378", country: "SM", name: "Saint-Marin" },
  { code: "+508", country: "PM", name: "Saint-Pierre-et-Miquelon" },
  { code: "+685", country: "WS", name: "Samoa" },
  { code: "+221", country: "SN", name: "Sénégal" },
  { code: "+381", country: "RS", name: "Serbie" },
  { code: "+248", country: "SC", name: "Seychelles" },
  { code: "+232", country: "SL", name: "Sierra Leone" },
  { code: "+65", country: "SG", name: "Singapour" },
  { code: "+421", country: "SK", name: "Slovaquie" },
  { code: "+386", country: "SI", name: "Slovénie" },
  { code: "+677", country: "SB", name: "Îles Salomon" },
  { code: "+252", country: "SO", name: "Somalie" },
  { code: "+249", country: "SD", name: "Soudan" },
  { code: "+211", country: "SS", name: "Soudan du Sud" },
  { code: "+94", country: "LK", name: "Sri Lanka" },
  { code: "+46", country: "SE", name: "Suède" },
  { code: "+41", country: "CH", name: "Suisse" },
  { code: "+597", country: "SR", name: "Suriname" },
  { code: "+963", country: "SY", name: "Syrie" },
  { code: "+239", country: "ST", name: "Sao Tomé-et-Principe" },
  { code: "+886", country: "TW", name: "Taïwan" },
  { code: "+992", country: "TJ", name: "Tadjikistan" },
  { code: "+255", country: "TZ", name: "Tanzanie" },
  { code: "+66", country: "TH", name: "Thaïlande" },
  { code: "+228", country: "TG", name: "Togo" },
  { code: "+690", country: "TK", name: "Tokelau" },
  { code: "+676", country: "TO", name: "Tonga" },
  { code: "+1868", country: "TT", name: "Trinité-et-Tobago" },
  { code: "+216", country: "TN", name: "Tunisie" },
  { code: "+90", country: "TR", name: "Turquie" },
  { code: "+993", country: "TM", name: "Turkménistan" },
  { code: "+1649", country: "TC", name: "Îles Turques-et-Caïques" },
  { code: "+688", country: "TV", name: "Tuvalu" },
  { code: "+256", country: "UG", name: "Ouganda" },
  { code: "+380", country: "UA", name: "Ukraine" },
  { code: "+971", country: "AE", name: "Émirats Arabes Unis" },
  { code: "+598", country: "UY", name: "Uruguay" },
  { code: "+998", country: "UZ", name: "Ouzbékistan" },
  { code: "+678", country: "VU", name: "Vanuatu" },
  { code: "+379", country: "VA", name: "Vatican" },
  { code: "+58", country: "VE", name: "Venezuela" },
  { code: "+84", country: "VN", name: "Viêt Nam" },
  { code: "+681", country: "WF", name: "Wallis-et-Futuna" },
  { code: "+967", country: "YE", name: "Yémen" },
  { code: "+260", country: "ZM", name: "Zambie" },
  { code: "+263", country: "ZW", name: "Zimbabwe" },
];

export { countries, phones };
