import { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";

import Button from "@ui/Button";
import Icon from "@ui/Icon";
import Spinner from "@ui/Spinner";

import { ButtonDropdownProps } from "@types";

const ButtonDropdown = ({
  options,
  buttonLabel,
  iconType,
  iconColor,
  onClick = () => {},
  payload = null,
  disabled = false,
  loading = false,
}: ButtonDropdownProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const disabledClass = disabled ? "btn-disabled-light" : "";
  const buttonWithoutOptionsStyle =
    options.length > 0
      ? "border-r rounded-lg lg:border-r-0 lg:rounded-r-none lg:rounded-l-lg"
      : "rounded-lg";

  return (
    <div
      className="relative inline-block select-none text-left"
      ref={dropdownRef}
    >
      <div className="flex items-center justify-center">
        <Button
          className={`btn-secondary flex border items-center px-3 h-[34px] body-lg whitespace-nowrap ${disabledClass} ${buttonWithoutOptionsStyle}`}
          type="button"
          disabled={disabled || loading}
          onClick={(event) => {
            event.stopPropagation();
            onClick(event);
          }}
          data-testid="cta-button"
        >
          {iconType && !loading && (
            <Icon
              type={iconType}
              className="w-4 h-4 mr-[8px]"
              color={iconColor}
            />
          )}
          {loading && <Spinner className="size-4 mr-4" dark={true} />}
          {buttonLabel}
        </Button>
        {options.length > 0 && (
          <Button
            className={`flex items-center justify-center p-[12px] btn-secondary h-[34px] rounded-r-lg ${disabledClass} hidden lg:block`}
            disabled={disabled}
            onClick={(event) => {
              event.stopPropagation();
              toggleDropdown();
            }}
            type="button"
            data-testid="dropdown-button"
          >
            <Icon
              type="dropdown-arrow-slim"
              color="#000"
              className="w-[20px] h-[11px]"
            />
          </Button>
        )}
      </div>
      {isDropdownOpen && (
        <ul
          className="dropdown absolute top-[40px] right-0 bg-white z-50 py-[5px] box-shadow whitespace-nowrap"
          data-testid="button-dropdown-dropdown-list"
        >
          {options.map((option, index) => {
            const buttonClassName =
              "block body-md hover:bg-ea-lightblue px-8 py-[4px] cursor-pointer w-full text-left";

            return (
              <li key={index}>
                {option.onClick ? (
                  <Button
                    type="button"
                    onClick={(event) => {
                      event.stopPropagation();

                      if (option.onClick) {
                        const onClickParam = payload !== null ? payload : null;

                        option.onClick(onClickParam, event);
                        setIsDropdownOpen(false);
                      }
                    }}
                    className={buttonClassName}
                    // data-testid={option.dataTestId}
                  >
                    {option.label}
                  </Button>
                ) : (
                  <NavLink
                    to={option.link ? option.link : ""}
                    className={buttonClassName}
                  >
                    {option.label}
                  </NavLink>
                )}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default ButtonDropdown;
